import { Controller, UseFormReturn } from 'react-hook-form';
import { Box, FormLabel, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { DeliveryVehicleCard } from './DeliveryVehicleCard';
import {
  CreateOrder,
  DeliveryVehicle,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';

type DeliveryVehiclesProps = {
  filteredVehicles?: DeliveryVehicle[];
  handleOptionAndItemsOnDeliveryVehicle: (
    deliveryVehicleId: DeliveryVehicleId,
  ) => void;
  form: UseFormReturn<CreateOrder>;
};

export const DeliveryVehicles = ({
  filteredVehicles,
  handleOptionAndItemsOnDeliveryVehicle,
  form,
}: DeliveryVehiclesProps) => {
  return (
    <Controller
      name="deliveryVehicle"
      control={form.control}
      render={({ field: { onChange, value } }) => (
        <Box display={'flex'} flexDirection={'column'}>
          <Box mb={6}>
            <FormLabel>Vehicle Type</FormLabel>
            <Text color={'blackAlpha.600'}>
              Based on the sizing details estimated above, you can choose
              between the following available options:
            </Text>
          </Box>
          <SimpleGrid columns={[1, 2]} gap={4}>
            {filteredVehicles && filteredVehicles.length > 0 ? (
              filteredVehicles.map((deliveryVehicle, index) => (
                <DeliveryVehicleCard
                  key={deliveryVehicle.deliveryVehicleId}
                  deliveryVehicle={deliveryVehicle}
                  isSelected={value === deliveryVehicle.deliveryVehicleId}
                  handleClick={() => {
                    onChange(deliveryVehicle.deliveryVehicleId);
                    handleOptionAndItemsOnDeliveryVehicle(
                      deliveryVehicle.deliveryVehicleId,
                    );
                  }}
                />
              ))
            ) : (
              <Text color="orange.400" fontSize={14} data-cy={'no-vehicles'}>
                <Icon as={WarningTwoIcon} mr={1} />
                No vehicles can take the dimensions/weight given. Contact
                Tradeaze for any enquiries.
              </Text>
            )}
          </SimpleGrid>
        </Box>
      )}
    />
  );
};
