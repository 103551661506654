import { add, isSameDay, set } from 'date-fns';
import { OPENING_HOUR } from '@tradeaze-packages/schemas';

export const getFromDeliveryDateTime = (initialDate?: Date) => {
  if (initialDate) {
    const isToday = isSameDay(initialDate, new Date());
    if (isToday) {
      return initialDate;
    }
    return set(initialDate, {
      hours: OPENING_HOUR,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }
  const now = new Date();
  const isSunday = now.getDay() === 0;
  if (isSunday) {
    return set(add(now, { days: 1 }), {
      hours: OPENING_HOUR,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }
  return now;
};
