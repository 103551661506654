import { Button, Center, Text } from '@chakra-ui/react';
import React from 'react';

type OrderPageButtonProps = React.ComponentProps<typeof Button> & {
  icon: React.ReactNode;
  label: string;
  'data-cy': string;
};

export const OrderPageButton: React.FC<OrderPageButtonProps> = ({
  icon,
  label,
  'data-cy': dataCy,
  ...props
}) => (
  <Button borderRadius={'2xl'} fontSize="xs" h="50px" w="60px" colorScheme={'brandSecondary'} data-cy={dataCy} {...props}>
    <Center position={'relative'} bottom="1">
      {icon}
    </Center>
    <Text fontSize={10} position={'absolute'} bottom="1">
      {label}
    </Text>
  </Button>
);
