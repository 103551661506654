import { useState } from 'react';
import { CreateDeliveryItem, DimensionUnit } from '@tradeaze-packages/schemas';
import { unitConverter } from '../utils';
import { UseFormReturn } from 'react-hook-form';
import { captureEvent } from '@tradeaze/frontend/utils';
import { PostHog } from 'posthog-js/react';

type UseItemDetailsSection = {
  posthog?: PostHog;
  form: UseFormReturn<CreateDeliveryItem>;
};

export const useItemDetailsSection = ({
  posthog,
  form,
}: UseItemDetailsSection) => {
  const { setValue, getValues } = form;
  const [measurementUnit, setMeasurementUnit] = useState<DimensionUnit>(
    getValues('widthUnit') || 'mm',
  );

  const handleMeasurementChange = (currentUnit: DimensionUnit) => {
    const length = getValues('length');
    const width = getValues('width');
    const height = getValues('height');
    const existingWidthUnit = getValues('widthUnit');
    const existingLengthUnit = getValues('lengthUnit');
    const existingHeightUnit = getValues('heightUnit');

    const convertedWidth = unitConverter(width, existingWidthUnit, currentUnit);
    const convertedLength = unitConverter(
      length,
      existingLengthUnit,
      currentUnit,
    );
    const convertedHeight = unitConverter(
      height,
      existingHeightUnit,
      currentUnit,
    );

    setMeasurementUnit(currentUnit);

    setValue('widthUnit', currentUnit);
    setValue('lengthUnit', currentUnit);
    setValue('heightUnit', currentUnit);
    setValue('width', Number(convertedWidth));
    setValue('length', Number(convertedLength));
    setValue('height', Number(convertedHeight));
  };

  const onToggleHeavySideItems = (value: string) => {
    if (value === 'YES') {
      setValue('heavySideItems', true);
    } else if (value === 'NO') {
      setValue('heavySideItems', false);
    }
    if (posthog) {
      captureEvent(posthog)(`toggle_heavy_side_items_${value ? 'on' : 'off'}`);
    }
  };

  return {
    onToggleHeavySideItems,
    measurementUnit,
    handleMeasurementChange,
  };
};
