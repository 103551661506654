import { useCallback, useMemo, useState } from 'react';

export const useFieldVisibility = (initial = false) => {
  const [isVisible, setIsVisible] = useState(initial);

  const handleShow = useCallback(() => {
    setIsVisible(true);
  }, []);

  return useMemo(
    () => ({
      isVisible,
      handleShow,
    }),
    [isVisible, handleShow],
  );
};
