import { CreateOrder, MerchantAccount } from '@tradeaze-packages/schemas';
import { FormProvider } from 'react-hook-form';
import { useMultiDropForm } from '../../hooks';
import { PostHog } from 'posthog-js';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import { InvoicingSection, PickupSection } from '../../order-form-sections';
import { Box, Button } from '@chakra-ui/react';

type MultiDropOrderFormProps = {
  merchantAccount?: MerchantAccount;
  isAdmin?: boolean;
  posthog?: PostHog;
  onSubmit: (order: CreateOrder) => void;
  setShowMainBackButton?: (value: boolean) => void;
  addPickupMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
};

export const MultiDropOrderForm = ({
  merchantAccount,
  isAdmin,
  posthog,
  onSubmit,
  setShowMainBackButton,
  addPickupMarker,
  removePickupMarker,
}: MultiDropOrderFormProps) => {
  const { form, isInvoicingComplete, isPickupComplete, pickupForm } =
    useMultiDropForm({
      merchantAccount,
      isAdmin,
      posthog,
      onSubmit,
      setShowMainBackButton,
      addPickupMarker,
      removePickupMarker,
    });

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" gap={8}>
        {!merchantAccount && (
          <InvoicingSection
            form={form}
            isSectionComplete={isInvoicingComplete}
          />
        )}
        <PickupSection
          sectionName={'Pick Up'}
          defaultPickupAddress={merchantAccount?.address}
          defaultPickupPostcode={merchantAccount?.postCode}
          defaultPickupPosition={merchantAccount?.position}
          merchantAccount={merchantAccount}
          showExpanded={false}
          form={pickupForm}
          isSectionComplete={isPickupComplete}
          addMarker={addPickupMarker}
          removeMarker={removePickupMarker}
          isSecondPickup={false}
          restrictedEditing={false}
          cypressTestId={'pickup-section'}
          stopId={1}
          isMultiDrop={true}
        />
        <Button data-cy={'add-drop-button'}>Add Drop 1</Button>
      </Box>
    </FormProvider>
  );
};
