import { SiteContact } from '@tradeaze-packages/schemas';

export const formatSavedContacts = (
  paginatedSavedContacts:
    | { pages: { siteContacts: SiteContact[] }[] }
    | undefined,
) => {
  return paginatedSavedContacts?.pages
    .flatMap((page) => page.siteContacts)
    .sort((a, b) => a.contactName.localeCompare(b.contactName));
};
