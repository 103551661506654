import { useGetThresholdDistances } from '@tradeaze/frontend/hooks';
import { useMemo } from 'react';
import {
  getIsAnyStopOverThreshold,
  getIsEveryStopOverThreshold,
} from '@tradeaze/shared/utils';
import { MAX_DISTANCE_FROM_CENTRE_OF_LONDON_METRES } from '@tradeaze-packages/schemas';

type DeliveryStop = {
  postCode: string;
};

type ThresholdDistancesResult = {
  isAnyStopOverThreshold: boolean;
  isEveryStopOverThreshold: boolean;
};

export const useThresholdDistances = (
  deliveryStops: DeliveryStop[],
  enabled: boolean,
): ThresholdDistancesResult => {
  const stopPostCodes = deliveryStops.map((stop) => stop.postCode);
  const stopDistancesQuery = useGetThresholdDistances(
    { stops: stopPostCodes },
    { enabled: enabled },
  );

  const { isLoading, data } = stopDistancesQuery;
  const thresholdDistances = data?.thresholdDistances;

  return useMemo(() => {
    const isAnyStopOverThreshold =
      !isLoading && thresholdDistances
        ? getIsAnyStopOverThreshold(
            thresholdDistances,
            MAX_DISTANCE_FROM_CENTRE_OF_LONDON_METRES,
          )
        : false;

    const isEveryStopOverThreshold =
      !isLoading && thresholdDistances
        ? getIsEveryStopOverThreshold(
            thresholdDistances,
            MAX_DISTANCE_FROM_CENTRE_OF_LONDON_METRES,
          )
        : false;

    return { isAnyStopOverThreshold, isEveryStopOverThreshold };
  }, [isLoading, thresholdDistances]);
};
