import { useMutation } from '@tanstack/react-query';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { updateDeliveryStatus } from '@tradeaze-packages/api-client';
import {
  getAdminOrdersQueryKey,
  getMerchantOrdersQueryKey,
  getOrderQueryKey,
} from '../orders';
import { getAdminDeliveriesQueryKey } from './useGetAdminDeliveries';
import { getMerchantDeliveriesQueryKey } from './useGetMerchantDeliveries';
import { getDeliveryQueryKey } from './useGetDelivery';

export const useUpdateDeliveryStatus = (
  options?: CustomUseMutationOptions<typeof updateDeliveryStatus>
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    ...options,
    mutationFn: updateDeliveryStatus,
    onSuccess: (data, variables, context) => {
      invalidateQueries([
        getOrderQueryKey(),
        getDeliveryQueryKey(variables.deliveryId),
        getAdminDeliveriesQueryKey(),
        getMerchantDeliveriesQueryKey(),
        getAdminOrdersQueryKey(),
        getMerchantOrdersQueryKey(),
      ]);

      options?.onSuccess?.(data, variables, context);
    },
  });
};
