import { SimpleGrid } from '@chakra-ui/react';
import {
  orderKeyToLabel,
  travisPerkinsOrderKeyToLabel,
} from '@tradeaze/shared/utils';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Attribute, TextValue } from '../../shared';
import { OrderSection } from '../order-section';
import { sectionColumns } from './common';
import { OrderType } from '@tradeaze-packages/schemas';
import { OrderTypeLabel } from '../orderConstants';

export const BookingInfo: React.FunctionComponent<{
  orderType: OrderType;
  merchantOrderReference?: string | null;
  bookedBy?: string | null;
  notes?: string | null;
  isTravisPerkins?: boolean;
}> = ({
  merchantOrderReference,
  bookedBy,
  notes,
  isTravisPerkins = false,
  orderType,
}) => {
  const labelMap = isTravisPerkins
    ? travisPerkinsOrderKeyToLabel
    : orderKeyToLabel;

  return (
    <OrderSection name="Booking Information" icon={<AiOutlineInfoCircle />}>
      <SimpleGrid columns={sectionColumns} spacing={4}>
        <Attribute name={labelMap['bookedBy']}>
          <TextValue text={bookedBy} />
        </Attribute>
        <Attribute name={labelMap['merchantOrderReference']}>
          <TextValue text={merchantOrderReference} />
        </Attribute>
        <Attribute name="Order Type">
          <TextValue text={OrderTypeLabel[orderType]} />
        </Attribute>
      </SimpleGrid>
      {notes ? (
        <Attribute name={labelMap['notes']}>
          <TextValue text={notes} />
        </Attribute>
      ) : null}
    </OrderSection>
  );
};
