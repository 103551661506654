import {
  CreateDeliveryStop,
  MerchantAccount,
  MerchantDefinedCompanyAddress,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { useDeliveryStop } from './useDeliveryStop';
import {
  getAddressesQueryKey,
  useCreateMerchantCompany,
  useCreateMerchantCompanyAddress,
  useGeocodeAddress,
  useGetCompaniesByMerchant,
  useUpdateAddress,
} from '@tradeaze/frontend/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import {
  handleSaveMerchantCustomer,
  handleSelectMerchantCustomer,
} from '../utils';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';

type UseDropOffSection = {
  merchantAccount?: MerchantAccount;
  stopSequence?: number;
  form: UseFormReturn<CreateDeliveryStop>;
  addMarker: AddMarkerFn;
  removeMarker: RemoveMarkerFn;
};

export const useDropOffSection = ({
  merchantAccount,
  stopSequence,
  form,
  addMarker,
  removeMarker,
}: UseDropOffSection) => {
  const { setValue, watch, clearErrors } = form;
  const companyId = watch('companyId');
  const companyName = watch('companyName');
  const addressLine1 = watch('addressLine1');
  const postCode = watch('postCode');
  const addressId = watch('addressId');
  const deliveryNotes = watch('deliveryNotes');
  const contacts = watch('contacts');
  const position = form.watch('position');
  const id = form.watch('id');

  const {
    showFullAddress,
    isManualAddress,
    instructionsVisibility,
    setInstructionsVisibility,
    isLoadingSavedAddresses,
    savedAddresses,
    savedAddressPlaceholder,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
  } = useDeliveryStop({
    form,
    merchantAccount,
    addressType: 'DROP_OFF',
    companyId: companyId,
  });

  const {
    data: paginatedMerchantCustomers,
    isLoading: isLoadingMerchantCustomers,
  } = useGetCompaniesByMerchant(merchantAccount?.merchantId);

  const merchantCustomers = useMemo(
    () =>
      paginatedMerchantCustomers?.pages[0].companies.sort((a, b) =>
        a.companyName.localeCompare(b.companyName),
      ),
    [paginatedMerchantCustomers],
  );

  const {
    mutateAsync: createMerchantCompany,
    isLoading: isLoadingCreateMerchantCompany,
  } = useCreateMerchantCompany({
    invalidateQueryKeys: [['getCompaniesByMerchant']],
  });

  const handleSelectMerchantCustomerCallback = useMemo(
    () => handleSelectMerchantCustomer(setValue, clearErrors),
    [setValue, clearErrors],
  );

  const hasSelectedMerchantCustomer = useMemo(
    () => merchantCustomers?.some((el) => el.companyId === companyId),
    [merchantCustomers, companyId],
  );

  const canSaveMerchantCustomer = useMemo(
    () =>
      merchantAccount &&
      companyName &&
      !merchantCustomers?.some((el) => el.companyId === companyId),
    [companyName, merchantAccount, merchantCustomers, companyId],
  );

  const handleSaveMerchantCustomerCallback = useCallback(
    () =>
      handleSaveMerchantCustomer({
        createMerchantCompany,
        setValue,
        companyName,
        merchantAccount,
      }),
    [createMerchantCompany, setValue, companyName, merchantAccount],
  );

  const { mutate: createAddress, isLoading: isLoadingCreateAddress } =
    useCreateMerchantCompanyAddress({
      invalidateQueryKeys: [getAddressesQueryKey()],
      onSuccess: ({ addressId }) => {
        setValue('addressId', addressId);
      },
    });

  const updateAddressMutation = useUpdateAddress();

  const geocodeQuery = useGeocodeAddress({ postcode: postCode }, {
    enabled: isManualAddress,
  });

  useEffect(() => {
    if (!isManualAddress) return;

    if (geocodeQuery.data) {
      setValue('position', geocodeQuery.data.position);
    } else {
      setValue('position', null);
    }
  }, [geocodeQuery.data, isManualAddress, setValue]);

  useEffect(() => {
    if (position) {
      addMarker?.({
        id: id ?? '',
        position,
        stopSequence,
      });
    } else {
      removeMarker(id ?? '');
    }
  }, [addMarker, position, removeMarker, id, stopSequence]);

  const canSaveAddress =
    !!merchantAccount &&
    !!companyId &&
    !!addressLine1 &&
    !!postCode &&
    !!position &&
    !addressId;

  const canUpdateAddress = useMemo<boolean>(() => {
    if (!addressId || !savedAddresses) {
      return false;
    }
    const savedAddress = savedAddresses.find(
      (el) => el.address.addressId === addressId,
    );
    if (!savedAddress) {
      return false;
    }

    const hasAddressChanged =
      savedAddress.address.addressLine1 !== addressLine1 ||
      savedAddress.address.postCode !== postCode ||
      savedAddress.address.companyName !== companyName ||
      savedAddress.address.instructions !== deliveryNotes;

    return hasAddressChanged;
  }, [
    addressId,
    savedAddresses,
    addressLine1,
    postCode,
    companyName,
    deliveryNotes,
  ]);

  const handleSaveAddress = async () => {
    if (!addressLine1 || !postCode || !merchantAccount || !position) {
      return;
    }

    const newAddress: MerchantDefinedCompanyAddress = {
      companyId,
      companyName,
      instructions: deliveryNotes,
      merchantId: merchantAccount.merchantId,
      addressLine1,
      postCode,
      position,
      addressType: 'DROP_OFF',
    };

    createAddress(newAddress);
  };

  const handleUpdateAddress = () => {
    if (!addressId) {
      return;
    }
    updateAddressMutation.mutate({
      addressId,
      body: {
        contactIds: contacts.map((contact) => contact.siteContactId || ''),
        instructions: deliveryNotes,
        companyName: companyName,
        addressLine1,
        position,
        postCode,
      },
    });
  };

  return {
    form,
    showFullAddress,
    isManualAddress,
    instructionsVisibility,
    setInstructionsVisibility,
    geocodeData: geocodeQuery.data,
    geocodeError: geocodeQuery.error,
    savedAddresses,
    savedAddressPlaceholder: hasSelectedMerchantCustomer
      ? savedAddressPlaceholder
      : 'Existing customer not selected',
    isLoadingSavedAddresses,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
    merchantCustomers,
    canSaveMerchantCustomer,
    handleSaveMerchantCustomer: handleSaveMerchantCustomerCallback,
    hasSelectedMerchantCustomer,
    handleSelectMerchantCustomer: handleSelectMerchantCustomerCallback,
    isLoadingCreateMerchantCompany,
    isLoadingMerchantCustomers,
    companyId,
    canSaveAddress,
    handleSaveAddress,
    isLoadingCreateAddress,
    canUpdateAddress,
    handleUpdateAddress,
    isLoadingUpdateAddress: updateAddressMutation.isLoading,
  };
};
