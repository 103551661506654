import { Spinner, Stack, Text } from '@chakra-ui/react';
import { formatTimeRange } from '@tradeaze/shared/utils';

type DeliveryWindowProps = {
  deliveryWindowStart: string;
  deliveryWindowEnd: string;
  deliveryOptionsQueryLoading: boolean;
};

export const DeliveryWindow = ({
  deliveryWindowStart,
  deliveryWindowEnd,
  deliveryOptionsQueryLoading,
}: DeliveryWindowProps) => {
  return (
    <Stack
      direction={['column', 'column', 'column', 'column', 'row']}
      justifyContent={['left', 'left', 'left', 'left', 'space-between']}
      alignItems={['start', 'start', 'start', 'start', 'center']}
    >
      <Text fontWeight={'bold'}>Delivery Window</Text>
      {deliveryWindowStart && deliveryWindowEnd ? (
        <Text data-cy={'delivery-window'}>
          {formatTimeRange({
            from: deliveryWindowStart,
            to: deliveryWindowEnd,
          })}
        </Text>
      ) : deliveryOptionsQueryLoading ? (
        <Spinner />
      ) : (
        <Text color="red.500">Select a delivery option</Text>
      )}
    </Stack>
  );
};
