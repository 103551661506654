import { PostHog } from 'posthog-js/react';

export type AnalyticsEvent =
  | 'open_pricing_modal'
  | 'delivery_price_calculated'
  | 'start_create_order'
  | 'start_create_order_from_pricing_modal'
  | 'click_return_order'
  | 'click_duplicate_order'
  | 'duplicate_order'
  | 'return_order'
  | 'view_proof_of_delivery'
  | 'toggle_heavy_side_items_on'
  | 'toggle_heavy_side_items_off'
  | 'toggle_duplicate_config'
  | 'complete_create_order'
  | 'outdated_refresh'
  | 'outdated_ignore'
  | 'order_card_clicked'
  | 'delivery_card_clicked'
  | 'go_to_order_page_from_preview'
  | 'go_to_delivery_page_from_preview'
  | 'start_create_ab_order'
  | 'start_create_multicollection_order'
  | 'open_pricing_modal_multi-collection';

export const captureEvent =
  (posthog: PostHog) =>
  (eventId: AnalyticsEvent, metadata?: Record<string, any>) => {
    posthog?.capture(eventId, metadata);
  };
