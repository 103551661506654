import { AutocompleteAddress } from '@tradeaze/shared/services';
import { CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';

export const handleSelectLocation = (
  setValue: UseFormReturn<CreateDeliveryStop>['setValue'],
  trigger: UseFormReturn<CreateDeliveryStop>['trigger'],
  addressResult: AutocompleteAddress,
  setIsManualAddress: (manual: boolean) => void,
  setShowFullAddress: (show: boolean) => void,
) => {
  const position = {
    latitude: addressResult.latitude,
    longitude: addressResult.longitude,
  };

  const postcode = addressResult.postcode;
  const address = [addressResult.line_1, addressResult.line_2]
    .filter(Boolean)
    .join(', ');

  const fields = {
    position,
    addressId: null,
    postCode: postcode || '',
    addressLine1: address || '',
  };

  Object.entries(fields).forEach(([key, value]) =>
    setValue(key as keyof CreateDeliveryStop, value),
  );

  trigger(['addressLine1', 'postCode']);
  setIsManualAddress(false);
  setShowFullAddress(true);
};
