import {
  Box,
  Button,
  CloseButton,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import ReactDatePicker from 'react-datepicker';
import { OrderQueryStore } from './useOrderQueryStore';
import { ALL_ORDER_STATUSES, OrderSortBy } from '@tradeaze-packages/schemas';
import { DebounceSearchInput } from '../OrderSearchInput';
import { RiderFilterMenu } from '../../rider/RiderFilterMenu';
import { DeliveryVehicleFilterMenu } from '../../delivery/delivery-vehicle/DeliveryVehicleFilterMenu';
import { brandPrimaryColor, brandSecondaryColor } from '../../theme';
import { OrderStatusTag } from '../OrderStatusTag';
import { sortByToLabel } from './sortByToLabel';
import { NAVBAR_HEIGHT } from '../../navbar';
import { MerchantFilterMenu } from '../../merchant';

export const OrderQueryOptionsBar: React.FC<
  OrderQueryStore & {
    children?: React.ReactNode;
    isAdmin?: boolean;
    allowListView?: boolean;
  }
> = ({
  handleChangeSortBy,
  handleStatusFilterChange,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeOrderReference,
  handleToggleRider,
  handleSelectAllRiders,
  handleClearDates,
  handleToggleDeliveryVehicle,
  handleToggleMerchant,
  handleSelectAllMerchants,
  handleToggleDeliveryToday,
  handleSelectAllDeliveryVehicles,
  orderStatuses,
  sortBy,
  startDate,
  deliveryToday,
  endDate,
  riderIds,
  merchantIds,
  children,
  merchantOrderReference,
  deliveryVehicles,
  allowListView,
  isAdmin = false,
}) => {
  return (
    <>
      <HStack
        position={'fixed'}
        top={`${NAVBAR_HEIGHT}px`}
        left={0}
        zIndex={1}
        bg={brandPrimaryColor}
        minHeight={12}
        width={'100vw'}
        shadow={'md'}
        alignItems={'center'}
        justifyContent={'space-between'}
        paddingX={4}
      >
        <HStack>
          <DebounceSearchInput
            inputPlaceholder="Order Reference"
            defaultValue={merchantOrderReference}
            handleSearchChange={handleChangeOrderReference}
            height={'33px'}
            minWidth={'200px'}
          />
          {isAdmin ? (
            <>
              <RiderFilterMenu
                handleToggleRider={handleToggleRider}
                handleToggleSelectAllRiders={handleSelectAllRiders}
                selectedRiderIds={riderIds}
              />
              <DeliveryVehicleFilterMenu
                selectedVehicleIds={deliveryVehicles}
                handleSelectAllDeliveryVehicles={
                  handleSelectAllDeliveryVehicles
                }
                handleToggleDeliveryVehicle={handleToggleDeliveryVehicle}
              />
              <MerchantFilterMenu
                handleToggleMerchant={handleToggleMerchant}
                handleSelectAllMerchants={handleSelectAllMerchants}
                selectedMerchantIds={merchantIds}
              />
            </>
          ) : null}
        </HStack>
        <HStack
          sx={{
            '.react-datepicker__input-container': {
              input: {
                padding: '3px 10px',
                borderRadius: '5px',
                width: '120px',
              },
            },
          }}
        >
          <CloseButton onClick={handleClearDates} />
          <ReactDatePicker
            placeholderText="Start Date"
            selected={startDate ? new Date(startDate) : undefined}
            onChange={handleChangeStartDate}
            selectsStart
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
            dateFormat="dd/MM/yyyy"
          />
          <ReactDatePicker
            placeholderText="End Date"
            selected={endDate ? new Date(endDate) : undefined}
            onChange={handleChangeEndDate}
            selectsEnd
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
            minDate={startDate ? new Date(startDate) : undefined}
            dateFormat="dd/MM/yyyy"
          />
          <Menu closeOnSelect={false}>
            <Box>
              <MenuButton
                colorScheme={'gray'}
                size={'sm'}
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {sortByToLabel[sortBy]}
                {deliveryToday ? ' (Delivery Today)' : ''}
              </MenuButton>
            </Box>
            <MenuList maxWidth={'max-content'}>
              {/* When the option is inside the MenuOptionGroup, the state of isChecked seems to be gltichy, so its just her with no children for the group title */}
              <MenuOptionGroup title="Quick Filter" />
              <MenuItemOption
                onClick={handleToggleDeliveryToday}
                isChecked={deliveryToday}
              >
                Delivery Today
              </MenuItemOption>
              <MenuDivider />
              <MenuOptionGroup
                title="Sort By"
                type="radio"
                value={sortBy}
                onChange={(value) => handleChangeSortBy(value as OrderSortBy)}
              >
                {Object.keys(sortByToLabel).map((key) => (
                  <MenuItemOption
                    key={key}
                    value={key}
                    isChecked={sortBy === key}
                  >
                    {sortByToLabel[key as OrderSortBy]}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          {children}
        </HStack>
      </HStack>
      <HStack
        height={'40px'}
        position="fixed"
        bg={brandSecondaryColor}
        bottom={0}
        left={0}
        width="100vw"
        boxShadow={'md'}
        justifyContent={'center'}
      >
        {ALL_ORDER_STATUSES.map((status) => (
          <Box
            key={status}
            cursor={'pointer'}
            onClick={() => handleStatusFilterChange(status)}
            shadow="md"
          >
            <OrderStatusTag
              inactive={!orderStatuses?.includes(status)}
              status={status}
            />
          </Box>
        ))}
      </HStack>
    </>
  );
};
