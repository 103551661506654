import {
  Company,
  CreateDeliveryStop,
  MerchantAccount,
  MerchantDefinedCompany,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { UseMutateAsyncFunction } from 'react-query';
import { handleError } from '@tradeaze/frontend/utils';

type HandleSaveMerchantCustomerParams = {
  createMerchantCompany: UseMutateAsyncFunction<
    Company,
    unknown,
    MerchantDefinedCompany
  >;
  setValue: UseFormReturn<CreateDeliveryStop>['setValue'];
  companyName: string | null | undefined;
  merchantAccount: MerchantAccount | undefined;
};

export const handleSaveMerchantCustomer = async ({
  createMerchantCompany,
  setValue,
  companyName,
  merchantAccount,
}: HandleSaveMerchantCustomerParams) => {
  try {
    if (!companyName || !merchantAccount) {
      return;
    }
    const newCompany: MerchantDefinedCompany = {
      merchantId: merchantAccount.merchantId,
      companyName,
    };
    const company = await createMerchantCompany(newCompany);
    setValue('companyId', company.companyId);
  } catch (error) {
    handleError(error, {
      sendToSentry: true,
    });
  }
};
