import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';

type OrderTypeCardProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick: () => void;
};

export const OrderTypeCard = ({
  icon,
  title,
  description,
  onClick,
}: OrderTypeCardProps) => {
  return (
    <Box
      bg="white"
      borderRadius="4px"
      border="1px solid #D5D5D5"
      background={'#F9F9F9'}
      p="10px"
      w={'100%'}
      h={'100%'}
      cursor="pointer"
      data-cy={title.replace(/\s+/g, '-').toLowerCase()}
    >
      <VStack alignItems={'flex-start'} onClick={onClick}>
        {icon}
        <Text fontSize="14px" fontWeight="600" color="#333333">
          {title}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#666666">
          {description}
        </Text>
      </VStack>
    </Box>
  );
};
