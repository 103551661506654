import {
  HydratedDelivery,
  HydratedOrder,
  MerchantAccount,
  RiderAccount,
  RiderIdentity,
  RiderLocationMap,
} from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropOffMarker } from '../DropOffMarker';
import { MapMarkerProps } from '../Map';
import { MerchantMarker } from '../MerchantMarker';
import { PickupMarker } from '../PickupMarker';
import { RiderMarker } from '../RiderMarker';
import { shouldShowRiderMarker } from '../utils/shouldShowRiderMarker';
import { deliveriesToMarkers } from '../utils/deliveriesToMarkers';
import { useMapPointSelect } from './useMapPointSelect';
import { MapMarkerFilters, useMarkerFilters } from './useMarkerFilters';
import { useMarkerHover } from './useMarkerHover';
import { getIsOnDuty } from '@tradeaze/shared/utils';

const buildStopMarkersFromDelivery = (
  delivery: HydratedDelivery,
): MapMarkerProps[] => {
  return (
    [
      delivery.pickup.position
        ? {
            id: delivery.deliveryId,
            type: 'PICK_UP' as const,
            position: delivery.pickup.position,
            updateZoom: false,
            children: (
              <PickupMarker
                opacity={delivery.pickup.completedAt ? 0.5 : 1}
                stopSequence={0}
              />
            ),
            data: {
              deliveryId: delivery.deliveryId,
              deliveryTime: new Date(delivery.pickup.windowStart),
            },
          }
        : undefined,
      delivery.dropOff.position
        ? {
            id: delivery.deliveryId,
            type: 'DROP_OFF' as const,
            position: delivery.dropOff.position,
            updateZoom: false,
            children: (
              <DropOffMarker
                opacity={delivery.dropOff.completedAt ? 0.5 : 1}
                stopSequence={1}
              />
            ),
            data: {
              deliveryId: delivery.deliveryId,
              deliveryTime: new Date(delivery.dropOff.windowEnd),
            },
          }
        : undefined,
    ].filter((m): m is NonNullable<typeof m> => Boolean(m)) ?? []
  );
};

export const useDashboardMapMarkers = ({
  deliveries,
  merchants,
  riders,
  riderLocations,
  selectedDelivery,
  isAdmin,
  initialFilters,
  hoveredDeliveryId,
  hoveredOrderId,
  onHoverLeaveDelivery,
  onHoverDelivery,
  onClickDelivery,
}: ReturnType<typeof useMarkerHover> & {
  deliveries?: HydratedDelivery[];
  merchants?: MerchantAccount[];
  riders?: (RiderIdentity & Pick<RiderAccount, 'lastClockedInAt' | 'onDuty'>)[];
  riderLocations?: RiderLocationMap;
  selectedDelivery?: HydratedDelivery;
  isAdmin?: boolean;
  initialFilters?: MapMarkerFilters;
  onClickDelivery?: (order: HydratedOrder) => void;
}) => {
  const navigate = useNavigate();

  const mapPoints = useMapPointSelect({
    initialEnabled: false,
    multiPoint: true,
  });

  const { markerFilters, handleMarkerFilterChange } =
    useMarkerFilters(initialFilters);

  const merchantMarkers = useMemo<MapMarkerProps[]>(() => {
    if (!merchants) {
      return [];
    }

    const selectedMerchantId = selectedDelivery?.merchant?.merchantId;

    return merchants.reduce<MapMarkerProps[]>((acc, merchant) => {
      if (
        !merchant.position ||
        (selectedMerchantId && selectedMerchantId !== merchant.merchantId)
      ) {
        return acc;
      }

      acc.push({
        id: merchant.merchantId,
        type: 'MERCHANT',
        children: (
          <MerchantMarker
            name={merchant.merchantName}
            onClick={
              isAdmin
                ? () => navigate(`/merchants/${merchant.merchantId}`)
                : undefined
            }
          />
        ),
        position: merchant.position,
        anchor: 'center',
        data: {
          merchantId: merchant.merchantId,
        },
        updateZoom: false,
      });

      return acc;
    }, []);
  }, [merchants, navigate, selectedDelivery, isAdmin]);

  const riderMarkers = useMemo<MapMarkerProps[]>(() => {
    if (!riders || !riderLocations) {
      return [];
    }

    return riders.reduce<MapMarkerProps[]>((acc, rider) => {
      const riderLocation = riderLocations[rider.riderId];

      if (
        !riderLocation ||
        !shouldShowRiderMarker({
          updatedAt: riderLocation.updatedAt,
          isOnDuty: getIsOnDuty(rider.lastClockedInAt ?? '', rider.onDuty),
          hideClockedOut: true,
        })
      ) {
        return acc;
      }

      acc.push({
        id: rider.riderId,
        type: 'RIDER',
        children: (
          <RiderMarker
            rider={rider}
            updatedAt={riderLocation.updatedAt}
            showInactive={isAdmin}
          />
        ),
        position: riderLocation,
        anchor: 'center',
        data: {
          riderId: rider.riderId,
        },
        updateZoom: false,
      });

      return acc;
    }, []);
  }, [riders, riderLocations, isAdmin]);

  const deliveryMarkers = useMemo<MapMarkerProps[]>(() => {
    if (!deliveries) {
      return [];
    }
    if (selectedDelivery?.deliveryId) {
      const delivery = deliveries.find(
        ({ deliveryId }) => deliveryId === selectedDelivery?.deliveryId,
      );
      if (!delivery) {
        return [];
      }
      return buildStopMarkersFromDelivery(delivery);
    }
    return deliveriesToMarkers({
      deliveries,
      isAdmin,
      hoveredDeliveryId,
      hoveredOrderId,
      onHover: onHoverDelivery,
      onHoverLeave: onHoverLeaveDelivery,
      onClickDelivery,
    });
  }, [
    deliveries,
    selectedDelivery,
    hoveredDeliveryId,
    hoveredOrderId,
    onHoverDelivery,
    onHoverLeaveDelivery,
    onClickDelivery,
    isAdmin,
  ]);

  const mapMarkers = useMemo<MapMarkerProps[]>(() => {
    return [
      ...merchantMarkers,
      ...riderMarkers,
      ...deliveryMarkers,
      ...mapPoints.markers,
    ].filter((marker) => markerFilters[marker.type]);
  }, [
    merchantMarkers,
    riderMarkers,
    deliveryMarkers,
    mapPoints.markers,
    markerFilters,
  ]);

  return useMemo(
    () => ({
      mapMarkers,
      mapPoints,
      markerFilters,
      handleMarkerFilterChange,
    }),
    [mapMarkers, mapPoints, markerFilters, handleMarkerFilterChange],
  );
};
