import React, { useMemo } from 'react';
import { Box, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { DeliveryStopType, DeliveryVehicleId, RiderIdentity } from '@tradeaze-packages/schemas';
import { DeliveryVehicleIcon } from '../delivery/delivery-vehicle/DeliveryVehicleIcon';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { getDifferenceInTime } from '@tradeaze/shared/utils';
import { RiderAvatar } from '../rider/RiderAvatar';

type MarkerHoverCardProps = {
  rider?: Pick<RiderIdentity, 'firstName' | 'lastName'>;
  deliveryVehicle: DeliveryVehicleId;
  pickupPostcode: string;
  deliveryPostcode: string;
  timeRemainingText: string;
  timeRemainingColour: string;
};

const MarkerHoverCard = ({ 
  rider, 
  deliveryVehicle, 
  pickupPostcode, 
  deliveryPostcode, 
  timeRemainingText, 
  timeRemainingColour 
}: MarkerHoverCardProps) => (
  <Stack
    bg="white"
    borderRadius={5}
    paddingX={2}
    textAlign="center"
    spacing={0}
  >
    {rider ? (
      <HStack spacing={1} justifyContent="center">
        <Text fontSize={10}>{rider?.firstName}</Text>
        <DeliveryVehicleIcon
          fontSize={10}
          deliveryVehicle={deliveryVehicle}
        />
      </HStack>
    ) : null}
    <Text fontSize={10}>
      {pickupPostcode}
      {' - '}
      {deliveryPostcode}
    </Text>
    <Text fontSize={10} color={timeRemainingColour}>
      {timeRemainingText}
    </Text>
  </Stack>
);

type DeliveryMarkerProps = {
  deliveryId: string;
  deliveryWindowEnd: string;
  deliveryPostcode: string;
  pickUpPostcode: string;
  deliveryVehicle: DeliveryVehicleId;
  isAdmin?: boolean;
  rider?: {
    firstName: string;
    lastName?: string | null;
  };
  stopType?: DeliveryStopType;
  onHover?: (deliveryId: string) => void;
  onHoverLeave?: (deliveryId: string) => void;
  onClick: () => void;
  isHovered?: boolean;
  isSelected?: boolean;
  opacity?: number;
};

const shouldRerender = (prevProps: DeliveryMarkerProps, nextProps: DeliveryMarkerProps) => {
  return (
    prevProps.isHovered === nextProps.isHovered &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.rider?.firstName === nextProps.rider?.firstName &&
    prevProps.rider?.lastName === nextProps.rider?.lastName
  );
};

export const DeliveryMarker = React.memo<DeliveryMarkerProps>(({
  deliveryWindowEnd,
  deliveryId,
  deliveryPostcode,
  deliveryVehicle,
  pickUpPostcode: pickupPostcode,
  isAdmin,
  rider,
  stopType,
  onHover,
  onHoverLeave,
  onClick,
  isHovered,
  isSelected,
  opacity,
}) => {
  const handleHover = () => {
    onHover?.(deliveryId);
  };

  const handleLeaveHover = () => {
    onHoverLeave?.(deliveryId);
  };

  const timeRemaining = useMemo(
    () => getDifferenceInTime(new Date(), new Date(deliveryWindowEnd)),
    [deliveryWindowEnd]
  );

  const timeRemainingText = useMemo(() => {
    if (timeRemaining.text.includes('late')) {
      return isAdmin ? timeRemaining.text : 'Due';
    }
    return timeRemaining.text;
  }, [timeRemaining, isAdmin]);

  const timeRemainingColour = useMemo(() => {
    if (timeRemaining.text.includes('late')) {
      return isAdmin ? 'red' : 'orange';
    }
    if (
      timeRemaining.values.hours !== undefined &&
      timeRemaining.values.hours < 1
    ) {
      return 'orange';
    }
    return 'green';
  }, [timeRemaining, isAdmin]);

  const markerColor = useMemo(() => {
    return stopType === 'PICK_UP' ? 'blue.600' : 'green.500';
  }, [stopType]);

  return (
    <Stack
      direction={'column'}
      alignItems="center"
      spacing={1}
      onClick={onClick}
      cursor="pointer"
      onMouseEnter={handleHover}
      onMouseLeave={handleLeaveHover}
      opacity={opacity}
    >
      {(isHovered || isSelected) && (
        <MarkerHoverCard
          rider={rider}
          deliveryVehicle={deliveryVehicle}
          pickupPostcode={pickupPostcode}
          deliveryPostcode={deliveryPostcode}
          timeRemainingText={timeRemainingText}
          timeRemainingColour={timeRemainingColour}
        />
      )}
      <Box position={'relative'}>
        {rider && (
          <Box position="absolute" left={'7.5px'} top="4px">
            <RiderAvatar
              height={'15px'}
              width={'15px'}
              size={'xs'}
              firstName={rider.firstName}
              lastName={rider.lastName}
            />
          </Box>
        )}
        <Icon as={FaMapMarkerAlt} fontSize={30} color={markerColor} />
      </Box>
    </Stack>
  );
}, shouldRerender);

DeliveryMarker.displayName = 'DeliveryMarker';