import { MeasurementUnit } from '../../orderConstants';

export const unitConverter = (
  value: number | undefined,
  fromUnit: MeasurementUnit | undefined,
  toUnit: MeasurementUnit,
): number | undefined => {
  const conversionRates: { [key in MeasurementUnit]: number } = {
    mm: 1,
    cm: 10,
    m: 1000,
  };
  if (!value || !fromUnit) {
    return undefined;
  }
  const baseValue = value * conversionRates[fromUnit];
  return baseValue / conversionRates[toUnit];
};
