import { GrLocation } from 'react-icons/gr';
import React from 'react';
import { OrderSection } from '../../order-section';
import { FormAttribute } from '../../../form';
import { Button } from '@chakra-ui/react';
import { useFieldVisibility } from '../../hooks';
import { useFormContext } from 'react-hook-form';
import { CreateOrder } from '@tradeaze-packages/schemas';

type BookingInformationSectionProps = {
  sectionName: string;
  isSectionComplete: boolean;
};

export const BookingInformationSection = ({
  sectionName,
  isSectionComplete,
}: BookingInformationSectionProps) => {
  const form = useFormContext<CreateOrder>();
  const notesVisibility = useFieldVisibility(Boolean(form.getValues('notes')));

  const {
    register,
    formState: { errors },
  } = useFormContext<CreateOrder>();

  return (
    <OrderSection
      name={sectionName}
      icon={<GrLocation />}
      isComplete={isSectionComplete}
    >
      <FormAttribute
        id={'bookedBy'}
        label={'Booked By'}
        description={'The name of the person who is booking out this delivery'}
        placeholder={'Your Name'}
        isRequired
        isInvalid={Boolean(errors.bookedBy)}
        error={errors.bookedBy}
        mb={4}
        data-cy={'bookedBy'}
        {...register('bookedBy')}
      />
      <FormAttribute
        id={'orderReference'}
        label={'Purchase Order Reference'}
        description={
          'Include a reference so that we can invoice for this booking'
        }
        placeholder={'Order Reference'}
        isRequired={true}
        error={errors.merchantOrderReference}
        mb={4}
        data-cy={'merchantOrderReference'}
        {...register('merchantOrderReference')}
      />
      {notesVisibility.isVisible ? (
        <FormAttribute
          id={'notes'}
          label={'Notes for Operator'}
          description={'Notes for this order that only the operator can see'}
          isRequired={false}
          error={errors.notes}
          inputType={'textarea'}
          data-cy={'notes'}
          {...register('notes')}
        />
      ) : (
        <Button
          colorScheme="blue"
          variant={'link'}
          onClick={notesVisibility.handleShow}
          data-cy={'addNotes'}
        >
          Add Notes for Operator
        </Button>
      )}
    </OrderSection>
  );
};
