import { useMemo } from 'react';
import { useSubscribeToRiderLocations } from '@tradeaze/frontend/hooks';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import {
  DropOffMarker,
  MapMarkerProps,
  PickupMarker,
  RiderMarker,
  shouldShowRiderMarker,
} from '../../map';

export const useOrderPageMarkers = (order?: HydratedOrder) => {
  const riders = useMemo(() => {
    return order?.deliveries.map((d) => d.rider).filter(Boolean) ?? [];
  }, [order]);

  const riderLocations = useSubscribeToRiderLocations(
    riders.map((r) => r?.riderId || ''),
  );

  return useMemo<MapMarkerProps[]>(() => {
    const markers: MapMarkerProps[] = [];
    const deliveryStopMarkers =
      order?.deliveries
        .flatMap((d) => [d.pickup, d.dropOff])
        ?.map((ds, index) =>
          ds.position
            ? {
                id: ds.deliveryStopId,
                type:
                  ds.type === 'PICK_UP'
                    ? ('PICK_UP' as const)
                    : ('DROP_OFF' as const),
                position: ds.position,
                updateZoom: true,
                children:
                  ds.type === 'PICK_UP' ? <PickupMarker /> : <DropOffMarker />,
              }
            : undefined,
        )
        .filter((m): m is NonNullable<typeof m> => Boolean(m)) ?? [];

    markers.push(...deliveryStopMarkers);

    if (order?.orderStatus === 'CONFIRMED' && riderLocations) {
      Object.entries(riderLocations).forEach(([riderId, riderLocation]) => {
        const rider = riders.find((r) => r?.riderId === riderId);
        if (
          shouldShowRiderMarker({
            updatedAt: riderLocation.updatedAt,
            isOnDuty: true,
          }) &&
          rider
        ) {
          markers.push({
            id: riderId,
            type: 'RIDER',
            position: riderLocation,
            updateZoom: true,
            children: (
              <RiderMarker
                rider={rider}
                updatedAt={riderLocation.updatedAt}
                alwaysShowDetails
              />
            ),
          });
        }
      });
    }

    return markers;
  }, [order, riderLocations, riders]);
};
