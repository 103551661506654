import 'react-datepicker/dist/react-datepicker.css';
import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Toaster } from 'react-hot-toast';
import { AnimatePresence } from 'framer-motion';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import React from 'react';
import {
  ChakraStyleProvider,
  getAuthHeader,
  getJwt,
  globalSignOut,
  Layout,
  OutdatedAppPopup,
  PageTransition,
  ScrollToTop,
  StorageBucketProvider,
  useCheckAuthSession,
} from '@tradeaze/frontend/ui-web';
import { captureEvent } from '@tradeaze/frontend/utils';
import RequireAuth from '../components/auth/RequireAuth';
import SignInPage from '../pages/SignInPage';
import Navbar from '../components/navbar/NavBar';
import { AuthProvider } from '../components/auth/AuthProvider';
import SignUpPage from '../pages/SignUpPage';
import ConfirmSignupPage from '../pages/ConfirmSignupPage';
import CreateAccountPage from '../pages/CreateAccountPage';
import { OrderDashboardPage } from '../pages/OrderDashboardPage';
import AccountPage from '../pages/AccountPage';
import AddressBookPage from '../pages/AddressBookPage';
import CompanyPage from '../pages/CompanyPage';
import OrderPage from '../pages/OrderPage';
import CreateOrderPage from '../pages/CreateOrderPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ConfirmForgotPasswordPage from '../pages/ConfirmForgotPasswordPage';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { EditOrderPage } from '../pages/EditOrderPage';
import { IntercomProvider } from '../components/intercom/Intercom';
import { PostHogInitiator } from '../components/analytics/PostHog';
import { usePostHog } from 'posthog-js/react';
import { setupApiClient } from '@tradeaze-packages/api-client';

setupApiClient({
  baseUrl: import.meta.env['VITE_BASE_API_URL'],
  getJwt: getJwt,
});

const getServiceUrls = () => {
  const baseUrl = import.meta.env['VITE_BASE_API_URL'];

  if (!baseUrl) {
    throw new Error('BASE_API_URL is not set');
  }

  return {
    merchant: `${baseUrl}/merchant`,
    order: `${baseUrl}/order`,
    delivery: `${baseUrl}/delivery`,
    rider: `${baseUrl}/rider`,
    config: `${baseUrl}/config`,
  };
};

const serviceUrls = getServiceUrls();

Amplify.configure({
  Auth: {
    userPoolId: import.meta.env['VITE_MERCHANT_USER_POOL_ID'],
    userPoolWebClientId: import.meta.env['VITE_MERCHANT_USER_POOL_CLIENT_ID'],
    identityPoolId: import.meta.env['VITE_MERCHANT_IDENTITY_POOL_ID'],
    region: import.meta.env['VITE_AWS_REGION'],
  },
  Storage: {
    AWSS3: {
      bucket: import.meta.env['VITE_ORDER_CSV_BUCKET'],
      region: import.meta.env['VITE_AWS_REGION'],
    },
  },
  API: {
    endpoints: [
      {
        name: 'MerchantApi',
        endpoint: serviceUrls.merchant,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'OrderApi',
        endpoint: serviceUrls.order,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'DeliveryApi',
        endpoint: serviceUrls.delivery,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'RiderApi',
        endpoint: serviceUrls.rider,
        custom_header: getAuthHeader(globalSignOut),
      },
      {
        name: 'ConfigApi',
        endpoint: serviceUrls.config,
        custom_header: getAuthHeader(globalSignOut),
      },
    ],
  },
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error) {
      Sentry.captureException(error);
    },
  }),
  mutationCache: new MutationCache({
    onError(error) {
      Sentry.captureException(error);
    },
  }),
});

const AppPage: React.FC<{
  id: string;
  children: React.ReactNode;
  requireAuth?: boolean;
  defaultLayout?: boolean;
}> = ({ id, children, requireAuth = true, defaultLayout = true }) => {
  let Page = (
    <PageTransition key={id}>
      <ScrollToTop>{children}</ScrollToTop>
    </PageTransition>
  );

  if (requireAuth) {
    Page = <RequireAuth>{Page}</RequireAuth>;
  }

  if (defaultLayout) {
    Page = <Layout>{Page}</Layout>;
  }

  return Page;
};

function App() {
  const posthog = usePostHog();

  useCheckAuthSession();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ChakraStyleProvider>
        <StorageBucketProvider
          buckets={{
            riderProfilePicBucket: import.meta.env[
              'VITE_RIDER_PROFILE_PIC_BUCKET'
            ],
            uploadInvoiceBucket: import.meta.env[
              'VITE_INVOICE_BUCKET'
            ] as string,
          }}
        >
          <AuthProvider>
            <PostHogInitiator />
            <IntercomProvider>
              <OutdatedAppPopup
                appName="merchant-app"
                onRefresh={() => {
                  captureEvent(posthog)('outdated_refresh');
                }}
                onIgnore={() => {
                  captureEvent(posthog)('outdated_ignore');
                }}
              />
              <Navbar />
              <AnimatePresence>
                <Routes>
                  <Route
                    path="/signin"
                    element={
                      <AppPage id="signin" requireAuth={false}>
                        <SignInPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <AppPage id="signup" requireAuth={false}>
                        <SignUpPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/confirm-signup"
                    element={
                      <AppPage id="confirm-signup" requireAuth={false}>
                        <ConfirmSignupPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <AppPage id="forgot-password" requireAuth={false}>
                        <ForgotPasswordPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/confirm-forgot-password"
                    element={
                      <AppPage id="confirm-forgot-password" requireAuth={false}>
                        <ConfirmForgotPasswordPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/reauth"
                    element={
                      <AppPage id="reauth" requireAuth={false}>
                        <SignInPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/create-account"
                    element={
                      <AppPage id="create-account" defaultLayout={false}>
                        <CreateAccountPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <AppPage id="order-dashboard" defaultLayout={false}>
                        <OrderDashboardPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/account"
                    element={
                      <AppPage id="account" defaultLayout={false}>
                        <AccountPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/address-book"
                    element={
                      <AppPage id="address-book">
                        <AddressBookPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/company/:companyId"
                    element={
                      <AppPage id="company" defaultLayout={false}>
                        <CompanyPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/orders/:orderId"
                    element={
                      <AppPage id="order" defaultLayout={false}>
                        <OrderPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/create-order"
                    element={
                      <AppPage id="create-order" defaultLayout={false}>
                        <CreateOrderPage />
                      </AppPage>
                    }
                  />
                  <Route
                    path="/edit-order/:orderId"
                    element={
                      <AppPage id="edit-order-page" defaultLayout={false}>
                        <EditOrderPage />
                      </AppPage>
                    }
                  />
                </Routes>
              </AnimatePresence>
              <Toaster position="bottom-center" />
            </IntercomProvider>
          </AuthProvider>
        </StorageBucketProvider>
      </ChakraStyleProvider>
    </QueryClientProvider>
  );
}

export default App;
