import {
  DimensionUnit,
  MAX_LOAD_HEIGHT,
  MAX_LOAD_LENGTH,
  MAX_LOAD_WIDTH,
} from '@tradeaze-packages/schemas';

export const getMaxValue = (
  unit: DimensionUnit,
  type: 'length' | 'width' | 'height',
) => {
  const maxValues = {
    m: {
      length: MAX_LOAD_LENGTH / 1000,
      width: MAX_LOAD_WIDTH / 1000,
      height: MAX_LOAD_HEIGHT / 1000,
    },
    cm: {
      length: MAX_LOAD_LENGTH / 10,
      width: MAX_LOAD_WIDTH / 10,
      height: MAX_LOAD_HEIGHT / 10,
    },
    mm: {
      length: MAX_LOAD_LENGTH,
      width: MAX_LOAD_WIDTH,
      height: MAX_LOAD_HEIGHT,
    },
  };
  return maxValues[unit][type];
};
