import { z } from 'zod';
import { nashErrorDataSchema } from '../nash';

export const buildAxiosErrorSchema = <T extends z.ZodRawShape>(
  errorDataSchema: z.ZodObject<T>
)=>
  z.object({
    response: z.object({
      data: errorDataSchema,
      status: z.number(),
    }),
  });

const defaultErrorDataSchema = z.object({
  error: z.object({
    message: z.string(),
  }),
});

export const axiosErrorSchema = buildAxiosErrorSchema(defaultErrorDataSchema);

export const nashErrorSchema = buildAxiosErrorSchema(nashErrorDataSchema);
