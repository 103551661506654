import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useInvalidateQueries } from '../utility';
import { getOrderQueryKey } from './useGetOrder';
import { getMerchantOrdersQueryKey } from './useGetMerchantOrders';
import { merchantUpdateOrder } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { handleError } from '@tradeaze/frontend/utils';

export const useMerchantUpdateOrder = (
  options?: CustomUseMutationOptions<typeof merchantUpdateOrder>,
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation(
    {
      mutationFn: merchantUpdateOrder,
      ...options,
      onSuccess: (data, variables, context) => {
        // Invalidate and refetch
        toast.success('Updated successfully');
        invalidateQueries([
          getOrderQueryKey(variables.orderId),
          getMerchantOrdersQueryKey(),
        ]);
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        handleError(new Error('Error updating order'), {
          sendToSentry: true,
          sentryContext: error,
        });
        toast.error('Error updating');
        options?.onError?.(error, variables, context);
      },
    },
  );
};
