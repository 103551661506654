import { CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import React from 'react';
import { resetAddressFields } from '../resetAddressFields';

type HandleToggleDefaultPickupParams = {
  setValue: UseFormReturn<CreateDeliveryStop>['setValue'];
  defaultPickupAddress?: string;
  defaultPickupPostcode?: string;
  defaultPickupPosition?: { latitude: number; longitude: number } | null;
  merchantAccount?: { merchantName?: string };
  setShouldUseDefaultPickup: (value: boolean) => void;
  handleManualAddress: (value?: boolean) => void;
};

export const handleToggleDefaultPickup =
  ({
    setValue,
    defaultPickupAddress,
    defaultPickupPostcode,
    defaultPickupPosition,
    merchantAccount,
    setShouldUseDefaultPickup,
    handleManualAddress,
  }: HandleToggleDefaultPickupParams) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && defaultPickupAddress && defaultPickupPostcode) {
      setShouldUseDefaultPickup(true);
      handleManualAddress(false);
      setValue(
        'companyName',
        merchantAccount?.merchantName || '',
      );
      setValue('addressLine1', defaultPickupAddress);
      setValue('postCode', defaultPickupPostcode);
      setValue('position', defaultPickupPosition);
    } else {
      setValue('companyName', '');
      setShouldUseDefaultPickup(false);
      resetAddressFields(setValue);
    }
  };
