import { CreateOrder, DeliveryOptionId } from '@tradeaze-packages/schemas';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { calculateDeliveryWindow } from '@tradeaze/shared/utils';

export const setDeliveryTimes = ({
  fromDateTime,
  deliveryOption,
  totalTimeEstimate,
  scheduledDateTime,
  setValue,
  getValues,
}: {
  fromDateTime: Date;
  deliveryOption: DeliveryOptionId | null;
  totalTimeEstimate?: number;
  scheduledDateTime?: Date;
  setValue: UseFormSetValue<CreateOrder>;
  getValues: UseFormGetValues<CreateOrder>;
}) => {
  if (!deliveryOption) {
    setValue('deliveryWindowStart', null as unknown as string);
    setValue('deliveryWindowEnd', null as unknown as string);
    return;
  }

  const deliveryWindow = calculateDeliveryWindow({
    deliveryOptionId: deliveryOption,
    fromDateTime,
    totalTimeEstimate,
    scheduledTime: scheduledDateTime,
  });

  const windowStart = deliveryWindow.start.toISOString();
  const windowEnd = deliveryWindow.end.toISOString();

  setValue('deliveryWindowStart', windowStart);
  setValue('deliveryWindowEnd', windowEnd);
};
