import {
  Box,
  Center,
  Heading,
  Select,
  Show,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  AtoBTypeIcon,
  MultiCollectionTypeIcon,
  MultiDropTypeIcon,
  // MultiDropTypeIcon,
} from '../assets/orderTypeIcons';
import { OrderTypeCard } from './order-type-card';
import React from 'react';
import { MerchantAccount, OrderType } from '@tradeaze-packages/schemas';
import { ENABLE_MULTI_DROP } from '@tradeaze/frontend/utils';

type OrderOptionsProps = {
  handleOrderSelection: (orderType: OrderType) => void;
  isAdmin: boolean;
  merchantsLoading?: boolean;
  selectedMerchantId?: string;
  handleMerchantSelect?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  sortedMerchants?: MerchantAccount[];
};

type OrderTypeOption = {
  icon: React.ReactNode;
  title: string;
  description: string;
  enabled: boolean;
};

export const orderTypeOptions: Partial<Record<OrderType, OrderTypeOption>> = {
  A_TO_B: {
    icon: <AtoBTypeIcon height={80} />,
    title: 'A to B',
    description:
      'One way trip from location A to location B. Manage one single collection to one single destination.',
    enabled: true,
  },
  MULTI_COLLECTION: {
    icon: <MultiCollectionTypeIcon height={80} />,
    title: 'Multi-Collection',
    description:
      'Load several times and unload once. Manage multiple collections to one single destination.',
    enabled: true,
  },
  MULTI_DROP: {
    icon: <MultiDropTypeIcon height={80} />,
    title: 'Multi-Drop',
    description:
      'Load once and unload several times. Manage one single collection to multiple destinations.',
    enabled: ENABLE_MULTI_DROP,
  },
};

export const OrderOptions = ({
  handleOrderSelection,
  isAdmin,
  merchantsLoading,
  selectedMerchantId,
  handleMerchantSelect,
  sortedMerchants,
}: OrderOptionsProps) => {
  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Box display={'flex'} flexDirection={'column'} gap={4}>
        <Heading size="md" mb={4}>
          Create an order
        </Heading>

        {isAdmin ? (
          <Box>
            <Text data-cy={'order-address-text'} mb={1}>
              Create order for:{' '}
            </Text>
            {merchantsLoading ? (
              <Center h="16">
                <Spinner />
              </Center>
            ) : (
              <Select
                data-cy={'order-address-select'}
                defaultValue={selectedMerchantId}
                onChange={handleMerchantSelect}
              >
                <option value={undefined} data-cy={'order-address-blank'}>
                  -
                </option>
                {sortedMerchants?.map((merchant, i) => (
                  <option
                    value={merchant.merchantId}
                    data-cy={`order-address-${i + 1}`}
                  >
                    {merchant.merchantName} - {merchant.address},{' '}
                    {merchant.postCode}
                  </option>
                ))}
              </Select>
            )}
          </Box>
        ) : null}

        <Text fontSize="16px" fontWeight={500}>
          Which delivery type do you need?
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} mt={4}>
          {Object.entries(orderTypeOptions)
            .filter(([_, option]) => option.enabled)
            .map(([orderType, option]) => (
              <OrderTypeCard
                key={orderType}
                icon={option.icon}
                title={option.title}
                description={option.description}
                onClick={() => handleOrderSelection(orderType as OrderType)}
              />
            ))}
          <div style={{ width: '250px', height: '1px' }} />
        </SimpleGrid>
      </Box>
      <Show above="2xl">
        <Box width={'100px'} ml={8}></Box>
      </Show>
    </Box>
  );
};
