import { DeliveryVehicleId, OrderType } from '@tradeaze-packages/schemas';

export type MeasurementUnit = 'mm' | 'cm' | 'm';

export type ModalVehicleDimensions = {
  length: number;
  width: number;
  xPosition: string;
  yPosition: string;
};

export const measurmentUnits = [
  {
    label: 'millimetres',
    value: 'mm',
  },
  {
    label: 'centimetres',
    value: 'cm',
  },
  {
    label: 'metres',
    value: 'm',
  },
];

export const vehicleSortOrder: DeliveryVehicleId[] = [
  'BIKE',
  'CAR',
  'VAN_LARGE',
  'VAN_XLARGE',
  'LUTON',
  'FLATBED',
];

export const REFRESH_INTERVAL_MS = 60 * 1000;

export const vehicleDimensions: Record<
  DeliveryVehicleId,
  Record<'SIDE' | 'REAR', ModalVehicleDimensions>
> = {
  BIKE: {
    REAR: {
      length: 72,
      width: 72,
      xPosition: '133',
      yPosition: '56',
    },
    SIDE: {
      length: 72,
      width: 72,
      xPosition: '144',
      yPosition: '56',
    },
  },
  CAR: {
    REAR: {
      length: 74,
      width: 87,
      xPosition: '139',
      yPosition: '56',
    },
    SIDE: {
      length: 74,
      width: 114,
      xPosition: '139',
      yPosition: '56',
    },
  },
  FLATBED: {
    REAR: {
      length: 128.341,
      width: 102,
      xPosition: '141',
      yPosition: '52',
    },
    SIDE: {
      length: 111,
      width: 354,
      xPosition: '141',
      yPosition: '56',
    },
  },
  VAN_LARGE: {
    REAR: {
      length: 128.341,
      width: 102,
      xPosition: '141',
      yPosition: '56',
    },
    SIDE: {
      length: 111,
      width: 173,
      xPosition: '141',
      yPosition: '56',
    },
  },
  VAN_XLARGE: {
    REAR: {
      length: 128.341,
      width: 102,
      xPosition: '141',
      yPosition: '52',
    },
    SIDE: {
      length: 111,
      width: 268,
      xPosition: '141',
      yPosition: '56',
    },
  },
  LUTON: {
    REAR: {
      length: 136.727,
      width: 137,
      xPosition: '138',
      yPosition: '55',
    },
    SIDE: {
      length: 125,
      width: 233,
      xPosition: '141',
      yPosition: '56',
    },
  },
};

export const OrderTypeLabel: Record<OrderType, string> = {
  A_TO_B: 'A to B',
  MULTI_COLLECTION: 'Multi Collection',
  MULTI_DROP: 'Multi Drop',
};
