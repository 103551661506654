import { useMutation } from '@tanstack/react-query';
import { DownloadMerchantDeliveriesCsvQuery } from '@tradeaze-packages/schemas';
import { downloadUrl } from '@tradeaze/frontend/utils';
import { fetchMerchantDeliveriesCsv } from '@tradeaze-packages/api-client';
import toast from 'react-hot-toast';

export const useDownloadMerchantDeliveries = (merchantId: string) => {
  let loadingToastId: string;

  return useMutation(
    {
      mutationFn: async (filters: DownloadMerchantDeliveriesCsvQuery) => fetchMerchantDeliveriesCsv(merchantId, filters),
      onError() {
        toast.error('Error generating CSV');
      },
      onSuccess: async (data) => {
        toast.success('Generated CSV');
        downloadUrl(data.url, `Merchant Deliveries - ${new Date().toISOString()}.csv`);
      },
      onMutate: () => {
        loadingToastId = toast.loading('Generating CSV');
      },
      onSettled: () => {
        toast.dismiss(loadingToastId);
      },
    }
  );
};
