import { z } from 'zod';
import { PositionSchema } from './shared-schema';
import { OrderStatusSchema } from './order-schema';
import { CurrentDeliveryOptionIdSchema } from './delivery-option-schema';
import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { EtaStatusSchema } from './eta-status-schema';
import { validateDatetime } from '../utils/validateDatetime';

extendZodWithOpenApi(z);

const PublicCurrencySchema = z.enum(['GBP']);

export const PublicPriceSchema = z
  .object({
    amount: z.number({
      description:
        'Amount in the currency specified (Excl. VAT). Example: amount 23.50 in currency GBP is £23.50',
    }),
    currency: PublicCurrencySchema,
  })
  .openapi('Price');

const PublicDeliveryStopTypeSchema = z
  .enum(['DROP_OFF', 'PICK_UP'])
  .openapi('DeliveryStopType');

export const PublicDimensionMeasurementSchema = z
  .object({
    value: z.number(),
    unit: z.enum(['m']),
  })
  .openapi('DimensionMeasurement');

export const PublicWeightMeasurementSchema = z
  .object({
    value: z.number(),
    unit: z.enum(['kg']),
  })
  .openapi('WeightMeasurement');

/**
 * Stops
 */

const CommonPublicDeliveryStopSchema = z
  .object({
    id: z.string(),
    arrivedAt: z.string().nullish(),
    completedAt: z.string().nullish(),
    windowStart: z.string().nullish(),
    windowEnd: z.string().nullish(),
    addressLine1: z.string(),
    addressLine2: z.string().nullish(),
    addressLine3: z.string().nullish(),
    postCode: z.string(),
    city: z.string().nullish(),
    country: z.string().nullish(),
    position: PositionSchema.nullish(),
    instructions: z.string().nullish(),
    contactName: z.string().nullish(),
    contactNumber: z.string().nullish(),
    companyName: z.string().nullish(),
    failureReason: z.string().nullish(),
    collectionReference: z.string().nullish(),
    proofName: z.string().nullish(),
    proofImages: z.array(z.string()).nullish(),
    proofSignature: z.string().nullish(),
    requiresSignature: z.boolean(),
    requiresImage: z.boolean(),
  })
  .openapi('DeliveryStop');

const PublicPickupStopSchema = CommonPublicDeliveryStopSchema.extend({
  type: z.literal('PICK_UP'),
});

const PublicDropoffStopSchema = CommonPublicDeliveryStopSchema.extend({
  type: z.literal('DROP_OFF'),
});

export const PublicDeliveryStopSchema = z.union([
  PublicPickupStopSchema,
  PublicDropoffStopSchema,
]);

const createStopOmitedFields = {
  id: true,
  completedAt: true,
  arrivedAt: true,
  failureReason: true,
  proofName: true,
  proofImages: true,
  proofSignature: true,
  windowStart: true,
  windowEnd: true,
} as const;

const PublicPickupStopCreateSchema = PublicPickupStopSchema.omit(
  createStopOmitedFields
);

const PublicDropoffStopCreateSchema = PublicDropoffStopSchema.omit(
  createStopOmitedFields
);

const PublicDeliveryStopCreateSchema = z.union([
  PublicPickupStopCreateSchema,
  PublicDropoffStopCreateSchema,
]);

const updateStopOmittedFields = {
  completedAt: true,
  arrivedAt: true,
  failureReason: true,
  proofName: true,
  proofImages: true,
  proofSignature: true,
  windowStart: true,
  windowEnd: true,
  addressLine1: true,
  addressLine2: true,
  addressLine3: true,
  postCode: true,
  city: true,
  country: true,
  position: true,
  type: true,
} as const;

const PublicPickupStopUpdateSchema = PublicPickupStopSchema.omit(
  updateStopOmittedFields
)
  .partial()
  .strict();

const PublicDropoffStopUpdateSchema = PublicDropoffStopSchema.omit(
  updateStopOmittedFields
)
  .partial()
  .strict();

/**
 * Items
 */

const PublicDeliveryItemsSchema = z
  .object({
    id: z.string(),
    externalId: z.string().nullish(),
    name: z.string(),
    quantity: z.number(),
    width: PublicDimensionMeasurementSchema,
    height: PublicDimensionMeasurementSchema,
    length: PublicDimensionMeasurementSchema,
    weight: PublicWeightMeasurementSchema,
    description: z.string().nullish(),
    value: PublicPriceSchema.nullish(),
  })
  .openapi('DeliveryItem');

const createItemOmittedFields = {
  id: true,
} as const;

const PublicDeliveryItemsCreateSchema = PublicDeliveryItemsSchema.omit(
  createItemOmittedFields
);

const updateItemOmittedFields = createItemOmittedFields;

const PublicDeliveryItemsUpdateSchema = PublicDeliveryItemsSchema.omit(
  updateItemOmittedFields
)
  .partial() // all fields are optional
  .strict(); // throw error if unknown keys are present

/**
 * Courier
 */

const PublicCourierSchema = z
  .object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string().nullish(),
    contactNumber: z.string().nullish(),
    location: PositionSchema.nullish(),
  })
  .openapi('Courier');

/**
 * Delivery Vehicle
 */

const CapacitySchema = z
  .object({
    length: PublicDimensionMeasurementSchema.nullish(),
    width: PublicDimensionMeasurementSchema.nullish(),
    height: PublicDimensionMeasurementSchema.nullish(),
    weight: PublicWeightMeasurementSchema.nullish(),
  })
  .openapi('Capacity');

export const PublicDeliveryVehicleSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullish(),
    capacity: CapacitySchema,
  })
  .openapi('DeliveryVehicle');

/**
 * Delivery
 */

export const PublicDeliverySchema = z
  .object({
    createdAt: z.string(),
    updatedAt: z.string(),
    id: z.string(),
    externalId: z.string().nullish(),
    orderStatus: OrderStatusSchema,
    deliveryStatus: EtaStatusSchema,
    deliveryOptionId: CurrentDeliveryOptionIdSchema,
    deliveryPrice: PublicPriceSchema,
    serviceCharge: PublicPriceSchema,
    tips: PublicPriceSchema.nullish(),
    pickup: PublicPickupStopSchema,
    dropoff: PublicDropoffStopSchema,
    items: z.array(PublicDeliveryItemsSchema).nullish(),
    courier: PublicCourierSchema.nullish(),
    metadata: z
      .record(
        z.string(),
        z.any({
          description: 'Any external metadata about the delivery',
        })
      )
      .nullish(),
  })
  .openapi('Delivery');

const createDeliveryOmittedFields = {
  id: true,
  courier: true,
  createdAt: true,
  updatedAt: true,
  deliveryStatus: true,
  deliveryPrice: true,
  serviceCharge: true,
  orderStatus: true,
} as const;

export const PublicDeliveryCreateSchema = PublicDeliverySchema.omit(
  createDeliveryOmittedFields
).extend({
  pickup: PublicPickupStopCreateSchema,
  dropoff: PublicDropoffStopCreateSchema,
  items: z.array(PublicDeliveryItemsCreateSchema),
  startTime: validateDatetime,
});

const updateDeliveryOmittedFields = {
  ...createDeliveryOmittedFields,
  deliveryOptionId: true,  
  items: true,
} as const;

export const PublicDeliveryUpdateSchema = PublicDeliverySchema.omit(
  updateDeliveryOmittedFields
)
  .extend({
    pickup: PublicPickupStopUpdateSchema,
    dropoff: PublicDropoffStopUpdateSchema,
  })
  .partial() // all fields are optional;
  .strict(); // throw error if unknown keys are present

/**
 * Type exports
 */

export type PublicCurrency = z.infer<typeof PublicCurrencySchema>;
export type PublicPrice = z.infer<typeof PublicPriceSchema>;
export type PublicDimensionMeasurement = z.infer<
  typeof PublicDimensionMeasurementSchema
>;
export type PublicWeightMeasurement = z.infer<
  typeof PublicWeightMeasurementSchema
>;
export type PublicCourier = z.infer<typeof PublicCourierSchema>;
export type PublicCapacity = z.infer<typeof CapacitySchema>;
export type PublicDeliveryVehicle = z.infer<typeof PublicDeliveryVehicleSchema>;

export type PublicDeliveryStopType = z.infer<
  typeof PublicDeliveryStopTypeSchema
>;
export type PublicPickupStop = z.infer<typeof PublicPickupStopSchema>;
export type PublicDropoffStop = z.infer<typeof PublicDropoffStopSchema>;
export type PublicDeliveryStop = z.infer<typeof PublicDeliveryStopSchema>;

export type PublicPickupStopCreate = z.infer<
  typeof PublicPickupStopCreateSchema
>;
export type PublicDropoffStopCreate = z.infer<
  typeof PublicDropoffStopCreateSchema
>;
export type PublicDeliveryStopCreate = z.infer<
  typeof PublicDeliveryStopCreateSchema
>;

export type PublicPickupStopUpdate = z.infer<
  typeof PublicPickupStopUpdateSchema
>;
export type PublicDropoffStopUpdate = z.infer<
  typeof PublicDropoffStopUpdateSchema
>;
export type PublicDeliveryStopUpdate = z.infer<typeof PublicDeliveryStopSchema>;

export type PublicDeliveryItems = z.infer<typeof PublicDeliveryItemsSchema>;
export type PublicDeliveryItemsCreate = z.infer<
  typeof PublicDeliveryItemsCreateSchema
>;
export type PublicDeliveryItemsUpdate = z.infer<
  typeof PublicDeliveryItemsUpdateSchema
>;

export type PublicDelivery = z.infer<typeof PublicDeliverySchema>;
export type PublicDeliveryCreate = z.infer<typeof PublicDeliveryCreateSchema>;
export type PublicDeliveryUpdate = z.infer<typeof PublicDeliveryUpdateSchema>;
