import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetAllMerchants } from '@tradeaze/frontend/hooks';
import { useMemo } from 'react';

const MerchantMenuItemOption: React.FC<
  React.ComponentProps<typeof MenuItemOption> & {
    merchantId: string;
    merchantName: string;
    handleToggleMerchant: (merchantId: string) => void;
  }
> = ({ merchantId, merchantName, handleToggleMerchant, ...props }) => {
  return (
    <MenuItemOption
      {...props}
      width="350px"
      onClick={() => handleToggleMerchant(merchantId)}
    >
      {merchantName}
    </MenuItemOption>
  );
};

export const MerchantFilterMenu: React.FC<{
  handleToggleMerchant: (merchantId: string) => void;
  handleSelectAllMerchants?: () => void;
  selectedMerchantIds?: string[];
}> = ({ handleToggleMerchant, handleSelectAllMerchants, selectedMerchantIds = [] }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const merchantQuery = useGetAllMerchants({ enabled: isOpen });

  const filterTitle = useMemo(() => {
    if (!merchantQuery.data) return 'Merchant';
    if (selectedMerchantIds.length === 1) {
      const merchant = merchantQuery.data.find(
        (merchant) => merchant.merchantId === selectedMerchantIds[0]
      );
      if (merchant) return merchant.merchantName;
      return '1 Merchant';
    }
    if (selectedMerchantIds.length > 0) {
      return `${selectedMerchantIds.length} Merchants`;
    }
    return 'Merchants';
  }, [merchantQuery.data, selectedMerchantIds]);

  const ListItems = useMemo(() => {
    if (!merchantQuery.data) return null;
    return merchantQuery.data
      .sort((a, b) => {
        if (a.merchantName < b.merchantName) return -1;
        if (a.merchantName > b.merchantName) return 1;
        return 0;
      })
      .map((merchant) => (
        <MerchantMenuItemOption
          key={merchant.merchantId}
          merchantId={merchant.merchantId}
          merchantName={merchant.merchantName}
          handleToggleMerchant={handleToggleMerchant}
          isChecked={selectedMerchantIds.includes(merchant.merchantId)}
        />
      ));
  }, [merchantQuery.data, selectedMerchantIds, handleToggleMerchant]);

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      closeOnSelect={false}
    >
      <MenuButton
        as={Button}
        colorScheme={'gray'}
        size={'sm'}
        rightIcon={<ChevronDownIcon />}
        onClick={onOpen}
        color={selectedMerchantIds.length > 0 ? 'black' : 'gray.500'}
      >
        {filterTitle}
      </MenuButton>
      <MenuList maxHeight={'300px'} overflow="scroll" minWidth={'200px'}>
        {handleSelectAllMerchants && (
          <MenuItemOption
            isChecked={selectedMerchantIds.length === 0}
            onClick={handleSelectAllMerchants}
          >
            All
          </MenuItemOption>
        )}
        {ListItems}
      </MenuList>
    </Menu>
  );
};
