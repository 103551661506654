import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { FaUndo } from 'react-icons/fa';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import { captureEvent } from '@tradeaze/frontend/utils';
import { OrderPageButton } from './OrderPageButton';
import { buildReturnJourneyOrder } from '../utils/buildReturnJourneyOrder/buildReturnJourneyOrder';

const useReturnOrder = ({ order }: { order: HydratedOrder }) => {
  const posthog = usePostHog();
  const navigate = useNavigate();

  const allowReturn = order.type === 'A_TO_B';

  const handleReturnOrder = () => {
    if (!order || !allowReturn || !order.deliveries[0]) return;

    const returnOrder = buildReturnJourneyOrder(order);

    navigate('/create-order', {
      state: {
        order: returnOrder,
      },
    });

    captureEvent(posthog)('return_order');
  };

  return {
    allowReturn,
    handleReturnOrder,
  };
};

export const ReturnOrder: React.FC<{
  order: HydratedOrder;
}> = ({ order }) => {
  const { handleReturnOrder, allowReturn } = useReturnOrder({ order });

  if (!allowReturn) return null;

  return (
    <OrderPageButton
      icon={<FaUndo fontSize={18} />}
      label="Return"
      onClick={handleReturnOrder}
      data-cy="return-order-button"
    />
  );
};