import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

export const CancelConfirmation: React.FC<{
  cancelType: 'order' | 'delivery' | 'pickup';
  shouldChargeFee: boolean;
  isLoading?: boolean;
  onConfirm: (cancellationReason: string) => void;
  renderButton: ({
    onClick,
    isLoading,
  }: {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading: boolean;
  }) => React.ReactNode;
}> = ({ cancelType, shouldChargeFee, isLoading, onConfirm, renderButton }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpen();
  };

  const handleConfirm = () => {
    onConfirm(cancellationReason);
    onClose();
  };

  // cancellation reason
  const [cancellationReason, setCancellationReason] = React.useState('');

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCancellationReason(e.target.value);
  };

  const isInvalid = cancellationReason.trim().length === 0;

  const error = isInvalid
    ? { message: 'Please enter a reason for cancellation' }
    : undefined;
  const deliveryHeading = cancelType === 'delivery' ? 'Cancel Delivery' : 'Cancel Pickup';
  return (
    <>
      {renderButton({ onClick: handleOpen, isLoading: isLoading || false })}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            {cancelType === 'order' ? 'Cancel Order' : deliveryHeading}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {' '}
            <Stack spacing={3}>
              <Text>
                Are you sure you want to cancel this {cancelType}?{' '}
                {shouldChargeFee
                  ? 'A £5 cancellation fee will be charged.'
                  : 'There will be no cancellation fee.'}
              </Text>
              <Text></Text>
              <FormControl isRequired={true} isInvalid={isInvalid}>
                <FormLabel>Reason for cancellation</FormLabel>
                <Input
                  value={cancellationReason}
                  onChange={handleChangeText}
                  placeholder="Enter reason for cancellation"
                />
                <FormErrorMessage>
                  <Text>{error?.message}</Text>
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button
              onClick={handleConfirm}
              isDisabled={isInvalid}
              colorScheme={'red'}
              ml={3}
              isLoading={isLoading}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
