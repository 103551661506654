import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { findAddresses } from '@tradeaze/shared/services';
import { GET_ADDRESS_API_KEY } from '@tradeaze/frontend/utils';

type Response = Awaited<ReturnType<typeof findAddresses>>;

export const useFindAddresses = (
  postcode: string,
  options?: UseQueryOptions<Response>
) =>
  useQuery<Response>({
    queryKey: ['findAddresses', postcode],
    queryFn: async () => await findAddresses(postcode, {
      apiKey: GET_ADDRESS_API_KEY
    }),
    enabled: false, // use refetch to trigger,
    ...options,
  });
