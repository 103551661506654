import { z } from 'zod';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';
import { RegionIdEnumSchema } from './region-schema';


export const GigJobBoardSchema = z.object({
  id: z.string(),
  gigJobId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  deliveryVehicleId: DeliveryVehicleIdSchema,
  regionId: RegionIdEnumSchema
});
export const GigJobBoardGenerateSchema = z.object({
  id: z.string().optional(),
  gigJobId: z.string(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  deliveryVehicleId: DeliveryVehicleIdSchema,
  regionId: RegionIdEnumSchema
});

export type GigJobBoard = z.infer<typeof GigJobBoardSchema>;
export type GigJobBoardGenerateRequest = z.infer<typeof GigJobBoardGenerateSchema>;
