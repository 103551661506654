import { useState, useCallback, useMemo } from 'react';

export const useMarkerHover = () => {
  const [hoveredDeliveryId, setHoveredDeliveryId] = useState<string>();
  const [hoveredOrderId, setHoveredOrderId] = useState<string>();

  const onHoverDelivery = useCallback(
    (deliveryId: string) => setHoveredDeliveryId(deliveryId),
    [],
  );

  const onHoverLeaveDelivery = useCallback(
    () => setHoveredDeliveryId(undefined),
    [],
  );

  const onHoverOrder = useCallback(
    (orderId: string) => setHoveredOrderId(orderId),
    [],
  );

  const onHoverLeaveOrder = useCallback(() => setHoveredOrderId(undefined), []);

  return useMemo(
    () => ({
      hoveredDeliveryId,
      hoveredOrderId,
      onHoverDelivery,
      onHoverLeaveDelivery,
      onHoverOrder,
      onHoverLeaveOrder
    }),
    [
      hoveredDeliveryId,
      onHoverDelivery,
      onHoverLeaveDelivery,
      hoveredOrderId,
      onHoverOrder,
      onHoverLeaveOrder,
    ],
  );
};
