import { CreateOrder, Order, HydratedOrder } from '@tradeaze-packages/schemas';

export const orderKeyToLabel: {
  [key in keyof CreateOrder | keyof Order | keyof HydratedOrder]: string;
} = {
  type: 'Order Type',
  orderId: 'Order ID',
  apiClientId: 'API Client ID',
  invoicingName: 'Invoicing Name',
  merchantId: 'Merchant ID',
  bookedBy: 'Booked By',
  endUserId: 'End User ID',
  merchantOrderReference: 'Order Reference',
  deliveryPrice: 'Delivery Price',
  merchantServiceCharge: 'Service Charge',
  deliveryDistance: 'Delivery Distance',
  deliveryDuration: 'Delivery Duration',
  isPriceEdited: 'Price Edited Status',
  deliveryWindowEnd: 'Delivery Window End',
  totalTimeEstimate: 'Total Time Estimate',
  deliveryWindowStart: 'Delivery Window Start',
  cancellationFee: 'Cancellation Fee',
  cancellationReason: 'Cancellation Reason',
  deliveryOption: 'Delivery Options',
  collectionReady: 'Collection Ready',
  deliveryVehicle: 'Delivery Vehicle',
  tips: 'Tips',
  confirmedAt: 'Confirmed At',
  cancelledAt: 'Cancelled At',
  createdAt: 'Created At',
  deliveredAt: 'Delivered At',
  orderStatus: 'Order Status',
  updatedAt: 'Updated At',
  tradeazeComments: 'Tradeaze Comments',
  deliveries: 'Deliveries',
  Merchant: 'Merchant',
  metadata: 'Metadata',
  notes: 'Notes for Operator',
  deliveryStops: 'Delivery Stops',
};

export const orderKeyToDescription: Record<
  keyof Pick<CreateOrder, 'merchantOrderReference' | 'bookedBy' | 'notes'>,
  string
> = {
  bookedBy: 'The name of the person who is booking out this delivery',
  merchantOrderReference:
    'Include an order reference to match up with your internal order system',
  notes: 'Notes for this order that only the operator can see',
};

export const orderKeyToPlaceholder: Record<
  keyof Pick<CreateOrder, 'merchantOrderReference' | 'bookedBy'>,
  string
> = {
  bookedBy: 'Your Name',
  merchantOrderReference: 'Order Reference',
};

/**
 * Travis Perkins
 */

export const travisPerkinsOrderKeyToLabel: {
  [key in keyof CreateOrder | keyof Order]: string;
} = {
  ...orderKeyToLabel,
  merchantOrderReference: 'PO Number',
};

export const travisPerkinsOrderKeyToDescription: Record<
  keyof Pick<CreateOrder, 'merchantOrderReference' | 'bookedBy' | 'notes'>,
  string
> = {
  ...orderKeyToDescription,
  merchantOrderReference:
    'The 9 or 11 digit code to match the delivery with your internal order system',
};

export const travisPerkinsOrderKeyToPlaceholder: Record<
  keyof Pick<CreateOrder, 'merchantOrderReference' | 'bookedBy'>,
  string
> = {
  ...orderKeyToPlaceholder,
  merchantOrderReference: 'PO Number',
};
