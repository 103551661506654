import {
  Card,
  CardBody,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { DeliveryVehicleIcon } from '../../../delivery';
import { DeliveryVehicle } from '@tradeaze-packages/schemas';

type DeliveryVehicleCardProps = {
  deliveryVehicle: DeliveryVehicle;
  isSelected: boolean;
  handleClick: () => void;
};

export const DeliveryVehicleCard = ({
  deliveryVehicle,
  isSelected,
  handleClick,
}: DeliveryVehicleCardProps) => {
  const isBase = useBreakpointValue({ base: true, sm: false });
  const {
    deliveryVehicleId,
    heightCapacity,
    lengthCapacity,
    name,
    weightCapacity,
    widthCapacity,
  } = deliveryVehicle;

  return (
    <Card
      background={isSelected ? '#FEFAED' : '#F9F9F9'}
      border={isSelected ? '1px solid #AF9032' : '1px solid #D5D5D5'}
      onClick={handleClick}
      cursor={'pointer'}
      data-cy={deliveryVehicleId}
      id={'vehicle-name'}
    >
      <CardBody py={2} px={4}>
        {isBase ? (
          <VStack spacing={1} alignItems="flex-start">
            <HStack gap={4} alignItems="center">
              <DeliveryVehicleIcon
                deliveryVehicle={deliveryVehicleId}
                fontSize={'1rem'}
              />
              <Text fontSize="14px" fontWeight="600">
                {name}
              </Text>
            </HStack>
            <Text fontSize={'14px'} color="#666668">
              Max. Weight {weightCapacity}kg
            </Text>
            <Text fontSize={'14px'} color="#666668">
              L {lengthCapacity} x W {widthCapacity} x H {heightCapacity}
            </Text>
          </VStack>
        ) : (
          <VStack spacing={1} alignItems={'flex-start'} flex={1}>
            <HStack>
              <DeliveryVehicleIcon
                deliveryVehicle={deliveryVehicleId}
                fontSize={'1rem'}
              />
              <Text fontSize={'14px'} fontWeight="600">
                {name}
              </Text>
            </HStack>
            <Text fontSize={'14px'} color="#666668">
              Max. Weight {weightCapacity}kg
            </Text>
            <Text fontSize={'14px'} color="#666668">
              L {lengthCapacity} x W {widthCapacity} x H {heightCapacity}
            </Text>
          </VStack>
        )}
      </CardBody>
    </Card>
  );
};
