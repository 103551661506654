import { z } from 'zod';

export const externalFleetIds = {
  NASH: 'NASH',
} as const;

export const ExternalFleetSchema = z.object({
  externalFleetId: z.string(),
  name: z.string(),
});

export type ExternalFleet = z.infer<typeof ExternalFleetSchema>;
