import React from 'react';
import {
  Box,
  Button,
  Circle,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { HiFilter } from 'react-icons/hi';
import { OrderQueryStore } from './useOrderQueryStore';
import ReactDatePicker from 'react-datepicker';
import { ALL_ORDER_STATUSES, OrderSortBy } from '@tradeaze-packages/schemas';
import { brandSecondaryColor } from '../../theme/colors';
import { DebounceSearchInput } from '../OrderSearchInput';
import { RiderFilterMenu } from '../../rider/RiderFilterMenu';
import { DeliveryVehicleFilterMenu } from '../../delivery/delivery-vehicle/DeliveryVehicleFilterMenu';
import { OrderStatusTag } from '../OrderStatusTag';
import { sortByToLabel } from './sortByToLabel';
import { MerchantFilterMenu } from '../../merchant';

export const OrderQueryOptionsDrawer: React.FC<
  OrderQueryStore & {
    isAdmin: boolean;
    buttonProps: React.ComponentProps<typeof Button>;
  }
> = ({
  handleChangeEndDate,
  handleChangeStartDate,
  handleChangeOrderReference,
  handleChangeSortBy,
  handleStatusFilterChange,
  handleToggleRider,
  handleSelectAllRiders,
  handleToggleDeliveryVehicle,
  handleSelectAllDeliveryVehicles,
  handleToggleMerchant,
  handleSelectAllMerchants,
  handleToggleDeliveryToday,
  handleClearDates,
  getActiveFilters,
  isAdmin,
  orderStatuses,
  sortBy,
  startDate,
  deliveryVehicles,
  endDate,
  merchantIds,
  riderIds,
  deliveryToday,
  merchantOrderReference,
  buttonProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const showRiderFilter = isAdmin;
  const showMerchantFilter = isAdmin;

  const numberOfFilters = getActiveFilters();

  return (
    <Box>
      <Button {...buttonProps} onClick={onOpen}>
        <HStack>
          <HiFilter size={16} />
          <Text>Filters</Text>
        </HStack>
        {numberOfFilters > 0 && (
          <Circle
            position={'absolute'}
            left={-2}
            top={-2}
            minH={5}
            minW={5}
            bg="red.600"
            fontSize={10}
            color="white"
          >
            {numberOfFilters}
          </Circle>
        )}
      </Button>
      <Drawer placement={'right'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent bg="yellow.400">
          <DrawerHeader display={'flex'} justifyContent="space-between">
            <Text>Filter Orders</Text>
            <CloseButton onClick={onClose} />
          </DrawerHeader>
          <DrawerBody>
            <Heading size={'sm'} mb={4} color={brandSecondaryColor}>
              Order Reference
            </Heading>
            <Box mb={6}>
              <DebounceSearchInput
                defaultValue={merchantOrderReference}
                inputPlaceholder="Order Reference"
                handleSearchChange={handleChangeOrderReference}
                height={'33px'}
              />
            </Box>
            <Heading size={'sm'} mb={4} color={brandSecondaryColor}>
              Dates
            </Heading>
            <Stack
              direction="row"
              sx={{
                '.react-datepicker__input-container': {
                  input: {
                    padding: '3px 10px',
                    borderRadius: '5px',
                    width: '100%',
                  },
                },
              }}
            >
              <ReactDatePicker
                placeholderText="Start Date"
                selected={startDate ? new Date(startDate) : undefined}
                onChange={handleChangeStartDate}
                selectsStart
                startDate={startDate ? new Date(startDate) : undefined}
                endDate={endDate ? new Date(endDate) : undefined}
                dateFormat="dd/MM/yyyy"
              />
              <ReactDatePicker
                placeholderText="End Date"
                selected={endDate ? new Date(endDate) : undefined}
                onChange={handleChangeEndDate}
                selectsEnd
                startDate={startDate ? new Date(startDate) : undefined}
                endDate={endDate ? new Date(endDate) : undefined}
                minDate={startDate ? new Date(startDate) : undefined}
                dateFormat="dd/MM/yyyy"
              />
              <CloseButton onClick={handleClearDates} />
            </Stack>
            <RadioGroup my={3} onChange={handleChangeSortBy} value={sortBy}>
              <Stack direction="column">
                {Object.keys(sortByToLabel).map((sortBy) => (
                  <Radio value={sortBy}>
                    {sortByToLabel[sortBy as OrderSortBy]}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            <HStack mt={2} mb={6}>
              <Switch
                isChecked={deliveryToday}
                onChange={handleToggleDeliveryToday}
              />
              <Text>Delivery Today</Text>
            </HStack>
            {showMerchantFilter ? (
              <>
                <Heading size={'sm'} mb={4} color={brandSecondaryColor}>
                  Merchant
                </Heading>
                <Box mb={6}>
                  <MerchantFilterMenu
                    handleToggleMerchant={handleToggleMerchant}
                    handleSelectAllMerchants={handleSelectAllMerchants}
                    selectedMerchantIds={merchantIds}
                  />
                </Box>
              </>
            ) : null}
            {showRiderFilter ? (
              <>
                <Heading size={'sm'} mb={4} color={brandSecondaryColor}>
                  Rider
                </Heading>
                <Box mb={6}>
                  <RiderFilterMenu
                    handleToggleRider={handleToggleRider}
                    handleToggleSelectAllRiders={handleSelectAllRiders}
                    selectedRiderIds={riderIds}
                  />
                </Box>
              </>
            ) : null}
            <Heading size={'sm'} mb={4} color={brandSecondaryColor}>
              Delivery Vehicle
            </Heading>
            <Box mb={6}>
              <DeliveryVehicleFilterMenu
                handleSelectAllDeliveryVehicles={
                  handleSelectAllDeliveryVehicles
                }
                handleToggleDeliveryVehicle={handleToggleDeliveryVehicle}
                selectedVehicleIds={deliveryVehicles}
              />
            </Box>
            <Heading size={'sm'} mb={4} color={brandSecondaryColor}>
              Order Status
            </Heading>
            <Flex mb={6} wrap="wrap">
              {ALL_ORDER_STATUSES.map((status) => (
                <Box
                  key={status}
                  cursor={'pointer'}
                  onClick={() => handleStatusFilterChange(status)}
                  mr={2}
                  mb={2}
                  shadow="md"
                >
                  <OrderStatusTag
                    inactive={!orderStatuses?.includes(status)}
                    status={status}
                  />
                </Box>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
