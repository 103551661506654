import {
  Box,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  CreateDeliveryItem,
  DimensionUnit,
  MAX_LOAD_WEIGHT,
} from '@tradeaze-packages/schemas';
import { DimensionInput } from './DimensionInput';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { getMaxValue } from '../../utils';
import { FormAttribute } from '../../../form';
import { measurmentUnits } from '../../orderConstants';

type ItemLoadProps = {
  setIsPrimaryView?: React.Dispatch<React.SetStateAction<boolean>>;
  form: UseFormReturn<CreateDeliveryItem>;
  handleMeasurementChange: (currentUnit: DimensionUnit) => void;
  measurementUnit: DimensionUnit;
  label?: string;
};

export const ItemLoad = ({
  setIsPrimaryView,
  form,
  handleMeasurementChange,
  measurementUnit,
  label,
}: ItemLoadProps) => {
  const {
    formState: { errors },
    register,
    control,
  } = form;

  return (
    <Stack spacing={4}>
      <Box>
        <FormLabel>{label ?? 'Total Load Estimate'}</FormLabel>
        <Text color={'blackAlpha.600'}>
          Please specify the overall dimensions of the load you're collecting
          for this destination, considering its packed state inside the vehicle.
        </Text>
      </Box>
      <RadioGroup
        onChange={(value: DimensionUnit) => handleMeasurementChange(value)}
        value={measurementUnit}
      >
        <Stack direction={['column', 'column', 'row']}>
          {measurmentUnits.map((unit) => (
            <Radio key={unit.value} value={unit.value} data-cy={unit.value}>
              {unit.label} ({unit.value})
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      <Stack
        spacing={6}
        direction={['column', 'column', 'row']}
        alignItems={['center', 'center', 'flex-start']}
      >
        <DimensionInput
          id="length"
          label="Load Length"
          unit={measurementUnit}
          max={getMaxValue(measurementUnit, 'length')}
          onFocus={() => setIsPrimaryView && setIsPrimaryView(true)}
          control={control}
          inputNote="Longest Item"
          width={['100%', '100%', 'auto']}
          data-cy={'load-length'}
        />
        <DimensionInput
          id="width"
          label="Load Width"
          unit={measurementUnit}
          max={getMaxValue(measurementUnit, 'width')}
          onFocus={() => setIsPrimaryView && setIsPrimaryView(false)}
          control={control}
          inputNote="Estimate"
          width={['100%', '100%', 'auto']}
          data-cy={'load-width'}
        />
        <DimensionInput
          id="height"
          label="Load Height"
          unit={measurementUnit}
          max={getMaxValue(measurementUnit, 'height')}
          control={control}
          inputNote="Estimate"
          width={['100%', '100%', 'auto']}
          data-cy={'load-height'}
        />
      </Stack>
      <FormAttribute
        id={'weight'}
        inputType={'number'}
        unit={'kg'}
        label={'Total Weight Estimate'}
        isRequired={true}
        description={`The total weight of the load being sent (Max. ${MAX_LOAD_WEIGHT}kg)`}
        error={errors?.weight}
        data-cy={'total-weight'}
        {...register('weight', {
          setValueAs: (value) => (value === '' ? undefined : parseFloat(value)),
        })}
      />
    </Stack>
  );
};
