import { UseFormReturn } from 'react-hook-form';
import { CreateDeliveryItem } from '@tradeaze-packages/schemas';
import { FormAttribute } from '../../../form';

type ItemDescriptionProps = {
  form: UseFormReturn<CreateDeliveryItem>;
};

export const ItemDescription = ({ form }: ItemDescriptionProps) => {
  const {
    register,
    formState: { errors },
  } = form;
  return (
    <FormAttribute
      id={'deliveryItems.0.description'}
      label={'Item Description'}
      isRequired={false}
      error={errors?.description}
      inputType={'textarea'}
      description={'Brief description of the items you are sending.'}
      placeholder={'e.g. 10 bags of plaster'}
      data-cy={'item-description'}
      {...register('description')}
    />
  );
};
