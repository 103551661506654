import { Alert, AlertIcon } from '@chakra-ui/react';

type LongDistanceAlertSectionProps = {
  isEveryStopOverThreshold: boolean;
  allowLongDistance: boolean;
};

export const LongDistanceAlertSection = ({
  isEveryStopOverThreshold,
  allowLongDistance,
}: LongDistanceAlertSectionProps) => {
  if (!isEveryStopOverThreshold) {
    return null;
  }

  return allowLongDistance ? (
    <Alert
      status="warning"
      borderRadius={'lg'}
      data-cy={'longDistanceAlert-all-stops'}
    >
      <AlertIcon />
      All delivery stops are over the threshold distance from the centre of
      London. Please confirm with Tradeaze before booking.
    </Alert>
  ) : (
    <Alert
      status="error"
      borderRadius={'lg'}
      data-cy={'longDistanceAlert-some-stops'}
    >
      <AlertIcon />
      At least one delivery stop must be inside the London area. Please contact
      Tradeaze to book this.
    </Alert>
  );
};
