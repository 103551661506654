import { z } from "zod";

export const SetAssignQuotesResponseSchema = z.object({
  id: z.string(),
  externalId: z.string(),
  providerId: z.string(),
  providerName: z.string(),
  status: z.string(),
  trackingUrl: z.string(),
  price: z.number(),
  currency: z.string(),
  quoteId: z.string(),
  externalFleetId: z.string().nullish(),
  deliveryId: z.string(),
  externalUrl: z.string(),
});

export type SetAssignQuotesResponse = z.infer<
  typeof SetAssignQuotesResponseSchema
>;

