import { Flex, HStack, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { DotIcon } from '../../../../shared';

export const CardPostcodes: React.FC<{
  collectionPostcodes: string[];
  dropOffPostcode: string;
}> = ({ collectionPostcodes, dropOffPostcode }) => {
  const displayedPostcodes = collectionPostcodes.slice(0, 2);
  const remainingPostcodes = collectionPostcodes.length - 2;

  return (
    <Flex flexWrap={'wrap'} fontWeight={600}>
      <HStack>
        {displayedPostcodes.map((postcode, index) => (
          <React.Fragment key={postcode}>
            <Text fontSize={'0.9em'} noOfLines={1}>
              {postcode?.toLocaleUpperCase()}
            </Text>
            {index === 0 && displayedPostcodes.length > 1 && <DotIcon />}
          </React.Fragment>
        ))}
        {remainingPostcodes > 0 && (
          <React.Fragment>
            <DotIcon />
            <Text noOfLines={1}>+{remainingPostcodes}</Text>
          </React.Fragment>
        )}
        <Icon as={FaLongArrowAltRight} color="blackAlpha.500" />
        <Text fontSize={'0.9em'} noOfLines={1}>
          {dropOffPostcode?.toLocaleUpperCase()}
        </Text>
      </HStack>
    </Flex>
  );
};
