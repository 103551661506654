import { CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';

type PickupDetails = CreateDeliveryStop;

type Form = UseFormReturn<CreateDeliveryStop>;

export const isPickupSectionComplete = (
  pickupDetails: PickupDetails,
  pickupForm: Form,
): boolean => {
  return (
    Boolean(pickupDetails.addressLine1) &&
    Boolean(pickupDetails.postCode) &&
    Boolean(pickupDetails.collectionReference) &&
    !pickupForm.getFieldState('addressLine1').invalid &&
    !pickupForm.getFieldState('postCode').invalid &&
    !pickupForm.getFieldState('collectionReference').invalid
  );
};
