import {
  CreateOrder,
  CreateOrderMultiDrop,
  CreateOrderMultiDropSchema,
  ExistingOrder,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { PostHog } from 'posthog-js';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { isInvoicingSectionComplete, isPickupSectionComplete } from '../utils';
import { useOrderSubForms } from './useOrderSubForms';

type UseMultiDropForm = {
  merchantAccount?: MerchantAccount;
  isAdmin?: boolean;
  posthog?: PostHog;
  onSubmit: (order: CreateOrder) => void;
  setShowMainBackButton?: (value: boolean) => void;
  addPickupMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
};

export const useMultiDropForm = ({
  merchantAccount,
  isAdmin,
  posthog,
  onSubmit,
  setShowMainBackButton,
  addPickupMarker,
  removePickupMarker,
}: UseMultiDropForm) => {
  const form = useForm<CreateOrder>({
    defaultValues: {
      type: 'MULTI_DROP',
      invoicingName:
        merchantAccount?.invoicingName ?? merchantAccount?.merchantName ?? '',
      merchantId: merchantAccount?.merchantId,
    },
    resolver: zodResolver(CreateOrderMultiDropSchema),
    mode: 'all',
  });

  const { pickupForm, pickupDetails } = useOrderSubForms({
    merchantAccount,
  });

  const isInvoicingComplete = isInvoicingSectionComplete(form);
  const isPickupComplete = isPickupSectionComplete(pickupDetails, pickupForm);

  return {
    form,
    pickupForm,
    isInvoicingComplete,
    isPickupComplete,
  };
};
