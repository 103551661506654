import { Checkbox, FormControl, FormLabel, HStack } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { CreateDeliveryStop } from '@tradeaze-packages/schemas';

type PodSectionProps = {
  form: UseFormReturn<CreateDeliveryStop>;
  disabled?: boolean;
};

export const PodSection = ({ form, disabled = false }: PodSectionProps) => {
  const { register } = form;

  return (
    <HStack spacing={5} mt={6}>
      <FormControl
        isInvalid={Boolean(form.formState.errors.requiresImage)}
        display={'flex'}
        isDisabled={disabled}
      >
        <FormLabel mb={0} htmlFor={'requiresImage'}>
          Require Image
        </FormLabel>
        <Checkbox
          id={'requiresImage'}
          colorScheme="yellow"
          data-cy={'requires-image'}
          {...register('requiresImage')}
        />
      </FormControl>
      <FormControl
        isInvalid={Boolean(form.formState.errors.requiresSignature)}
        display={'flex'}
        isDisabled={disabled}
      >
        <FormLabel mb={0} htmlFor={'requiresSignature'}>
          Require Signature
        </FormLabel>
        <Checkbox
          id={'requiresSignature'}
          colorScheme="yellow"
          data-cy={'requires-signature'}
          {...register('requiresSignature')}
        />
      </FormControl>
    </HStack>
  );
};
