import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { Address, MerchantAddress } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

interface UseCreateMerchantAddressOptions
  extends UseMutationOptions<Address, unknown, MerchantAddress> {
  invalidateQueryKeys?: QueryKey[];
}

export const useCreateMerchantAddress = (
  options?: UseCreateMerchantAddressOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (merchantAddress: MerchantAddress) => {
      const apiName = 'MerchantApi';
      const path = '/createMerchantAddress/' + merchantAddress.merchantId;
      const options: {
        body: MerchantAddress;
      } = {
        body: merchantAddress,
      };
      return API.post(apiName, path, options);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.invalidateQueryKeys?.forEach((queryKey) => {
          queryClient.invalidateQueries(queryKey);
        });
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
};
