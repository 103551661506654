import { Box, SimpleGrid } from '@chakra-ui/react';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import React from 'react';
import {
  BookingInfo,
  OrderStatus,
  OrderSummaryHeading,
  RouteSummary,
  InvoicingDetails,
  TradeazeOrderFees,
  DeliveryStopsSummary,
  OrderCourier,
} from './order-summary';

export const OrderSummary: React.FunctionComponent<{
  order: HydratedOrder;
  proofOfDeliveryBucketname: string;
  columns?: number[];
  isTravisPerkins?: boolean;
  isAdmin?: boolean;
  showBackButton: boolean;
}> = ({
  order,
  proofOfDeliveryBucketname,
  columns,
  isTravisPerkins = false,
  isAdmin = false,
  showBackButton,
}) => {
  const pickups =
    order.deliveryStops?.filter((ds) => ds.type === 'PICK_UP') ?? [];

  const dropoffs =
    order.deliveryStops?.filter((ds) => ds.type === 'DROP_OFF') ?? [];

  return (
    <Box>
      <Box mb={12}>
        <OrderSummaryHeading
          type={order.type}
          pickups={pickups}
          dropoffs={dropoffs}
          merchantOrderReference={order.merchantOrderReference}
          showBackButton={showBackButton}
        />
      </Box>
      <SimpleGrid columns={columns} spacing={12}>
        <OrderStatus order={order} />
        <OrderCourier order={order} isAdmin={isAdmin} />
        {isAdmin ? (
          <InvoicingDetails
            invoicingName={order.Merchant?.invoicingName || order.invoicingName}
            merchantName={order.Merchant?.merchantName}
            isTravisPerkins={isTravisPerkins}
          />
        ) : null}

        <BookingInfo
          merchantOrderReference={order.merchantOrderReference}
          bookedBy={order.bookedBy}
          orderType={order.type}
          notes={order.notes}
          isTravisPerkins={isTravisPerkins}
        />

        <RouteSummary
          deliveryWindowStart={order.deliveryWindowStart}
          deliveryWindowEnd={order.deliveryWindowEnd}
          deliveryLength={order.deliveries.length}
          deliveryDuration={order.deliveryDuration}
          deliveryDistance={order.deliveryDistance}
          deliveryVehicle={order.deliveries[0].deliveryVehicleId}
          deliveryOption={order.deliveries[0].deliveryOptionId}
        />
        <DeliveryStopsSummary
          deliveryStops={pickups}
          proofOfDeliveryBucketname={proofOfDeliveryBucketname}
          isAdmin={isAdmin}
          order={order}
        />
        <DeliveryStopsSummary
          deliveryStops={dropoffs}
          order={order}
          isAdmin={isAdmin}
          proofOfDeliveryBucketname={proofOfDeliveryBucketname}
        />
        <TradeazeOrderFees order={order} />
      </SimpleGrid>
    </Box>
  );
};
