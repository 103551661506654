import { z } from "zod";
import { DeliveryStopTypeSchema } from "./delivery-stop-schema";
import { EtaStatusSchema } from "./eta-status-schema";

export const EtaSchema = z.object({
  etaId: z.string(),
  updatedAt: z.string(),
  deliveryId: z.string(),
  deliveryStopId: z.string(),
  stopType: DeliveryStopTypeSchema,
  etaStart: z.string().nullable(),
  etaEnd: z.string().nullable(),
  etaStatus: EtaStatusSchema,
  stopIndex: z.number().nullable(),
});

export const EtaHistorySchema = z.object({
  etaHistoryId: z.string(),
  createdAt: z.string(),
  etaId: z.string(),
  etaStart: z.string().nullable(),
  etaEnd: z.string().nullable(),
  etaStatus: EtaStatusSchema,
  stopIndex: z.number().nullable(),
});

export type EtaRecord = z.infer<typeof EtaSchema>;
export type EtaHistoryRecord = z.infer<typeof EtaHistorySchema>;
