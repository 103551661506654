import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text,
} from '@chakra-ui/react';
import { HydratedDeliveryStop, OrderType } from '@tradeaze-packages/schemas';
import React from 'react';
import { DeliveryStopSummary } from './DeliveryStopSummary';

export const DeliveryStopAccordionItem: React.FunctionComponent<{
  deliveryStop: HydratedDeliveryStop;
  type: OrderType;
  title?: string;
  titleIcon?: React.ReactNode;
  proofOfDeliveryBucketname: string;
  isAdmin: boolean;
}> = ({
  title,
  titleIcon,
  type,
  deliveryStop,
  proofOfDeliveryBucketname,
  isAdmin,
}) => {
  const isMultiCollection = type === 'MULTI_COLLECTION';
  return (
    <AccordionItem borderColor="transparent">
      <AccordionButton py={0} _hover={{ bg: 'transparent' }}>
        <Box flex="1" textAlign="left">
          {title && (
            <Heading
              bg={'white'}
              size="md"
              display={'flex'}
              alignItems={'center'}
            >
              {title}
              {titleIcon && <Box ml={2}>{titleIcon}</Box>}
            </Heading>
          )}
          <Text size="sm" mt={2}>
            {deliveryStop.postCode}
          </Text>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel py={isMultiCollection ? 5 : 10}>
        <DeliveryStopSummary
          deliveryStop={deliveryStop}
          proofOfDeliveryBucketname={proofOfDeliveryBucketname}
          isAdmin={isAdmin}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};
