import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { MdDeliveryDining, MdManageAccounts } from 'react-icons/md';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Show,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { BsCalculatorFill } from 'react-icons/bs';
import { HiHome } from 'react-icons/hi';
import { MdRecentActors } from 'react-icons/md';
import { DeliveryPricingModal, NavbarButton } from '@tradeaze/frontend/ui-web';
import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';
import { useAuthContext } from '../auth/AuthProvider';
import { TradeazeLogoNoText } from '@tradeaze/frontend/ui-web';
import { HamburgerIcon } from '@chakra-ui/icons';
import React, { MouseEventHandler } from 'react';
import { NavbarCommon } from '@tradeaze/frontend/ui-web';
import { usePostHog } from 'posthog-js/react';
import { captureEvent } from '@tradeaze/frontend/utils';

const Navbar: React.FunctionComponent = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const location = useLocation();

  const { cognitoUserId } = useAuthContext();

  const { data: merchantAccount } = useGetMerchantAccount(cognitoUserId);

  const disableFullNavbarPathnames = [
    '/signin',
    '/signup',
    '/confirm-signup',
    '/create-account',
  ];

  const hideFullNavbar =
    disableFullNavbarPathnames.includes(location.pathname) || !merchantAccount;

  const hidePriorityDelivery =
    merchantAccount?.featureFlags?.hidePriorityDelivery || false;

  const allowLongDistance =
    merchantAccount?.featureFlags?.allowLongDistance || false;

  const deliveryPricingDisclosure = useDisclosure();

  const mobileMenuDisclosure = useDisclosure();

  const openDeliveryPricingModal = () => {
    deliveryPricingDisclosure.onOpen();
    mobileMenuDisclosure.onClose();
    captureEvent(posthog)('open_pricing_modal');
  };

  const onCreateOrder = () => {
    captureEvent(posthog)('start_create_order');
  };

  return (
    <NavbarCommon hideFullNavbar={hideFullNavbar}>
      <Show above="md">
        <NavbarContentDesktop
          navigate={navigate}
          onCreateOrder={onCreateOrder}
          onOpenDeliveryPricingModal={openDeliveryPricingModal}
        />
      </Show>
      {/* ON MOBILE */}
      <Show below="md">
        <NavbarButton onClick={mobileMenuDisclosure.onOpen} label="Menu">
          <Center position={'relative'} top={1}>
            <HamburgerIcon w="100%" fontSize={'3xl'} />
          </Center>
        </NavbarButton>
        <NavbarContentMobile
          navigate={navigate}
          onCreateOrder={onCreateOrder}
          onOpenDeliveryPricingModal={openDeliveryPricingModal}
          isOpen={mobileMenuDisclosure.isOpen}
          onClose={mobileMenuDisclosure.onClose}
        />
      </Show>
      {merchantAccount && (
        <DeliveryPricingModal
          posthog={posthog}
          isOpen={deliveryPricingDisclosure.isOpen}
          onClose={deliveryPricingDisclosure.onClose}
          defaultFromPostcode={merchantAccount.postCode}
          merchantId={merchantAccount.merchantId}
          hidePriorityDelivery={hidePriorityDelivery}
          allowLongDistance={allowLongDistance}
          showMultiDrop={false}
        />
      )}
    </NavbarCommon>
  );
};

const NavbarContentDesktop: React.FunctionComponent<{
  navigate: NavigateFunction;
  onCreateOrder: () => void;
  onOpenDeliveryPricingModal: MouseEventHandler<HTMLButtonElement> | undefined;
}> = ({ navigate, onOpenDeliveryPricingModal, onCreateOrder }) => (
  <HStack>
    {/* HOME */}
    <NavbarButton label="Home" onClick={() => navigate('/')}>
      <HiHome fontSize={34} />
    </NavbarButton>
    {/* ADDRESS BOOK */}
    <NavbarButton
      label="Address Book"
      onClick={() => navigate('/address-book')}
    >
      <MdRecentActors fontSize={38} />
    </NavbarButton>
    {/* ACCCOUNT */}
    <NavbarButton label="Account" onClick={() => navigate('/account')}>
      <MdManageAccounts fontSize={38} />
    </NavbarButton>
    {/* PRICE DELIVERY */}
    <NavbarButton onClick={onOpenDeliveryPricingModal} label="Delivery Price">
      <HStack spacing={0} mb={-2}>
        <MdDeliveryDining fontSize={40} />
        <BsCalculatorFill fontSize={24} />
      </HStack>
    </NavbarButton>
    {/* REQUEST A RIDER */}
    <NavbarButton
      label="Create Order"
      onClick={() => {
        onCreateOrder();
        navigate('/create-order');
      }}
    >
      <Box position={'relative'} top={1}>
        <TradeazeLogoNoText height={30} />
      </Box>
    </NavbarButton>
  </HStack>
);

const NavbarContentMobile: React.FunctionComponent<{
  onOpenDeliveryPricingModal: () => void;
  onCreateOrder: () => void;
  navigate: NavigateFunction;
  onClose: () => void;
  isOpen: boolean;
}> = ({
  onOpenDeliveryPricingModal,
  onCreateOrder,
  navigate,
  onClose,
  isOpen,
}) => {
  return (
    <Drawer placement={'right'} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg="yellow.400">
        <DrawerHeader>Menu</DrawerHeader>
        <DrawerBody h="100%" justifyContent={'center'} flexDirection="column">
          <Stack spacing={8}>
            <Button
              fontSize={'xl'}
              onClick={() => {
                onClose();
                navigate('/');
              }}
            >
              Home
            </Button>
            <Button
              fontSize={'xl'}
              onClick={() => {
                onClose();
                navigate('/address-book');
              }}
            >
              Address Book
            </Button>
            <Button
              fontSize={'xl'}
              onClick={() => {
                onClose();
                navigate('/account');
              }}
            >
              Account
            </Button>
            <Button fontSize={'xl'} onClick={onOpenDeliveryPricingModal}>
              Delivery Price
            </Button>
            <Button
              fontSize={'xl'}
              onClick={() => {
                onClose();
                onCreateOrder();
                navigate('/create-order');
              }}
            >
              Create Order
            </Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Navbar;
