import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DropDownItem } from './DropDownItem';
import { useClickOutside } from '@tradeaze/frontend/hooks';

export const SearchDropdown: React.FC<{
  value: string;
  inputPlaceholder: string;
  onChange: (value: string) => void;
  results?: { label: string; value: any }[];
  onSelect: (value: any) => void;
  isLoading: boolean;
  onTriggerSearch?: () => void;
  disableSearch?: boolean;
  isError?: boolean;
}> = ({
  value,
  isLoading,
  onChange,
  results,
  onSelect,
  inputPlaceholder,
  onTriggerSearch,
  disableSearch,
  isError,
  ...props
}) => {
  const [isFocussed, setIsFocussed] = useState(false);
  const [filter, setFilter] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const filteredResults = useMemo(() => {
    if (!filter) {
      return results;
    }
    return results?.filter((result) =>
      result.label.toLowerCase().includes(filter.toLowerCase()),
    );
  }, [results, filter]);

  const removeFocus = () => {
    setIsFocussed(false);
  };

  const handleFocus = () => {
    setIsFocussed(true);
  };

  const handleSelect = (value: unknown) => {
    onSelect(value);
    removeFocus();
  };

  const ref = useClickOutside<HTMLDivElement>(() => {
    const selection = window.getSelection()?.toString();
    if (selection) {
      // If text is selected, don't remove focus
      return;
    }
    removeFocus();
  });

  useEffect(() => {
    if (!isFocussed) {
      setFilter('');
    }
  }, [isFocussed]);

  return (
    <Box ref={ref} position={'relative'}>
      <InputGroup onFocus={handleFocus}>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          ref={inputRef}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          placeholder={inputPlaceholder}
          borderColor={isError ? 'red.500' : undefined} // Add this line
          {...props}
        />
        {onTriggerSearch ? (
          <InputRightElement
            px={7}
            children={
              <Button
                size={'sm'}
                px={6}
                onClick={onTriggerSearch}
                isLoading={isLoading}
                disabled={disableSearch}
              >
                <SearchIcon />
              </Button>
            }
          />
        ) : (
          <InputRightElement
            children={
              isLoading ? <Spinner size={'sm'} color={'gray.400'} /> : null
            }
          />
        )}
      </InputGroup>
      {!!(results?.length && isFocussed) && (
        <Box
          ref={dropdownRef}
          position={'absolute'}
          bg="white"
          border="1px"
          marginTop={1}
          borderColor={'rgba(0,0,0,0.1)'}
          width={inputRef.current?.clientWidth}
          borderRadius="md"
          maxH={250}
          zIndex={10}
          boxShadow="md"
          display={'flex'}
          flexDirection={'column'}
        >
          <Input
            placeholder="Filter Results"
            position={'sticky'}
            py={5}
            onChange={handleFilterChange}
          />
          <Box overflowY="scroll">
            {filteredResults && filteredResults?.length > 0 ? (
              filteredResults.map((option, index) => (
                <DropDownItem
                  key={option.value}
                  label={option.label}
                  value={option.value}
                  onSelect={handleSelect}
                  isLast={index === filteredResults.length - 1}
                />
              ))
            ) : (
              <Box padding={3}>
                <Text>No Results</Text>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
