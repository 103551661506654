import { z } from 'zod';
import { GigJobSchema, CreateGigJobParamSchema, CreateGigJobSchema } from '../../../models';

export const CreateGigJobBodySchema = CreateGigJobParamSchema;
export const CreateGigJobResponseSchema = GigJobSchema.extend({
  deliveryIds: z.array(z.string()),
});

export type MakeGigJob = z.infer<typeof CreateGigJobSchema>;
export type CreateGigJobBody = z.infer<typeof CreateGigJobBodySchema>;

export type CreateGigJobResponse = z.infer<typeof CreateGigJobResponseSchema>;
