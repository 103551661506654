import { DeliveryOptionId } from '@tradeaze-packages/schemas';
import { Divider, Stack } from '@chakra-ui/react';
import { DeliveryWindow } from './DeliveryWindow';
import { DeliveryFees } from './DeliveryFee';
import { DeliveryServiceCharge } from './DeliveryServiceCharge';
import { DeliveryTotal } from './DeliveryTotal';

type DeliveryWindowAndPriceProps = {
  deliveryWindowStart: string;
  deliveryWindowEnd: string;
  deliveryOptionsQueryLoading: boolean;
  isDeliveryPriceLoading: boolean;
  showDeliveryPrice: false | DeliveryOptionId;
  isAdmin: boolean;
  deliveryPrice: number;
  serviceCharge: number;
  totalTradeazeCharges: number;
  deliveryPriceErrorMessage: string | null;
};

export const DeliveryWindowAndPrice = ({
  deliveryWindowStart,
  deliveryWindowEnd,
  deliveryOptionsQueryLoading,
  isDeliveryPriceLoading,
  showDeliveryPrice,
  isAdmin,
  deliveryPrice,
  serviceCharge,
  totalTradeazeCharges,
  deliveryPriceErrorMessage,
}: DeliveryWindowAndPriceProps) => {
  return (
    <>
      <Divider opacity={0.3} />
      <DeliveryWindow
        deliveryWindowStart={deliveryWindowStart}
        deliveryWindowEnd={deliveryWindowEnd}
        deliveryOptionsQueryLoading={deliveryOptionsQueryLoading}
      />
      <Divider opacity={0.3} />
      <Stack spacing={6}>
        <DeliveryFees
          isDeliveryPriceLoading={isDeliveryPriceLoading}
          showDeliveryPrice={showDeliveryPrice}
          isAdmin={isAdmin}
          deliveryPrice={deliveryPrice}
        />
        <DeliveryServiceCharge
          isDeliveryPriceLoading={isDeliveryPriceLoading}
          showDeliveryPrice={showDeliveryPrice}
          isAdmin={isAdmin}
          serviceCharge={serviceCharge}
        />
      </Stack>
      <Divider opacity={0.3} />
      <DeliveryTotal
        isDeliveryPriceLoading={isDeliveryPriceLoading}
        showDeliveryPrice={showDeliveryPrice}
        totalTradeazeCharges={totalTradeazeCharges}
        deliveryPriceErrorMessage={deliveryPriceErrorMessage}
      />
    </>
  );
};
