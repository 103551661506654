import { Address, CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';

export const updateAddressFields = (
  setValue: UseFormReturn<CreateDeliveryStop>['setValue'],
  address: Address,
) => {
  const fields = {
    addressId: address.addressId,
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    addressLine3: address.addressLine3,
    postCode: address.postCode,
    position: address.position,
  };

  Object.entries(fields).forEach(([key, value]) =>
    setValue(key as keyof CreateDeliveryStop, value),
  );
};
