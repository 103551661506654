import {
  GetAdminOrdersQuery,
  GetAdminOrdersResponse,
  CancelOrderResponse,
  DownloadAdminOrdersCsvResponse,
  DownloadMerchantOrdersCsvResponse,
  GetOrderByIdResponse,
  GetOrderByIdQuery,
  DownloadMerchantOrdersCsvQuery,
  DownloadAdminOrdersCsvQuery,
  UpdateOrderBody,
} from '@tradeaze-packages/schemas';
import * as client from './client';
import { handleResponse } from './handle-response';

export const getOrderById = async (
  orderId: string,
  query: GetOrderByIdQuery,
): Promise<GetOrderByIdResponse> =>
  handleResponse(
    client.getOrderById({
      path: { orderId },
      query,
    }),
  );

export const getAdminOrdersAmplify = async (
  query: GetAdminOrdersQuery,
): Promise<GetAdminOrdersResponse> =>
  handleResponse(client.getAdminOrders({ query }));

export const cancelOrderAmplify = async ({
  orderId,
  cancellationReason,
}: {
  orderId: string;
  cancellationReason: string;
}): Promise<CancelOrderResponse> =>
  handleResponse(
    client.cancelOrder({
      path: { orderId },
      body: { cancellationReason },
    }),
  );

export const fetchAdminOrdersCsv = async (
  filters: DownloadAdminOrdersCsvQuery,
): Promise<DownloadAdminOrdersCsvResponse> =>
  handleResponse(client.downloadOrdersCsv({ query: filters }));

export const fetchMerchantOrdersCsv = async (
  merchantId: string,
  filters: DownloadMerchantOrdersCsvQuery,
): Promise<DownloadMerchantOrdersCsvResponse> =>
  handleResponse(
    client.downloadMerchantOrdersCsv({
      path: { merchantId },
      query: filters,
    }),
  );

export const adminUpdateOrder = async ({
  orderId,
  order,
}: {
  orderId: string;
  order: UpdateOrderBody;
}) =>
  handleResponse(
    client.adminUpdateOrder({
      path: { orderId },
      // Need to type cast because the generated types think position cannot be null
      body: order as client.AdminUpdateOrderData['body'],
    }),
  );

export const merchantUpdateOrder = async ({
  orderId,
  order,
}: {
  orderId: string;
  order: UpdateOrderBody;
}) =>
  handleResponse(
    client.merchantUpdateOrder({
      path: { orderId },
      // Need to type cast because the generated types think position cannot be null
      body: order as client.MerchantUpdateOrderData['body'],
    }),
  );
