import * as z from 'zod';

export const NashWebhookEventSchema = z.enum([
  'assigned_driver',
  'pickup_arrived',
  'pickup_complete',
  'dropoff_arrived',
  'dropoff_complete',
]);

export const NashWebhookBodySchema = z.object({
  type: z.string(),
  event: NashWebhookEventSchema,
  data: z.object({
    id: z.string(),
    createdAt: z.string(),
    portalUrl: z.string(),
    externalIdentifier: z.string().nullable(),
    isActive: z.boolean(),
    jobConfigurations: z.array(
      z.object({
        package: z.object({
          pickupEndTime: z.string().nullable(),
          pickupStartTime: z.string(),
          dropoffStartTime: z.string().nullable(),
          dropoffEndTime: z.string().nullable(),
        }),
        tasks: z.array(
          z.object({
            id: z.string(),
            status: z.string(),
            delivery: z.object({
              currency: z.string(),
              courierName: z.string(),
              courierPhoneNumber: z.string(),
              courierLocation: z.object({
                lat: z.number(),
                lng: z.number(),
              }),
            }),
          }),
        ),
      }),
    ),
  }),
});

export type NashWebhookEvent = z.infer<typeof NashWebhookEventSchema>;

export type NashWebhookBody = z.infer<typeof NashWebhookBodySchema>;