export const buildTrackingLink = (
  deliveryId: string,
  env: string,
  isLocalHost = false,
) => {
  const baseUrl =
    env === 'prod'
      ? `https://tracking.tradeaze.app`
      : isLocalHost
      ? `http://localhost:4202`
      : `https://${env}-tracking.tradeaze.app`;

  return `${baseUrl}/${deliveryId}`;
};
