import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { BiCopy } from 'react-icons/bi';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import { captureEvent } from '@tradeaze/frontend/utils';
import { OrderPageButton } from './OrderPageButton';
import { buildDuplicateOrder } from '../utils/buildDuplicateOrder/buildDuplicateOrder';

const useDuplicateOrder = ({ order }: { order: HydratedOrder }) => {
  const posthog = usePostHog();
  const navigate = useNavigate();

  const allowDuplicate = order.type === 'A_TO_B';

  const handleDuplicateOrder = () => {
    if (!order || !allowDuplicate) return;

    const duplicateOrder = buildDuplicateOrder(order);

    navigate('/create-order', {
      state: {
        order: duplicateOrder,
      },
    });

    captureEvent(posthog)('duplicate_order');
  };

  return {
    allowDuplicate,
    handleDuplicateOrder,
  };
};

export const DuplicateOrder: React.FC<{
  order: HydratedOrder;
}> = ({ order }) => {
  const { handleDuplicateOrder, allowDuplicate } = useDuplicateOrder({ order });

  if (!allowDuplicate) return null;

  return (
    <OrderPageButton
      icon={<BiCopy fontSize={24} />}
      label="Duplicate"
      onClick={handleDuplicateOrder}
      data-cy="duplicate-order-button"
    />
  );
};