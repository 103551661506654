import {
  DuplicateDropoffStop,
  DuplicateOrder,
  DuplicatePickupStop,
  HydratedOrder,
} from '@tradeaze-packages/schemas';

// only built to work for A to B for now
export const buildDuplicateOrder = (order: HydratedOrder): DuplicateOrder => {
  const firstDelivery = order.deliveries[0];

  const firstStop = firstDelivery.pickup;
  const lastStop = firstDelivery.dropOff;

  const newPickup = {
    type: 'PICK_UP',
    addressLine1: firstStop.addressLine1,
    postCode: firstStop.postCode,
    position: firstStop.position,
    stopSequence: 0,
    contacts: firstStop.contacts || [],
    windowStart: firstStop.windowStart,
    windowEnd: firstStop.windowEnd,
    companyName: firstStop.companyName,
    collectionReference: '',
    addressId: firstStop.addressId,
    requiresImage: firstStop.requiresImage,
    requiresSignature: firstStop.requiresSignature,
    deliveryItems: firstDelivery.deliveryItems.map((item) => ({
      ...item,
      heavySideItems: firstDelivery.heavySideItems ?? false,
    })),
  } satisfies DuplicatePickupStop;

  const newDropOff = {
    type: 'DROP_OFF',
    addressLine1: lastStop.addressLine1,
    postCode: lastStop.postCode,
    position: lastStop.position,
    stopSequence: 1,
    windowStart: lastStop.windowStart,
    windowEnd: lastStop.windowEnd,
    companyName: lastStop.companyName,
    addressId: lastStop.addressId,
    companyId: lastStop.companyId,
    requiresImage: lastStop.requiresImage,
    requiresSignature: lastStop.requiresSignature,
    contacts: lastStop.contacts?.length
      ? lastStop.contacts
      : [
          {
            contactName: undefined,
            contactNumber: undefined,
            isPrimary: true,
            trackingEnabled: true,
          },
        ],
  } satisfies DuplicateDropoffStop;

  return {
    merchantId: order.merchantId,
    invoicingName: order.invoicingName,
    deliveryOption: firstDelivery.deliveryOptionId,
    deliveryVehicle: firstDelivery.deliveryVehicleId,
    pickupDetails: [newPickup],
    dropoffDetails: [newDropOff],
    type: 'A_TO_B',
  };
};
