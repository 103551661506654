import { z } from 'zod';
import { RiderAccountSchema } from '../../../models';

export const UndoDeletedRiderResponseSchema = z.object({
  message: z.string(),
  rider: RiderAccountSchema,
});

export type UndoDeletedRiderResponse = z.infer<
  typeof UndoDeletedRiderResponseSchema
>;
