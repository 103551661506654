import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

type MainBackButtonProps = {
  cypressTestId: string;
  handleMainBackButton: () => void;
};

export const MainBackButton = ({
  cypressTestId,
  handleMainBackButton,
}: MainBackButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const onConfirm = () => {
    onClose();
    handleMainBackButton();
  };
  return (
    <>
      <Button
        mr="5px"
        variant={'ghost'}
        colorScheme={'gray'}
        onClick={() => onOpen()}
        data-cy={cypressTestId}
      >
        <ChevronLeftIcon fontSize={32} />
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        autoFocus={false}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            Are you sure you want to leave this page?
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Leaving now will erase all the information you&apos;ve entered so
            far.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} data-cy="cancel-leave">
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              colorScheme="red"
              ml={3}
              data-cy="yes-leave"
            >
              Yes, leave
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
