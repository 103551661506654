import { Divider, Stack } from '@chakra-ui/react';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import React from 'react';
import { FiCreditCard } from 'react-icons/fi';
import { PriceAttribute, PriceValue } from '../../shared';
import { OrderSection } from '../order-section';

export const TradeazeOrderFees: React.FunctionComponent<{
  order: HydratedOrder;
}> = ({ order }) => {
  return (
    <OrderSection name="Tradeaze Fees" icon={<FiCreditCard />}>
      {order.orderStatus === 'CANCELLED' ? (
        <PriceAttribute name="Cancellation Fee" excludingVat>
          <PriceValue price={order.cancellationFee} />
        </PriceAttribute>
      ) : (
        <Stack spacing={6}>
          <Stack spacing={6}>
            n
            <PriceAttribute name="Delivery Fee" excludingVat>
              <PriceValue price={order.deliveryPrice} />
            </PriceAttribute>
            <PriceAttribute name="Service Fee" excludingVat>
              <PriceValue price={order.merchantServiceCharge} />
            </PriceAttribute>
          </Stack>
          <Divider />
          <PriceAttribute name="Total" excludingVat>
            <PriceValue
              price={order.deliveryPrice + order.merchantServiceCharge}
            />
          </PriceAttribute>
        </Stack>
      )}
    </OrderSection>
  );
};
