import { Box, HStack, Text } from '@chakra-ui/react';
import { DeliveryStatus, OrderType } from '@tradeaze-packages/schemas';

export const CardStopsTag: React.FC<{
  status: DeliveryStatus;
  deliveryCount: number;
  orderType: OrderType;
}> = ({ status, deliveryCount, orderType }) => {
  const backgroundColor = {
    PENDING: 'gray.50',
    CONFIRMED: 'yellow.50',
    DELIVERED: 'green.50',
    CANCELLED: 'red.50',
    REJECTED: 'red.50',
  }[status];

  const borderColor = {
    PENDING: 'gray.100',
    CONFIRMED: 'yellow.100',
    DELIVERED: 'green.100',
    CANCELLED: 'red.100',
    REJECTED: 'red.100',
  }[status];

  const stopText =
    orderType === 'MULTI_COLLECTION'
      ? 'Collections'
      : orderType === 'MULTI_DROP'
      ? 'Drops'
      : 'Stops';

  return (
    <Box
      width={'fit-content'}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      paddingY={'1px'}
      paddingX={'2px'}
      borderRadius={'5px'}
      borderWidth={'1px'}
    >
      <Text lineHeight={'1.2em'} fontSize={'0.8em'}>
        <HStack>
          <Text fontWeight={'bold'}>{stopText}:</Text>
          <Text>{deliveryCount}</Text>
        </HStack>
      </Text>
    </Box>
  );
};
