import * as Sentry from '@sentry/browser';
import toast from 'react-hot-toast';
import { ZodError } from 'zod';

export const handleError = (
  error: Error | unknown,
  options:
    | {
        sendToSentry?: boolean;
        consoleLog?: boolean;
        sentryContext?: Record<string, unknown>;
      } & (
        | {
            showNotification?: true;
            notificationMessage: string;
          }
        | {
            showNotification?: false;
            notificationMessage?: never;
          }
      )
) => {
  const { sendToSentry, sentryContext, consoleLog, showNotification, notificationMessage } =
    options || {};
  if (sendToSentry) {
    Sentry.captureException(error, {
      extra: sentryContext,
    });
  }
  if (consoleLog) {
    if (error instanceof ZodError) {
      console.error(error.flatten());
    } else {
      console.error(error);
    }
  }
  if (showNotification) {
    // show notification
    toast.error(notificationMessage || 'Something went wrong');
  }
};
