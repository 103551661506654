import { Company, CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';

export const handleSelectMerchantCustomer =
  (
    setValue: UseFormReturn<CreateDeliveryStop>['setValue'],
    clearErrors: UseFormReturn<CreateDeliveryStop>['clearErrors'],
  ) =>
  (customer: Company | null) => {
    setValue('addressLine1', '');
    setValue('postCode', '');
    setValue('companyId', undefined);
    if (!customer) {
      setValue('companyName', '');
      setValue('companyId', undefined);
      return;
    }

    setValue('companyName', customer.companyName);
    setValue('companyId', customer.companyId);
    clearErrors('companyName');
  };
