import { HydratedDeliveryStop } from "@tradeaze-packages/schemas";

export const sectionColumns = [2, 3, 2, 2, 3];
export const shouldShowCancelDelivery = (
  deliveryStop: Pick<HydratedDeliveryStop, 'delivery' | 'completedAt'>
) => {
  // if delivery is not delivered and pickup has not been completed yet
  return (
    (deliveryStop.delivery?.status === 'PENDING' || deliveryStop.delivery?.status === 'CONFIRMED') &&
    !deliveryStop?.completedAt
  );
};
