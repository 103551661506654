import React from 'react';
import {
  Button,
  Center,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { DeliveriesQueryParams } from '@tradeaze-packages/schemas';
import { useDownloadMerchantOrders } from './useDownloadMerchantOrders';
import { useDownloadMerchantDeliveries } from './useDownloadMerchantDeliveries';


type DownloadType = 'ORDERS' | 'DELIVERIES';

type DownloadMerchantCsvProps = {
  merchantId: string;
  downloadQueryParams: DeliveriesQueryParams;
} & React.ComponentProps<typeof Button>;

export const DownloadMerchantCsv: React.FC<DownloadMerchantCsvProps> =
  ({ merchantId, downloadQueryParams, ...props }) => {
    const [downloadType, setDownloadType] =
      React.useState<DownloadType>('ORDERS');

    const downloadOrdersQuery = useDownloadMerchantOrders(merchantId);
    const downloadDeliveriesQuery = useDownloadMerchantDeliveries(merchantId);

    const handleDownload = () => {
      if (downloadType === 'ORDERS') {
        downloadOrdersQuery.mutate({
          ...downloadQueryParams,
          orderStatuses: downloadQueryParams.deliveryStatuses,
        });
      } else {
        downloadDeliveriesQuery.mutate(downloadQueryParams);
      }
    };

    const isLoading = downloadOrdersQuery.isLoading || downloadDeliveriesQuery.isLoading;

    return (
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} aria-label="Download Options" {...props}>
          <HStack>
            <DownloadIcon />
            <Text>Download</Text>
          </HStack>
        </MenuButton>
        <MenuList minWidth="200px">
          <MenuOptionGroup
            title="Download Type"
            type="radio"
            value={downloadType}
            onChange={(value) => setDownloadType(value as DownloadType)}
          >
            <MenuItemOption value="ORDERS">Orders</MenuItemOption>
            <MenuItemOption value="DELIVERIES">Deliveries</MenuItemOption>
          </MenuOptionGroup>

          <MenuDivider />
          <Center>
            <Button width="90%" onClick={handleDownload} size="sm" isLoading={isLoading}>
              Download CSV
            </Button>
          </Center>
        </MenuList>
      </Menu>
    );
  };
