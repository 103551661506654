import { Box, Text } from '@chakra-ui/react';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import React from 'react';

type CardStatusCountProps = {
  order: HydratedOrder;
};

export const CardStatusCount = ({ order }: CardStatusCountProps) => {
  const statusCounts = [
    { status: 'PENDING', label: 'pending' },
    { status: 'CONFIRMED', label: 'confirmed' },
    { status: 'REJECTED', label: 'rejected' },
    { status: 'CANCELLED', label: 'cancelled' },
    { status: 'DELIVERED', label: 'completed' },
  ]
    .map(({ status, label }) => ({
      count: order.deliveries.filter((delivery) => delivery.status === status)
        .length,
      label,
    }))
    .filter(({ count }) => count > 0);

  const statusText = statusCounts.map(({ count, label }, index) => (
    <React.Fragment key={label}>
      <Text as="span" fontWeight="bold">
        {count}
      </Text>
      {` ${label}`}
      {index < statusCounts.length - 1 && ', '}
    </React.Fragment>
  ));

  return (
    <Box>
      <Text fontSize={'0.9em'}>{statusText}</Text>
    </Box>
  );
};
