import { Address, CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { updateAddressFields } from '../updateAddressFields';

export const handleSelectExistingAddress = (
  setValue: UseFormReturn<CreateDeliveryStop>['setValue'],
  trigger: UseFormReturn<CreateDeliveryStop>['trigger'],
  address: Address,
  setInstructionsVisibility: (visibility: boolean) => void,
  setShowFullAddress: (show: boolean) => void,
  setIsManualAddress: (manual: boolean) => void,
) => {
  if (!address) {
    setValue('deliveryNotes', '');
    setValue('position', undefined);
    setValue('addressId', '');
    setValue('addressLine1', '');
    setValue('postCode', '');
  } else {
    updateAddressFields(setValue, address);
    trigger(['addressLine1', 'addressLine2', 'addressLine3', 'postCode']);
    setInstructionsVisibility(!!address.instructions);
  }
  setShowFullAddress(!!address);
  setIsManualAddress(false);
};
