import React from 'react';
import { BiReceipt } from 'react-icons/bi';
import { OrderSection } from '../order-section';
import { Attribute, TextValue } from '../../shared';
import { SimpleGrid } from '@chakra-ui/react';
import { sectionColumns } from './common';

export const InvoicingDetails: React.FunctionComponent<{
  invoicingName?: string | null;
  merchantName?: string | null;
  isTravisPerkins?: boolean;
}> = ({ invoicingName, merchantName, isTravisPerkins }) => {
  return (
    <OrderSection name="Invoicing Details" icon={<BiReceipt />}>
      <SimpleGrid columns={sectionColumns} spacing={4}>
        {invoicingName ? (
          <Attribute name={'Invoicing Name'}>
            <TextValue text={invoicingName} />
          </Attribute>
        ) : null}
        {merchantName ? (
          <Attribute name={'Merchant Name'}>
            <TextValue text={merchantName} />
          </Attribute>
        ) : null}
      </SimpleGrid>
    </OrderSection>
  );
};
