import { HydratedDelivery, HydratedOrder } from '@tradeaze-packages/schemas';
import { createContext, useContext } from 'react';

type OrderDashboardContextType = {
  handleStartAssign: (delivery: HydratedDelivery) => void;
  handlePreviewOrder: (order: HydratedOrder) => void;
};

export const OrderDashboardContext = createContext<OrderDashboardContextType>({
  handleStartAssign: () => {
    throw new Error('OrderDashboardContext not implemented');
  },
  handlePreviewOrder: () => {
    throw new Error('OrderDashboardContext not implemented');
  },
});

export const useOrderDashboardContext = () => {
  const context = useContext(OrderDashboardContext);
  if (!context) {
    throw new Error(
      'OrderDashboardContext must be used within a OrderDashboardContextProvider',
    );
  }
  return context;
};
