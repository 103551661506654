import { Box, Divider, Text, VStack, HStack } from '@chakra-ui/react';
import {
  CardDeliveryOption,
  CardDeliveryWindow,
  CardDriverInfo,
  CardItemsInfo,
  CardPostcodes,
  CardStatusCount,
  CardStopsTag,
  CardTimeRemaining,
  GridCardContainer,
  GridCardContent,
  GridCardOrderReference,
  GridCardTab,
} from '../delivery';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import { useOrderCardData } from './hooks/useOrderCardData';

type GridOrderCardProps = {
  order: HydratedOrder;
  onHoverOrder?: (orderId: string) => void;
  onHoverLeaveOrder?: () => void;
  isAdmin: boolean;
  onSelectOrder?: (order?: HydratedOrder) => void;
};

export const GridOrderCard = ({
  order,
  onHoverOrder,
  onHoverLeaveOrder,
  isAdmin,
  onSelectOrder,
}: GridOrderCardProps) => {
  const {
    orderDetails,
    deliveryDetails,
    status,
    drivers,
    backgroundColor,
    handleCardClick,
    showControls
  } = useOrderCardData(order, isAdmin, onSelectOrder);

  return (
    <Box
      cursor="pointer"
      onClick={handleCardClick}
      onMouseEnter={() => onHoverOrder?.(orderDetails.orderId)}
      onMouseLeave={() => onHoverLeaveOrder?.()}
      position="relative"
    >
      <Box
        position="absolute"
        top="0"
        right="10px"
        width="100%"
        height="96%"
        borderColor={'border'}
        borderWidth={1}
        borderRadius={'14px'}
        overflow="hidden"
      >
        <Box width="100%" height="40px" bg={backgroundColor} />
        <Box width="100%" height="calc(100% - 40px)" />
      </Box>
      <Box position="relative" top="10px">
        <GridCardContainer>
          <GridCardTab
            createdAt={orderDetails.createdAt}
            deliveryStatus={orderDetails.orderStatus}
            vehicleId={deliveryDetails.deliveryVehicleId}
          />
          <GridCardContent>
            <CardDeliveryOption deliveryOptionId={deliveryDetails.deliveryOptionId} />
            <CardPostcodes
              collectionPostcodes={deliveryDetails.pickupPostCodes}
              dropOffPostcode={deliveryDetails.dropOffPostCode}
            />
            {deliveryDetails.companyName && !isAdmin ? (
              <Text fontSize={'0.9em'} color={'blackAlpha.700'}>
                For {deliveryDetails.companyName}
              </Text>
            ) : null}
            <Divider my={2} />
            {showControls.showItemSizing && deliveryDetails.deliveryItems?.length ? (
              <>
                <CardItemsInfo deliveryItems={deliveryDetails.deliveryItems} gap={4} />
                <Divider my={2} />
              </>
            ) : null}
            <VStack spacing={2} alignItems={'start'}>
              <CardDeliveryWindow
                deliveryWindowStart={orderDetails.deliveryWindowStart}
                deliveryWindowEnd={orderDetails.deliveryWindowEnd}
              />
            </VStack>
            <HStack py={2}>
              <CardStopsTag
                status={orderDetails.orderStatus}
                deliveryCount={deliveryDetails.deliveryCount}
                orderType={orderDetails.type}
              />
              <CardTimeRemaining
                deliveryWindowEnd={orderDetails.deliveryWindowEnd}
                showLate={isAdmin}
                deliveryStatus={orderDetails.orderStatus}
                updatedAt={orderDetails.updatedAt}
                deliveredAt={orderDetails.deliveredAt}
                isToday={status.isToday}
                cancelledAt={orderDetails.cancelledAt}
              />
            </HStack>
            <CardStatusCount order={order} />
            <Divider my={3} />
            <HStack>
              <CardDriverInfo
                drivers={drivers}
                showVehicle={false}
                showWorkType={isAdmin}
                isGigJob={status.isGigJob}
                isGigJobBoard={status.isGigJobBoard}
                hasShiftRider={status.hasShiftRider}
              />
            </HStack>
            <Divider my={3} />
            <GridCardOrderReference
              orderReference={orderDetails.merchantOrderReference || ''}
              invoicingName={orderDetails.invoicingName || ''}
              merchantName={orderDetails.merchantName || ''}
              orderType={orderDetails.type}
              hasNotes={status.hasNotes}
              showMerchantName={showControls.showMerchantName}
              isHeavyside={status.isHeavyside}
              notesPreview={status.notesPreview}
              showNotesPreview={showControls.showNotesPreview}
            />
          </GridCardContent>
        </GridCardContainer>
      </Box>
    </Box>
  );
};
