import { Link } from '@chakra-ui/react';
import React from 'react';
import { buildTrackingLink } from '@tradeaze/shared/utils';
import { ENV_NAME } from '@tradeaze/frontend/utils';

const isLocalHost = window.location.hostname === 'localhost';

export const TrackingLink: React.FunctionComponent<{
  deliveryId: string;
  isAdmin?: boolean;
}> = ({ deliveryId }) => {
  return ENV_NAME ? (
    <Link
      color={'blackAlpha.700'}
      fontSize={'sm'}
      href={buildTrackingLink(deliveryId || '', ENV_NAME, isLocalHost)}
      noOfLines={1}
    >
      {buildTrackingLink(deliveryId || '', ENV_NAME, isLocalHost)}
    </Link>
  ) : null;
};
