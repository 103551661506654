import { z } from 'zod';
import {
  UpdateOrderSchema,
  CommonUpdateOrderSchema,
} from '../../../models';

export const UpdateOrderBodySchema = UpdateOrderSchema;

export const UpdateOrderResponseSchema = z.object({
  message: z.string(),
  order: CommonUpdateOrderSchema,
});

export type UpdateOrderBody = z.infer<typeof UpdateOrderBodySchema>;

export type UpdateOrderResponse = z.infer<typeof UpdateOrderResponseSchema>;
