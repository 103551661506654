import { extractFormValidationErrorMessages } from '../utils';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import {
  CreateDeliveryItem,
  CreateDeliveryStop,
  CreateOrder,
  CreateOrderMultiCollection,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { handleError } from '@tradeaze/frontend/utils';

type UseMultiCollectionFormSubmission = {
  pickupForm: UseFormReturn<CreateDeliveryStop>;
  dropOffForm: UseFormReturn<CreateDeliveryStop>;
  itemForm: UseFormReturn<CreateDeliveryItem>;
  form: UseFormReturn<CreateOrder>;
  resetForms: () => void;
  selectedPickupIndex: number | null;
  setSelectedPickupIndex: (value: number | null) => void;
  setShowPickupSection: (value: boolean) => void;
  multiCollectionDetails: CreateOrderMultiCollection;
  onSubmit: (order: CreateOrder) => void;
  setShowMainBackButton?: (value: boolean) => void;
};

export const useMultiCollectionFormSubmission = ({
  pickupForm,
  dropOffForm,
  itemForm,
  form,
  resetForms,
  selectedPickupIndex,
  setSelectedPickupIndex,
  setShowPickupSection,
  multiCollectionDetails,
  onSubmit,
  setShowMainBackButton,
}: UseMultiCollectionFormSubmission) => {
  const { deliveryWindowStart, deliveryWindowEnd, pickups } =
    multiCollectionDetails;
  const currentPickups = form.getValues('pickups') || [];

  const validatePickupForm = useCallback(async () => {
    const validationErrors = [];
    const isPickupValid = await pickupForm.trigger();
    if (!isPickupValid) {
      validationErrors.push(pickupForm.formState.errors);
    }
    const isItemValid = await itemForm.trigger();
    if (!isItemValid) {
      validationErrors.push(itemForm.formState.errors);
    }

    const errorMessages = extractFormValidationErrorMessages(validationErrors);

    if (errorMessages.length > 0) {
      toast.error(
        `Please ensure all fields are valid: ${errorMessages.join(', ')}`,
      );
      return false;
    }
    return true;
  }, [pickupForm, itemForm]);

  const validateForm = useCallback(async () => {
    const validationErrors = [];
    const isDropOffValid = await dropOffForm.trigger();
    if (!isDropOffValid) {
      validationErrors.push(dropOffForm.formState.errors);
    }
    const isFormValid = await form.trigger();
    if (!isFormValid) {
      validationErrors.push(form.formState.errors);
    }

    const errorMessages = extractFormValidationErrorMessages(validationErrors);

    if (errorMessages.length > 0) {
      toast.error(
        `Please ensure all fields are valid: ${errorMessages.join(', ')}`,
      );
      return false;
    }
    return true;
  }, [dropOffForm, form]);

  const submitPickupAndItem = async (index: number) => {
    await pickupForm.handleSubmit(
      async (pickupData) => {
        if (currentPickups[index]?.type === 'PICK_UP') {
          currentPickups[index] = {
            ...currentPickups[index],
            ...pickupData,
            collectionReference: pickupData.collectionReference ?? '',
            type: currentPickups[index]?.type,
          };
        }

        const isItemValid = await itemForm.trigger();
        if (!isItemValid) return;

        await itemForm.handleSubmit(
          async (itemData) => {
            currentPickups[index].deliveryItems = [itemData];
            form.setValue('pickups', currentPickups);
          },
          (errors) => {
            handleError(errors, {
              sendToSentry: true,
              showNotification: false,
              consoleLog: true,
            });
          },
        )();
      },
      (errors) => {
        handleError(errors, {
          sendToSentry: true,
          showNotification: false,
          consoleLog: true,
        });
      },
    )();
  };

  const handleAddPickup = async () => {
    try {
      const isValidated = await validatePickupForm();
      if (!isValidated) return;

      const newPickup = {
        type: 'PICK_UP' as const,
        stopSequence: currentPickups.length + 1,
        companyName: '',
        addressLine1: '',
        postCode: '',
        windowStart: '',
        windowEnd: '',
        contacts: [
          {
            contactName: '',
            contactNumber: '',
            trackingEnabled: false,
            isPrimary: true,
          },
        ],
        deliveryItems: [],
        collectionReference: '',
      };

      currentPickups.push(newPickup);
      const newIndex = currentPickups.length - 1;

      await submitPickupAndItem(newIndex);

      resetForms();
      setShowPickupSection(false);
      setShowMainBackButton?.(true);
    } catch (error) {
      console.error('Error in form submission:', error);
    }
  };

  const handleUpdatePickup = async () => {
    if (selectedPickupIndex === null) return;

    const isValidated = await validatePickupForm();
    if (!isValidated) return;
    await submitPickupAndItem(selectedPickupIndex);

    setShowPickupSection(false);
    resetForms();
    setSelectedPickupIndex(null);
  };

  const handleSubmit = async () => {
    const isValidated = await validateForm();
    if (!isValidated) return;

    await dropOffForm.handleSubmit((data) => {
      const { id, ...rest } = data;
      const modifiedDropOffData = {
        ...rest,
        windowStart: deliveryWindowStart,
        windowEnd: deliveryWindowEnd,
        stopSequence: pickups?.length,
        type: 'DROP_OFF' as const,
        addressId: data.addressId === '' ? null : data.addressId,
      };
      form.setValue('dropOff', modifiedDropOffData);
      pickups?.forEach((pickup, index) => {
        const { id, ...rest } = pickup;
        const modifiedPickupData = {
          ...rest,
          windowStart: deliveryWindowStart,
          windowEnd: deliveryWindowEnd,
          stopSequence: index,
          type: 'PICK_UP' as const,
          addressId: pickup.addressId === '' ? null : pickup.addressId,
        };
        form.setValue(`pickups.${index}`, modifiedPickupData);
      });
      form.handleSubmit(
        (data) => {
          onSubmit(data);
        },
        (errors) => {
          handleError(errors, {
            sendToSentry: true,
            showNotification: false,
            consoleLog: true,
          });
        },
      )();
    })();
  };

  return {
    handleAddPickup,
    handleUpdatePickup,
    handleSubmit,
  };
};
