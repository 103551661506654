import { HydratedOrder, ExistingOrder } from '@tradeaze-packages/schemas';

export const parseToExistingOrder = (order: HydratedOrder): ExistingOrder => {
  const pickupDetails = order.deliveryStops
    ?.filter((stop) => stop.type === 'PICK_UP')
    .map((stop) => {
      return {
        deliveryStopId: stop.deliveryStopId,
        contacts: stop.contacts,
        collectionReference: stop.collectionReference,
        companyName: stop.companyName,
        addressLine1: stop.addressLine1,
        postCode: stop.postCode,
        requiresImage: stop.requiresImage,
        requiresSignature: stop.requiresSignature,
        position: stop.position,
        deliveryItems:
          stop.delivery?.deliveryItems.map((item) => ({
            id: item.id ?? '',
            deliveryId: item.deliveryId ?? '',
            name: item.name ?? '',
            quantity: item.quantity ?? 0,
            heightUnit: item.heightUnit ?? 'cm',
            lengthUnit: item.lengthUnit ?? 'cm',
            widthUnit: item.widthUnit ?? 'cm',
            weightUnit: item.weightUnit ?? 'kg',
            height: item.height ?? 0,
            length: item.length ?? 0,
            width: item.width ?? 0,
            weight: item.weight ?? 0,
            price: item.price ?? 0,
            description: item.description,
            heavySideItems: stop.delivery?.heavySideItems ?? false,
          })) ?? [],
        windowStart: stop.windowStart,
        windowEnd: stop.windowEnd,
        stopSequence: stop.stopSequence,
        addressId: stop.addressId,
        status: stop.delivery?.status,
        type: 'PICK_UP' as const,
      };
    });

  const dropoffDetails = order.deliveryStops
    ?.filter((stop) => stop.type === 'DROP_OFF')
    .map((stop) => {
      return {
        contacts: stop.contacts,
        companyName: stop.companyName,
        addressLine1: stop.addressLine1,
        postCode: stop.postCode,
        position: stop.position,
        windowStart: stop.windowStart,
        windowEnd: stop.windowEnd,
        deliveryStopId: stop.deliveryStopId,
        companyId: stop.companyId,
        addressId: stop.addressId,
        stopSequence: stop.stopSequence,
        type: 'DROP_OFF' as const,
      };
    });

  return {
    merchantId: order.merchantId,
    pickupDetails,
    dropoffDetails,
    type: order.type,
    deliveryVehicle:
      order.deliveryStops?.[0].delivery?.deliveryVehicleId ?? 'CAR',
    deliveryOption:
      order.deliveryStops?.[0].delivery?.deliveryOptionId ?? 'DAY',
    bookedBy: order.bookedBy,
    orderReference: order.merchantOrderReference,
    orderDate: new Date(order.deliveryWindowStart),
    orderStatus: order.orderStatus,
    invoicingName: order.invoicingName,
    deliveryWindowStart: order.deliveryWindowStart,
  };
};
