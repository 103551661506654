import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import {
  vehicleDimensions,
  ModalVehicleDimensions,
} from '../../orderConstants';

export const getVehicleDimensions = (
  vehicleId: DeliveryVehicleId | undefined,
  facing: 'SIDE' | 'REAR',
): ModalVehicleDimensions | null => {
  if (!vehicleId || !facing) {
    return null;
  }
  return vehicleDimensions[vehicleId][facing];
};
