import { UseFormReturn } from 'react-hook-form';
import { CreateOrder } from '@tradeaze-packages/schemas';

export type VehicleDetails = CreateOrder;

type Form = UseFormReturn<CreateOrder>;

export const isVehicleDetailsSectionComplete = (
  VehicleDetails: VehicleDetails,
  Form: Form,
): boolean => {
  return (
    Boolean(VehicleDetails.deliveryVehicle) &&
    Boolean(VehicleDetails.deliveryOption) &&
    Boolean(VehicleDetails.deliveryWindowStart) &&
    Boolean(VehicleDetails.deliveryWindowEnd) &&
    Boolean(VehicleDetails.deliveryPrice) &&
    Boolean(VehicleDetails.merchantServiceCharge) &&
    !Form.getFieldState('deliveryVehicle').invalid &&
    !Form.getFieldState('deliveryOption').invalid &&
    !Form.getFieldState('deliveryWindowStart').invalid &&
    !Form.getFieldState('deliveryWindowEnd').invalid &&
    !Form.getFieldState('deliveryPrice').invalid &&
    !Form.getFieldState('merchantServiceCharge').invalid
  );
};
