import { CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';

export const resetAddressFields = (
  setValue: UseFormReturn<CreateDeliveryStop>['setValue'],
) => {
  const fields = {
    addressId: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    postCode: '',
    position: undefined,
    deliveryNotes: '',
    companyName: '',
  };

  Object.entries(fields).forEach(([key, value]) =>
    setValue(key as keyof CreateDeliveryStop, value),
  );
};
