import { z } from 'zod';
import { EtaSchema } from '../../../models';

export const GetActiveDeliveryEtasResponseSchema = z.object({
  savedEtas: z.array(EtaSchema),
  failedScheduleIds: z.array(z.string()),
  failedDeliveryStopIds: z.array(z.string()),
});

export type GetActiveDeliveryEtasResponse = z.infer<
  typeof GetActiveDeliveryEtasResponseSchema
>;
