import { IoOptions } from 'react-icons/io5';
import { Box, Divider } from '@chakra-ui/react';
import { ItemDescription } from './ItemDescription';
import { ItemType } from './ItemType';
import { ItemLoad } from './ItemLoad';
import React from 'react';
import { ItemPrice } from './ItemPrice';
import { useItemDetailsSection, useOnEntry } from '../../hooks';
import { CreateDeliveryItem } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { PostHog } from 'posthog-js/react';
import { OrderSection } from '../../order-section';

type ItemDetailsSectionProps = {
  sectionName: string;
  isSectionComplete: boolean;
  form: UseFormReturn<CreateDeliveryItem>;
  posthog?: PostHog;
  setIsPrimaryView?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVehicleModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  loadLabel?: string;
  hideSection?: boolean;
};

export const ItemDetailsSection = ({
  sectionName,
  isSectionComplete,
  form,
  posthog,
  setIsPrimaryView,
  setIsVehicleModalOpen,
  loadLabel,
  hideSection = false,
}: ItemDetailsSectionProps) => {
  const { onToggleHeavySideItems, measurementUnit, handleMeasurementChange } =
    useItemDetailsSection({ posthog, form });

  const ref = useOnEntry(() => {
    if (setIsVehicleModalOpen) {
      setIsVehicleModalOpen(true);
    }
  });

  return (
    <div ref={ref}>
      <OrderSection
        name={sectionName}
        icon={<IoOptions />}
        isComplete={isSectionComplete}
        hideSection={hideSection}
      >
        <Box display={'flex'} flexDirection={'column'} gap={8}>
          <ItemDescription form={form} />
          <ItemType
            form={form}
            onToggleHeavySideItems={onToggleHeavySideItems}
          />
          <ItemLoad
            setIsPrimaryView={setIsPrimaryView}
            form={form}
            handleMeasurementChange={handleMeasurementChange}
            measurementUnit={measurementUnit}
            label={loadLabel}
          />
          <Divider />
          <ItemPrice form={form} />
        </Box>
      </OrderSection>
    </div>
  );
};
