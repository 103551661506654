import {
  UpdateAddressBody,
  UpdateAddressResponse,
} from '@tradeaze-packages/schemas';
import * as client from './client';
import { handleResponse } from './handle-response';

export const updateAddress = async ({
  addressId,
  body,
}: {
  addressId: string;
  body: UpdateAddressBody;
}): Promise<UpdateAddressResponse> =>
  handleResponse(
    client.updateAddress({
      path: {
        addressId,
      },
      body: {
        ...body,
        position: body.position ?? undefined,
      },
    }),
  );

export const geocodeAddress = async ({ postCode }: { postCode: string }) =>
  handleResponse(
    client.geocodeAddress({
      body: {
        postCode,
      },
    }),
  );
