import { z } from 'zod';
import {
  CreateOrderSchema,
  OrderSchema,
  CreateOrderAToBSchema,
  CreateOrderMultiCollectionSchema,
  CreateOrderMultiDropSchema,
  DeliverySchema,
  DeliveryStopSchema,
} from '../../../models';

export const CreateOrderBodySchema = CreateOrderSchema;

export const CreateOrderAToBBodySchema = CreateOrderAToBSchema;

export const CreateOrderMultiCollectionBodySchema =
  CreateOrderMultiCollectionSchema;

export const CreateOrderMultiDropBodySchema = CreateOrderMultiDropSchema;

export const CreateOrderResponseSchema = OrderSchema.extend({
  deliveries: z.array(
    DeliverySchema.extend({
      pickup: DeliveryStopSchema,
      dropOff: DeliveryStopSchema,
    }),
  ),
});

export type CreateOrderBody = z.infer<typeof CreateOrderBodySchema>;

export type CreateOrderResponse = z.infer<typeof CreateOrderResponseSchema>;
