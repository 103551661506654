import { z } from 'zod';
import { ExternalJobSchema } from '../../../models';

export const SelectQuoteBodySchema = z.object({
  providerId: z.string(),
  providerName: z.string(),
  currency: z.string(),
  price: z.number(),
  providerLogo: z.string().nullish(),
});

export const ExternalJobResponseSchema = ExternalJobSchema;

export type SelectQuoteBody = z.infer<typeof SelectQuoteBodySchema>;

export type ExternalJobResponse = z.infer<typeof ExternalJobResponseSchema>;
