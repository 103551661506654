import {
  Avatar,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  Link,
  Divider,
} from '@chakra-ui/react';
import { DeliveryStatus, HydratedOrder } from '@tradeaze-packages/schemas';
import React from 'react';
import { IoCar } from 'react-icons/io5';
import { FaUserCircle } from 'react-icons/fa';
import { Attribute, TextValue } from '../../shared';
import { OrderSection } from '../order-section';
import { sectionColumns } from './common';
import { DeliveryStatusTag } from '../../delivery';
import { TrackingLink } from './TrackingLink';

export const OrderCourier: React.FunctionComponent<{
  order: HydratedOrder;
  isAdmin?: boolean;
}> = ({ order, isAdmin }) => {
  return (
    <OrderSection name="Courier Details" icon={<IoCar />}>
      {order.deliveries.map((delivery, i) => {
        const showExternalFleetDetails = isAdmin && delivery.externalJob;

        return (
          <>
            {i > 0 ? <Divider mt={3} mb={5} /> : null}
            <SimpleGrid columns={sectionColumns} spacing={4}>
              <Attribute name="Courier">
                {delivery?.rider ? (
                  <HStack>
                    <Avatar
                      size={'xs'}
                      name={`${delivery.rider.firstName} ${delivery.rider.lastName}`}
                    />
                    <TextValue text={delivery.rider.firstName} />
                  </HStack>
                ) : (
                  <HStack alignItems={'center'}>
                    <HStack>
                      <Icon
                        as={FaUserCircle}
                        fontSize={24}
                        color={'blackAlpha.500'}
                      ></Icon>
                      <Text>Unassigned</Text>
                    </HStack>
                  </HStack>
                )}
              </Attribute>
              {showExternalFleetDetails ? (
                <Attribute name="Fleet">
                  <TextValue
                    text={delivery?.externalJob?.providerName || 'Tradeaze'}
                  />
                </Attribute>
              ) : null}
              {showExternalFleetDetails ? (
                <Attribute name="External Status">
                  <DeliveryStatusTag
                    status={
                      (delivery?.externalJob?.status as DeliveryStatus) || '-'
                    }
                  />
                </Attribute>
              ) : null}
              {showExternalFleetDetails &&
              delivery?.externalJob?.externalUrl ? (
                <Attribute name="External URL">
                  <Link
                    color={'blackAlpha.700'}
                    fontSize={'sm'}
                    href={delivery?.externalJob?.externalUrl}
                    noOfLines={1}
                  >
                    {delivery?.externalJob?.externalUrl}
                  </Link>
                </Attribute>
              ) : null}
              <Attribute name="Tracking URL">
                <TrackingLink
                  deliveryId={delivery.deliveryId}
                  isAdmin={isAdmin}
                />
              </Attribute>
            </SimpleGrid>
          </>
        );
      })}
    </OrderSection>
  );
};
