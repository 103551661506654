import React from 'react';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Position } from '@tradeaze-packages/schemas';
import { FaRoute } from 'react-icons/fa';

export const MapPointSelectMenu: React.FC<{
  coordinates?: Position[];
  enabled?: boolean;
  handleCancel: () => void;
  handleToggleEnabled: () => void;
  handleGoToGoogleMaps: () => void;
}> = ({
  coordinates,
  enabled,
  handleCancel,
  handleToggleEnabled,
  handleGoToGoogleMaps,
}) => {
  if (!enabled) {
    return (
      <Button onClick={handleToggleEnabled} colorScheme="yellow">
        <HStack>
          <FaRoute />
          <Text>Select Points</Text>
        </HStack>
      </Button>
    );
  }

  return (
    <Menu>
      <MenuButton as={Button} colorScheme={'green'}>
        {coordinates?.length || 0} points selected
      </MenuButton>
      <MenuList>
        <MenuItem onClick={handleGoToGoogleMaps}>Google Route</MenuItem>
        <MenuItem onClick={handleCancel}>Cancel</MenuItem>
      </MenuList>
    </Menu>
  );
};
