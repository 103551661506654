import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CreateDeliveryStop } from '@tradeaze-packages/schemas';
import { FormAttribute } from '../../../form';

type DriverInstructionSectionProps = {
  instructionsVisibility: boolean;
  setInstructionsVisibility: (value: boolean) => void;
  form: UseFormReturn<CreateDeliveryStop>;
  cypressTestId: string;
};

export const DriverInstructionSection = ({
  instructionsVisibility,
  setInstructionsVisibility,
  form,
  cypressTestId,
}: DriverInstructionSectionProps) => {
  const {
    register,
    formState: { errors },
  } = form;

  if (!instructionsVisibility) {
    return (
      <Button
        variant={'link'}
        onClick={() => setInstructionsVisibility(true)}
        colorScheme="blue"
        data-cy={`${cypressTestId}-add-driver-instructions-button`}
      >
        Add Driver Instructions
      </Button>
    );
  }

  return (
    <Box>
      <FormAttribute
        id={'deliveryNotes'}
        inputType={'textarea'}
        label="Driver Instructions"
        placeholder={'e.g. Ring doorbell'}
        description={'Any instructions for the driver for this specific stop'}
        error={errors.deliveryNotes}
        data-cy={`${cypressTestId}-driver-instructions`}
        {...register('deliveryNotes')}
      />
    </Box>
  );
};
