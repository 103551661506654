import {
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import { DatePicker } from '../../../shared';

type DeliveryDateProps = {
  fromDeliveryDateTime: Date;
  handleSelectDeliveryDate: (date: Date | undefined) => void;
  isAdmin: boolean;
  isSaturday: boolean;
  isPastDate: boolean;
};

export const DeliveryDate = ({
  fromDeliveryDateTime,
  handleSelectDeliveryDate,
  isAdmin,
  isSaturday,
  isPastDate,
}: DeliveryDateProps) => {
  return (
    <FormControl>
      <FormLabel>Delivery Date</FormLabel>
      <DatePicker
        mode="single"
        selected={fromDeliveryDateTime}
        onSelect={handleSelectDeliveryDate}
        allowPastDates={isAdmin}
        allowSundays={isAdmin}
        data-cy={'delivery-date'}
      />
      {isSaturday && (
        <Text
          color="orange.400"
          fontSize={14}
          pl={3}
          data-cy={'saturday-charges'}
        >
          (Saturday charges apply)
        </Text>
      )}
      {isPastDate && (
        <Alert status="error" my={4} borderRadius={'md'} data-cy={'past-date'}>
          <AlertIcon />
          Delivery date is in the past. Please select a future date.
        </Alert>
      )}
    </FormControl>
  );
};
