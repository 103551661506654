import { SiteContact } from '@tradeaze-packages/schemas';

export const getSavedContactPlaceholder = (
  savedContacts: SiteContact[] | undefined,
  isLoadingSavedContacts: boolean,
) => {
  if (savedContacts?.length) return 'Select existing site contact';
  if (isLoadingSavedContacts) return 'Loading saved site contacts';
  return 'No saved site contacts';
};
