import { set } from 'date-fns';

export const getDateFromScheduledTime = (
  time: string,
  fromDeliveryDateTime: Date,
) => {
  const [hours, minutes] = time.split(':');
  return set(fromDeliveryDateTime, {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
  });
};
