import { useCallback, useMemo, useState } from 'react';
import { HydratedOrder } from '@tradeaze-packages/schemas';

export const useSelectedOrderState = () => {
  const [selectedOrder, setSelectedOrder] = useState<HydratedOrder | undefined>(
    undefined
  );

  const onSelectOrder = useCallback(
    (order?: HydratedOrder) => setSelectedOrder(order),
    []
  );

  return useMemo(
    () => ({
      selectedOrder,
      onSelectOrder,
    }),
    [selectedOrder, onSelectOrder]
  );
};
