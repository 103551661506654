import { Center, SimpleGrid, Spinner } from '@chakra-ui/react';
import { HydratedDelivery, HydratedOrder } from '@tradeaze-packages/schemas';
import { Waypoint } from 'react-waypoint';
import { GridOrderCard } from '../order';
import { GridDeliveryCard } from '../delivery';

export const OrderPreviews: React.FC<{
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  deliveries: HydratedDelivery[];
  orders: HydratedOrder[];
  isAdmin: boolean;
  columns?: number[];
  selectedDelivery?: HydratedDelivery;
  selectedOrder?: HydratedOrder;
  hoveredDeliveryId?: string;
  groupByOrder: boolean;
  onWaypointEnter: () => void;
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onSelectOrder?: (order?: HydratedOrder) => void;
  onHoverDelivery?: (deliveryId: string) => void;
  onHoverLeaveDelivery?: () => void;
  onHoverOrder?: (orderId: string) => void;
  onHoverLeaveOrder?: () => void;
  onPressGoTo: (deliveryId: string) => void;
}> = ({
  isAdmin,
  isFetchingNextPage,
  hasNextPage,
  orders,
  columns,
  selectedDelivery,
  hoveredDeliveryId,
  groupByOrder,
  onSelectDelivery,
  onSelectOrder,
  onHoverDelivery,
  onHoverLeaveDelivery,
  onHoverOrder,
  onHoverLeaveOrder,
  onPressGoTo,
  onWaypointEnter,
}) => {
  return (
    <>
      <SimpleGrid spacing={6} columns={columns} paddingBottom={20}>
        {orders.map((order) => {
          if (order.type === 'A_TO_B' || !groupByOrder) {
            return order.deliveries.map((delivery) => (
              <GridDeliveryCard
                key={delivery.deliveryId}
                isAdmin={isAdmin}
                isSelected={Boolean(
                  selectedDelivery &&
                    selectedDelivery.deliveryId === delivery.deliveryId,
                )}
                isHovered={hoveredDeliveryId === delivery.deliveryId}
                delivery={{ ...delivery, order }}
                onPressGoTo={onPressGoTo}
                onSelectDelivery={onSelectDelivery}
                onHoverDelivery={onHoverDelivery}
                onHoverLeaveDelivery={onHoverLeaveDelivery}
              />
            ));
          }
          return (
            <GridOrderCard
              key={order.orderId}
              isAdmin={isAdmin}
              order={order}
              onHoverOrder={onHoverOrder}
              onHoverLeaveOrder={onHoverLeaveOrder}
              onSelectOrder={onSelectOrder}
            />
          );
        })}
      </SimpleGrid>
      {isFetchingNextPage && (
        <Center h="15vh">
          <Spinner />
        </Center>
      )}
      {hasNextPage && onWaypointEnter && <Waypoint onEnter={onWaypointEnter} />}
    </>
  );
};
