import { z } from 'zod';

export const ExternalJobStatusSchema = z.enum([
  'PENDING',
  'CONFIRMED',
  'COMPLETED',
  'REJECTED',
  'CANCELLED',
]);

export const ExternalJobSchema = z.object({
  id: z.string(),
  externalFleetId: z.string(),
  externalId: z.string(),
  providerId: z.string(),
  providerName: z.string(),
  providerLogo: z.string().nullish(),
  status: ExternalJobStatusSchema,
  trackingUrl: z.string(),
  price: z.number(),
  currency: z.string(),
  quoteId: z.string(),
  deliveryId: z.string(),
  externalCourierId: z.string().nullish(),
  externalUrl: z.string(),
});

export type ExternalJobStatus = z.infer<typeof ExternalJobStatusSchema>;
export type ExternalJob = z.infer<typeof ExternalJobSchema>;
