import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';

export const formatNumberIfValid = (
  value: string,
  countryCode: CountryCode = 'GB',
) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(value, countryCode);

  if (!parsedPhoneNumber?.isValid()) {
    return;
  }

  return parsedPhoneNumber.formatInternational();
};
