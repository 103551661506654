import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { CreateDeliveryItem } from '@tradeaze-packages/schemas';

type ItemTypeProps = {
  form: UseFormReturn<CreateDeliveryItem>;
  onToggleHeavySideItems: (value: string) => void;
};

export const ItemType = ({ form, onToggleHeavySideItems }: ItemTypeProps) => {
  const {
    control,
    formState: { errors },
  } = form;

  return (
    <FormControl isInvalid={Boolean(errors.heavySideItems)}>
      <FormLabel>{'Type of Items'}</FormLabel>
      <Controller
        name="heavySideItems"
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            value={value === true ? 'YES' : value === false ? 'NO' : undefined}
            onChange={(value) => {
              onToggleHeavySideItems(value);
              onChange(value === 'YES');
            }}
            data-cy={'heavyside-items-group'}
          >
            <Stack direction={'column'} spacing={6} p={3}>
              <Radio value={'NO'} data-cy={'shop-items'}>
                <Text>Shop items only</Text>
                <Text color={'blackAlpha.500'} fontSize={14}>
                  E.g.: items off the shelf
                </Text>
              </Radio>
              <Radio value={'YES'} data-cy={'heavyside-items'}>
                <Text>
                  <Text>Includes yard items (heavyside)</Text>
                  <Text color={'blackAlpha.500'} fontSize={14}>
                    E.g.: bricks, sand, timber, plasterboard, etc.
                  </Text>
                </Text>
              </Radio>
            </Stack>
            <Text
              color={'red.500'}
              fontSize={14}
              data-cy={'heavy-side-items-error'}
            >
              {errors.heavySideItems?.message}
            </Text>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
