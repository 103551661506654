import { DuplicateDropoffStop, DuplicateOrder, DuplicatePickupStop, HydratedOrder } from '@tradeaze-packages/schemas';

// only built to work for A to B for now
export const buildReturnJourneyOrder = (order: HydratedOrder): DuplicateOrder => {
  const firstDelivery = order.deliveries[0];

  const firstStop = firstDelivery.pickup;

  const lastStop = firstDelivery.dropOff;

  const newPickup = {
    type: 'PICK_UP',
    addressLine1: lastStop.addressLine1,
    postCode: lastStop.postCode,
    position: lastStop.position,
    stopSequence: 0,
    contacts: lastStop.contacts || [],
    windowStart: lastStop.windowStart,
    windowEnd: lastStop.windowEnd,
    companyName: lastStop.companyName,
    collectionReference: '',
    addressId: lastStop.addressId,
    requiresImage: false,
    requiresSignature: false,
    deliveryItems: firstDelivery.deliveryItems.map((item) => ({
      ...item,
      heavySideItems: firstDelivery.heavySideItems ?? false,
    })),
  } satisfies DuplicatePickupStop;

  const newDropOff = {
    type: 'DROP_OFF',
    addressLine1: firstStop.addressLine1,
    postCode: firstStop.postCode,
    position: firstStop.position,
    stopSequence: 1,
    windowStart: firstStop.windowStart,
    windowEnd: firstStop.windowEnd,
    companyName: firstStop.companyName,
    addressId: firstStop.addressId,
    companyId: firstStop.companyId,
    requiresImage: true,
    requiresSignature: true,
    contacts: firstStop.contacts?.length ? firstStop.contacts : [{
      contactName: undefined,
      contactNumber: undefined,
      isPrimary: true,
      trackingEnabled: true,
    }],
  } satisfies DuplicateDropoffStop;

  return {
    merchantId: order.merchantId,
    deliveryOption: firstDelivery.deliveryOptionId,
    deliveryVehicle: firstDelivery.deliveryVehicleId,
    invoicingName: order.invoicingName,
    type: order.type,
    pickupDetails: [newPickup],
    dropoffDetails: [newDropOff],
  };
};
