import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Button, ChakraComponent, Heading } from '@chakra-ui/react';
import React from 'react';

type OrderSectionProps = {
  children: React.ReactNode;
  name?: string;
  icon?: React.ReactNode;
  isComplete?: boolean;
  onRemove?: () => void;
  headerButtonLabel?: string;
  headerButtonIcon?: React.ReactNode;
  headerButtonAction?: () => void;
  hideHeaderButton?: boolean;
  hideSection?: boolean;
} & React.ComponentProps<ChakraComponent<'div'>>;

export const OrderSection = ({
  children,
  name,
  icon,
  isComplete,
  onRemove,
  headerButtonLabel,
  headerButtonIcon,
  headerButtonAction,
  hideHeaderButton,
  hideSection,
  ...props
}: OrderSectionProps) => (
  <Box
    p={[5, 7]}
    pt={[9, 9]}
    borderRadius={20}
    borderWidth={isComplete ? 2 : 0.5}
    borderColor={isComplete ? 'green.400' : 'rgba(0,0,0,0.3)'}
    transition={'all 0.2s'}
    position={'relative'}
    display={hideSection ? 'none' : 'block'}
    {...props}
  >
    {isComplete ? (
      <Box position={'absolute'} top={-6} right={-2}>
        <CheckCircleIcon color={'green.400'} />
      </Box>
    ) : null}
    <Heading
      bg={'white'}
      px={6}
      position={'absolute'}
      top={-4}
      left={-1}
      size="md"
      display={'flex'}
      alignItems={'center'}
    >
      {name}
      {icon && <Box ml={2}>{icon}</Box>}
    </Heading>
    {!hideHeaderButton && headerButtonAction && headerButtonLabel ? (
      <Button
        position={'absolute'}
        top={-4}
        right={8}
        size={'sm'}
        onClick={headerButtonAction}
      >
        {headerButtonIcon}
        {headerButtonLabel}
      </Button>
    ) : null}

    {onRemove ? (
      <Button
        position={'absolute'}
        top={-4}
        right={2}
        colorScheme={'red'}
        backgroundColor="white"
        variant="ghost"
        size={'sm'}
        onClick={onRemove}
      >
        Remove
      </Button>
    ) : null}
    {children}
  </Box>
);
