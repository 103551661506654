import React, { useCallback, useMemo } from 'react';
import {
  CreateDeliveryStop,
  DeliveryStopContact,
  SiteContact,
} from '@tradeaze-packages/schemas';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useGetSiteContacts } from '@tradeaze/frontend/hooks';
import {
  findContactIndex,
  formatNumberIfValid,
  formatSavedContacts,
  getIsContactSelected,
  getNextEmptyFieldIndex,
  getSavedContactPlaceholder,
  isContactsLimitReached,
} from '../../utils';

type UseSiteContacts = {
  companyId?: string | null;
};

export const useSiteContacts = ({ companyId }: UseSiteContacts) => {
  const CONTACTS_LIMIT = 5;
  const form = useFormContext<CreateDeliveryStop>();
  const { setValue, control } = form;
  const { append, remove, insert } = useFieldArray({
    control,
    name: 'contacts' as const,
  });
  const contacts = useMemo(() => form.watch('contacts') || [], [form]);

  const { data: paginatedSavedContacts, isFetching: isLoadingSavedContacts } =
    useGetSiteContacts(companyId);

  const savedContacts = useMemo(
    () => formatSavedContacts(paginatedSavedContacts),
    [paginatedSavedContacts],
  );

  const savedContactPlaceholder = useMemo(
    () => getSavedContactPlaceholder(savedContacts, isLoadingSavedContacts),
    [savedContacts, isLoadingSavedContacts],
  );

  const getIsContactSelectedCallback = useCallback(
    (siteContact: SiteContact) => getIsContactSelected(contacts, siteContact),
    [contacts],
  );

  const isContactsLimitReachedCallback = useCallback(
    () => isContactsLimitReached(contacts, CONTACTS_LIMIT),
    [contacts],
  );

  const getNextEmptyFieldIndexCallback = useCallback(
    () => getNextEmptyFieldIndex(contacts),
    [contacts],
  );

  const getIsDropdownDisabled = useCallback(
    () =>
      isContactsLimitReachedCallback() &&
      getNextEmptyFieldIndexCallback() === -1,
    [isContactsLimitReachedCallback, getNextEmptyFieldIndexCallback],
  );

  const findContactIndexCallback = useCallback(
    (contact: Partial<SiteContact>) => findContactIndex(contacts, contact),
    [contacts],
  );

  const isExistingContact = useCallback(
    (value: SiteContact): boolean => findContactIndexCallback(value) >= 0,
    [findContactIndexCallback],
  );

  const isSingleEmptyField = useCallback((): boolean => {
    return (
      contacts.length === 1 &&
      contacts[0].contactName === '' &&
      contacts[0].contactNumber === ''
    );
  }, [contacts]);

  const updateContact = useCallback(
    (index: number, data: Partial<DeliveryStopContact>) => {
      (Object.keys(data) as Array<keyof DeliveryStopContact>).forEach((key) => {
        setValue(`contacts.${index}.${key}`, data[key]);
      });
    },
    [setValue],
  );

  const resetContactField = useCallback(
    (index: number): void => {
      updateContact(index, {
        contactName: '',
        contactNumber: '',
        trackingEnabled: index === 0,
      });
    },
    [updateContact],
  );

  const removeContact = useCallback(
    (contact: { contactName: string; contactNumber: string }): void => {
      if (isSingleEmptyField()) {
        return;
      }

      const fieldIndex = findContactIndexCallback(contact);

      if (fieldIndex >= 0) {
        if (contacts.length === 1) {
          resetContactField(fieldIndex);
        } else {
          remove(fieldIndex);
          if (fieldIndex === 0) {
            updateContact(fieldIndex, {
              isPrimary: true,
              trackingEnabled: true,
            });
          }
        }
      }
    },
    [
      contacts.length,
      isSingleEmptyField,
      findContactIndex,
      resetContactField,
      updateContact,
      remove,
    ],
  );

  const replaceContact = useCallback(
    (index: number, value: SiteContact) => {
      remove(index);
      insert(index, {
        contactName: value.contactName,
        contactNumber: value.contactNumber,
        trackingEnabled: index === 0,
        isPrimary: index === 0,
        siteContactId: value.siteContactId,
      });
    },
    [remove, insert],
  );

  const appendExistingContact = useCallback(
    (contact: DeliveryStopContact) => {
      if (contacts.length === CONTACTS_LIMIT) {
        return;
      }
      append({
        contactName: contact.contactName,
        contactNumber:
          formatNumberIfValid(contact.contactNumber) || contact.contactNumber,
        isPrimary: contact.isPrimary,
        trackingEnabled: contact.trackingEnabled,
        siteContactId: contact.siteContactId,
      });
    },
    [contacts.length, append],
  );

  const addExistingContact = useCallback(
    (contact: SiteContact) => {
      if (isExistingContact(contact) || isContactsLimitReachedCallback()) {
        return;
      }

      const nextEmptyFieldIndex = getNextEmptyFieldIndexCallback();

      if (nextEmptyFieldIndex >= 0) {
        replaceContact(nextEmptyFieldIndex, contact);
      } else {
        appendExistingContact({
          ...contact,
          trackingEnabled: false,
          isPrimary: false,
        });
      }
    },
    [
      isExistingContact,
      isContactsLimitReachedCallback,
      getNextEmptyFieldIndexCallback,
      replaceContact,
      appendExistingContact,
    ],
  );

  const handleDropdownSelect = (value: SiteContact) => {
    if (isExistingContact(value)) {
      removeContact(value);
      return;
    }

    if (isContactsLimitReachedCallback()) {
      return;
    }

    addExistingContact(value);
  };

  const appendNewContact = useCallback(() => {
    if (contacts.length === CONTACTS_LIMIT) {
      return;
    }
    append({
      contactName: '',
      contactNumber: '',
      trackingEnabled: false,
      isPrimary: false,
    });
  }, [contacts.length, append]);

  const handleGroupTracking = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    contacts.forEach((_field, index) => {
      if (!isChecked && index === 0) {
        return;
      }
      updateContact(index, {
        trackingEnabled: isChecked,
      });
    });
  };

  return {
    form,
    savedContacts,
    savedContactPlaceholder,
    isLoadingSavedContacts,
    getIsContactSelected: getIsContactSelectedCallback,
    getIsDropdownDisabled,
    handleDropdownSelect,
    contacts,
    handleRemoveContactCard: removeContact,
    handleAddContactCard: appendNewContact,
    handleUpdateContact: updateContact,
    handleGroupTracking,
  };
};
