import { z } from 'zod';
const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;

export const validateDatetime = z
  .string({
    description:
      'An ISO string date of the time the delivery is scheduled to start. If not provided, defaults to the current time.',
  })
  .regex(isoDateTimeRegex, {
    message: 'Invalid date-time format. Expected format: YYYY-MM-DDTHH:MM:SSZ',
  })
  .nullish();
