import { z } from 'zod';
import { AddressSchema, MerchantAddressSchema } from '../../../models';

export const CreateMerchantAddressBodySchema = MerchantAddressSchema.omit({
  merchantId: true,
});

export const CreateMerchantAddressResponseSchema = AddressSchema;

export type CreateMerchantAddressBody = z.infer<
  typeof CreateMerchantAddressBodySchema
>;

export type CreateMerchantAddressResponse = z.infer<
  typeof CreateMerchantAddressResponseSchema
>;
