import { formatDuration, intervalToDuration } from 'date-fns';

export const secondsToDurationText = (s: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: s * 1000,
  });

  if (duration.hours === 0 && duration.minutes === 0) {
    return '0 minutes';
  }

  return formatDuration(duration, {
    format: ['hours', 'minutes'],
  });
};
