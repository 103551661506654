import { z } from 'zod';
import { DeliveryVehicleIdSchema } from '../../models';

export const DBSeedIntegrationTestResponseSchema = z.object({
  regionId: z.string(),
  merchantId: z.string(),
  orderId: z.string(),
  deliveryStopId: z.string(),
  pickupStopId: z.string(),
  companyId: z.string(),
  riderId: z.string(),
  deliveryId: z.string(),
  deliveryVehicleId: DeliveryVehicleIdSchema,
  deliveryDate: z.date(),
  gigJobId: z.never(),
});

export const DBSeedIntegrationTestResponseWithGigJobSchema =
  DBSeedIntegrationTestResponseSchema.extend({
    gigJobId: z.string(),
  });

export type DBSeedIntegrationTestResponse = z.infer<
  typeof DBSeedIntegrationTestResponseSchema
>;

export type DBSeedIntegrationTestResponseWithGigJob = z.infer<
  typeof DBSeedIntegrationTestResponseWithGigJobSchema
>;
