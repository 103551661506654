import { useEffect, useMemo, useState } from 'react';
import {
  formatMetresToKm,
  getApiErrorMessage,
  validatePostcode,
} from '@tradeaze/shared/utils';
import toast from 'react-hot-toast';
import { captureEvent } from '@tradeaze/frontend/utils';
import { useGetDeliveryPrice } from '@tradeaze/frontend/hooks';
import {
  CreateDeliveryStop,
  CreateOrder,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { PostHog } from 'posthog-js/react';
import { UseFormReturn } from 'react-hook-form';
import { useThresholdDistances } from './useThresholdDistances';

type UseDeliveryPrice = {
  merchantAccount?: MerchantAccount;
  createOrderDetails: CreateOrder;
  allStops: { postCode: string; type: 'PICK_UP' | 'DROP_OFF' }[];
  isAdmin?: boolean;
  posthog?: PostHog;
  form: UseFormReturn<CreateOrder>;
  pickupForm: UseFormReturn<CreateDeliveryStop>;
  dropOffForm: UseFormReturn<CreateDeliveryStop>;
  heavySideItems: boolean;
  isSamePricingConfig: boolean;
  existingOrder: boolean;
  isMultiCollection?: boolean;
};

export const useDeliveryPrice = ({
  merchantAccount,
  createOrderDetails,
  allStops,
  isAdmin,
  posthog,
  form,
  pickupForm,
  dropOffForm,
  heavySideItems,
  isSamePricingConfig,
  existingOrder,
  isMultiCollection,
}: UseDeliveryPrice) => {
  const [shouldFreezePriceForEdit, setShouldFreezePriceForEdit] =
    useState<boolean>(false);

  const { setValue } = form;

  const isEveryPostcodeValid = allStops.every((stop) =>
    validatePostcode(stop.postCode),
  );

  const shouldCheckThresholdDistances = useMemo(() => {
    if (isMultiCollection) {
      return isEveryPostcodeValid && allStops.length > 1;
    }
    return isEveryPostcodeValid;
  }, [isMultiCollection, isEveryPostcodeValid, allStops.length]);

  const thresholdDistances = useThresholdDistances(
    allStops,
    shouldCheckThresholdDistances,
  );

  const allowLongDistance = Boolean(
    isAdmin || merchantAccount?.featureFlags?.allowLongDistance,
  );

  const shouldCalculateDelivery =
    isEveryPostcodeValid &&
    !shouldFreezePriceForEdit &&
    !!createOrderDetails.deliveryOption &&
    !!createOrderDetails.deliveryVehicle &&
    !!createOrderDetails.deliveryWindowStart &&
    !createOrderDetails.isPriceEdited;

  useEffect(() => {
    if (existingOrder) {
      setShouldFreezePriceForEdit((prevState) => {
        if (prevState === false) {
          /**
           * once config has been changed, we don't want to freeze price again, even if the config changes
           * back to what it was. Otherwise we get unexpected stale pricing when changing between options.
           */
          return prevState;
        }
        return isSamePricingConfig;
      });
    }
  }, [existingOrder, isSamePricingConfig]);

  const { error, isFetching } = useGetDeliveryPrice(
    {
      deliveryOptionId: createOrderDetails.deliveryOption,
      scheduledTime: createOrderDetails.deliveryWindowStart,
      deliveryStopLocations: allStops?.map((stop) => stop.postCode) ?? [],
      throwOverThreshold: !allowLongDistance,
      throwOnBikeOverThreshold: !isAdmin,
      heavySideItems,
    },
    {
      enabled: shouldCalculateDelivery,
      onError(e) {
        const message = getApiErrorMessage(e);
        const data = e.error?.data;
        data?.deliveryStops?.forEach((stop, index) => {
          if (stop.isOverThreshold) {
            const isPickupStop = allStops[index].type === 'PICK_UP';
            const isDropOffStop = allStops[index].type === 'DROP_OFF';

            if (isPickupStop) {
              pickupForm.setError(`postCode`, {
                message: `Pickup location is too far from the centre of London (${formatMetresToKm(
                  stop.distance,
                )}km > ${formatMetresToKm(data.thresholdMetres)}km)`,
              });
            } else if (isDropOffStop) {
              dropOffForm.setError(`postCode`, {
                message: `Dropoff location is too far from the centre of London (${formatMetresToKm(
                  stop.distance,
                )}km > ${formatMetresToKm(data.thresholdMetres)}km)`,
              });
            }
          }
        });
        toast.error(message);
      },
      onSuccess(data) {
        //clearErrors('deliveryStops'); // make sure this works
        if (posthog) {
          captureEvent(posthog)('delivery_price_calculated', {
            deliveryOption: createOrderDetails.deliveryOption,
            deliveryVehicle: createOrderDetails.deliveryVehicle,
            heavySideItems,
            deliveryPrice: data?.deliveryPrice,
            serviceCharge: data?.serviceCharge,
            totalTimeEstimate: data?.totalTimeEstimate,
            orderType: createOrderDetails.type,
          });
        }
      },
      onSettled(data) {
        /**
         * Type casting so when no price result is returned, the price is wiped.
         * Form submission will protect us against prices being undefined
         */
        setValue(
          'merchantServiceCharge',
          data?.serviceCharge as unknown as number,
        );
        setValue('deliveryPrice', data?.deliveryPrice as unknown as number);
        setValue('deliveryDuration', data?.duration);
        setValue('deliveryDistance', data?.distance);
        setValue('totalTimeEstimate', data?.totalTimeEstimate);
        setValue('isPriceEdited', false);
      },
    },
  );

  return {
    isDeliveryPriceLoading: isFetching,
    deliveryPriceErrorMessage: error?.error.message ?? null,
    thresholdDistances,
  };
};
