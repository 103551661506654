import { BiReceipt } from 'react-icons/bi';
import { orderKeyToLabel } from '@tradeaze/shared/utils';
import { UseFormReturn } from 'react-hook-form';
import { CreateOrder } from '@tradeaze-packages/schemas';
import { OrderSection } from '../../order-section';
import { FormAttribute } from '../../../form';

type InvoicingSectionProps = {
  form: UseFormReturn<CreateOrder>;
  isSectionComplete: boolean;
};

export const InvoicingSection = ({
  form,
  isSectionComplete,
}: InvoicingSectionProps) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <OrderSection
      name="Invoicing Details"
      icon={<BiReceipt />}
      isComplete={isSectionComplete}
    >
      <FormAttribute
        id={'invoicingName'}
        label={orderKeyToLabel['invoicingName']}
        error={errors.invoicingName}
        placeholder={'Invoicing Name'}
        isRequired={true}
        description={'The name to be used for invoicing purposes'}
        data-cy={'invoicingName'}
        {...register('invoicingName')}
      />
    </OrderSection>
  );
};
