import { UseFormReturn } from 'react-hook-form';
import { CreateOrder } from '@tradeaze-packages/schemas';

type Form = UseFormReturn<CreateOrder>;

export const isInvoicingSectionComplete = (form: Form) => {
  const invoicingName = form.watch('invoicingName');

  return Boolean(invoicingName) && !form.getFieldState('invoicingName').invalid;
};
