import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  GeocodeAddressBodySchema,
  GeocodeAddressResponseSchema,
} from '../../address-service';
import { z } from 'zod';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/address';

/**
 * POST /geocode
 */
registry.registerPath({
  operationId: 'geocodeAddress',
  path: `${basePath}/geocode`,
  description: 'Get latitude and longitude for a given address.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: GeocodeAddressBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Address geocoded successfully.',
      content: {
        'application/json': {
          schema: GeocodeAddressResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const addressApiRegistry = registry; 