import { FieldError } from 'react-hook-form';

export const extractFormValidationErrorMessages = (
  validationErrors: Array<Record<string, unknown>>,
): string[] => {
  const errorMessages: string[] = [];

  validationErrors.forEach((error) => {

    if (error['invoicingName']) {
      const invoicingNameError = error['invoicingName'] as FieldError;
      if (invoicingNameError?.message?.includes('Invoicing name')) {
        errorMessages.push('invoicing name');
      }
    }

    if (error['addressLine1']) {
      const addressError = error['addressLine1'] as FieldError & {
        isPickup?: boolean;
        isDropOff?: boolean;
      };
      if (addressError?.message?.includes('Delivery address')) {
        if (addressError.isPickup) {
          errorMessages.push('pickup address');
        } else if (addressError.isDropOff) {
          errorMessages.push('drop off address');
        } else {
          errorMessages.push('address');
        }
      }
    }

    if (error['collectionReference']) {
      errorMessages.push('pickup reference');
    }

    if (error['companyName']) {
      const companyNameError = error['companyName'] as FieldError & {
        isPickup?: boolean;
        isDropOff?: boolean;
      };
      if (companyNameError.isPickup) {
        errorMessages.push('pickup company name');
      } else if (companyNameError.isDropOff) {
        errorMessages.push('drop off company name');
      } else {
        errorMessages.push('company name');
      }
    }

    if (error['postCode']) {
      const postCodeError = error['postCode'] as FieldError & {
        isPickup?: boolean;
        isDropOff?: boolean;
      };
      if (postCodeError?.message?.includes('Invalid UK postcode')) {
        if (postCodeError.isPickup) {
          errorMessages.push('pickup postcode');
        } else if (postCodeError.isDropOff) {
          errorMessages.push('drop off postcode');
        } else {
          errorMessages.push('postcode');
        }
      }
    }

    if (error['contacts'] && Array.isArray(error['contacts'])) {
      errorMessages.push('drop off contact');
    }

    if (error['heavySideItems']) {
      errorMessages.push('item type');
    }

    const itemMeasurements = ['height', 'length', 'width'];
    if (itemMeasurements.some((key) => key in error)) {
      errorMessages.push('item measurement');
    }

    if (error['weight']) {
      errorMessages.push('item weight');
    }

    if (error['price']) {
      errorMessages.push('item price');
    }

    if (error['deliveryOption']) {
      errorMessages.push('delivery option');
    }

    if (error['bookedBy']) {
      errorMessages.push('booked by');
    }

    if (error['merchantOrderReference']) {
      errorMessages.push('purchase order reference');
    }
  });

  return errorMessages;
};
