import { DeliveryItem, DeliveryVehicle } from '@tradeaze-packages/schemas';
import { unitConverter } from '../unitConverter';

type DeliveryItemWithTotalVolume = DeliveryItem & {
  totalVolume?: number;
  totalWeight?: number;
};
type RequiredProps = Pick<
  DeliveryItemWithTotalVolume,
  | 'length'
  | 'width'
  | 'height'
  | 'weight'
  | 'widthUnit'
  | 'totalVolume'
  | 'totalWeight'
>;
type OptionalProps = Partial<
  Omit<
    DeliveryItem,
    | 'length'
    | 'width'
    | 'height'
    | 'weight'
    | 'widthUnit'
    | 'totalVolume'
    | 'totalWeight'
  >
>;
type CustomDeliveryItem = RequiredProps & OptionalProps;

export const filterVehiclesByItemSize = (
  deliveryVehicle: DeliveryVehicle,
  deliveryItemsValues?: CustomDeliveryItem,
) => {
  if (!deliveryItemsValues) {
    return true;
  }
  const { length, width, height, weight, widthUnit, totalVolume, totalWeight } =
    deliveryItemsValues;
  const { lengthCapacity, widthCapacity, heightCapacity, weightCapacity } =
    deliveryVehicle;

  if (!lengthCapacity || !widthCapacity || !heightCapacity || !weightCapacity) {
    return false;
  }

  const volumeCapacity = lengthCapacity * widthCapacity * heightCapacity;

  const convertedLength = unitConverter(length, widthUnit, 'm') ?? 0;
  const convertedWidth = unitConverter(width, widthUnit, 'm') ?? 0;
  const convertedHeight = unitConverter(height, widthUnit, 'm') ?? 0;
  const convertedWeight = weight ?? 0;
  const itemVolume = convertedLength * convertedWidth * convertedHeight;

  if (convertedLength > lengthCapacity) {
    return false;
  }

  if (convertedWidth > widthCapacity) {
    return false;
  }

  if (convertedHeight > heightCapacity) {
    return false;
  }

  if (convertedWeight > weightCapacity) {
    return false;
  }

  const volumeToCheck = totalVolume ?? itemVolume;

  if (volumeToCheck > volumeCapacity) {
    return false;
  }

  if (totalWeight && totalWeight > weightCapacity) {
    return false;
  }

  return true;
};
