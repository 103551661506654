import { DeliveryStopContact } from '@tradeaze-packages/schemas';

export const isContactsLimitReached = (
  contacts: DeliveryStopContact[],
  limit: number,
) => {
  return (
    contacts.filter((f) => f.contactName !== '' && f.contactNumber !== '')
      .length === limit
  );
};
