import { FormAttribute } from '../../../form';
import { Box } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { CreateDeliveryItem } from '@tradeaze-packages/schemas';

type ItemPriceProps = {
  form: UseFormReturn<CreateDeliveryItem>;
};

export const ItemPrice = ({ form }: ItemPriceProps) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Box>
      <FormAttribute
        id={'price'}
        inputType={'number'}
        currency="£"
        label={'Total Item Price (exc. VAT)'}
        isRequired
        isInvalid={Boolean(errors?.price)}
        error={errors?.price}
        description="Required to insure your items"
        data-cy={'item-price'}
        {...register('price', {
          setValueAs: (value) => (value === '' ? undefined : parseFloat(value)),
        })}
      />
    </Box>
  );
};
