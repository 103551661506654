import { UseFormReturn } from 'react-hook-form';
import { CreateOrder } from '@tradeaze-packages/schemas';

type Form = UseFormReturn<CreateOrder>;

export const isBookingInformationSectionComplete = (
  Form: Form,
  referencePrefix?: string | null,
): boolean => {
  const bookedBy = Form.watch('bookedBy');
  const merchantOrderReference = Form.watch('merchantOrderReference');

  return (
    Boolean(bookedBy) &&
    Boolean(merchantOrderReference) &&
    merchantOrderReference !== referencePrefix &&
    !Form.getFieldState('bookedBy').invalid &&
    !Form.getFieldState('merchantOrderReference').invalid
  );
};
