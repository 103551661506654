import { useCallback, useMemo, useState } from 'react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';

export const useSelectedDeliveryState = () => {
  const [selectedDelivery, setSelectedDelivery] = useState<HydratedDelivery | undefined>(
    undefined
  );

  const onSelectDelivery = useCallback(
    (delivery?: HydratedDelivery) => setSelectedDelivery(delivery),
    []
  );

  return useMemo(
    () => ({
      selectedDelivery,
      onSelectDelivery,
    }),
    [selectedDelivery, onSelectDelivery]
  );
};
