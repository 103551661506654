import { HydratedOrder } from '@tradeaze-packages/schemas';
import { isSameDay } from 'date-fns';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import { captureEvent } from '@tradeaze/frontend/utils';
import { getHasNotes, buildNotesPreview } from '../../delivery';

export const useOrderCardData = (
  order: HydratedOrder,
  isAdmin: boolean,
  onSelectOrder?: (order?: HydratedOrder) => void,
) => {
  const posthog = usePostHog();
  const navigate = useNavigate();

  const {
    orderId,
    createdAt,
    orderStatus,
    deliveryWindowStart,
    deliveryWindowEnd,
    type,
    updatedAt,
    deliveredAt,
    cancelledAt,
    Merchant,
    merchantOrderReference,
    deliveries,
  } = order;

  const companyName = deliveries?.[0]?.dropOff?.companyName;
  const deliveryItems = deliveries?.[0]?.deliveryItems;
  const dropOff = deliveries?.[0]?.dropOff;
  const isToday = isSameDay(new Date(), new Date(dropOff.windowEnd));
  const isHeavyside = Boolean(deliveries?.[0]?.heavySideItems);
  const hasNotes = getHasNotes(order, deliveries?.[0]);
  const notesPreview = buildNotesPreview(order, deliveries?.[0]);
  const deliveryVehicleId = deliveries?.[0]?.deliveryVehicleId;
  const deliveryOptionId = deliveries?.[0]?.deliveryOptionId;
  const deliveryCount = deliveries.length;

  const pickupPostCodes = deliveries.map(
    (delivery) => delivery.pickup.postCode,
  );
  const dropOffPostCode = deliveries[0].dropOff.postCode;

  const isGigJob = deliveries.some((delivery) => delivery.gigJob?.riderId);
  const isGigJobBoard = deliveries.some(
    (delivery) => delivery.gigJob?.gigJobBoard && !delivery.gigJob?.riderId,
  );
  const hasShiftRider = deliveries.some(
    (delivery) => !delivery.gigJob && delivery.riderId,
  );

  const drivers = deliveries
    .map((delivery) => {
      if (!delivery.rider) {
        return undefined;
      }
      return {
        riderId: delivery.rider.riderId,
        firstName: delivery.rider.firstName,
        lastName: delivery.rider.lastName,
        vehicleId: delivery.deliveryVehicleId,
        workType: delivery.rider.workType,
      };
    })
    .filter(
      (driver): driver is NonNullable<typeof driver> => driver !== undefined,
    )
    .filter(
      (driver, index, self) =>
        self.findIndex((t) => t?.riderId === driver?.riderId) === index,
    );

  console.log('drivers', drivers);

  const handleCardClick = () => {
    captureEvent(posthog)('order_card_clicked', {
      orderId: orderId,
    });
    if (onSelectOrder) {
      onSelectOrder?.(order);
    } else {
      navigate(`/orders/${orderId}`);
    }
  };

  const backgroundColor = {
    PENDING: 'gray.300',
    CONFIRMED: 'yellow.300',
    DELIVERED: 'green.300',
    CANCELLED: 'red.300',
    REJECTED: 'red.300',
  }[orderStatus];

  return {
    orderDetails: {
      orderId,
      createdAt,
      orderStatus,
      deliveryWindowStart,
      deliveryWindowEnd,
      type,
      updatedAt,
      deliveredAt,
      cancelledAt,
      merchantOrderReference,
      merchantName: Merchant?.merchantName,
      invoicingName: order.invoicingName,
    },
    deliveryDetails: {
      companyName,
      deliveryItems,
      deliveryVehicleId,
      deliveryOptionId,
      deliveryCount,
      pickupPostCodes,
      dropOffPostCode,
    },
    status: {
      isToday,
      isHeavyside,
      hasNotes,
      notesPreview,
      isGigJob,
      isGigJobBoard,
      hasShiftRider,
    },
    drivers,
    backgroundColor,
    handleCardClick,
    showControls: {
      showItemSizing: isAdmin,
      showMerchantName: isAdmin,
      showNotesPreview: isAdmin,
    },
  };
};
