import { useQuery } from '@tanstack/react-query';
import { geocodeAddress } from '@tradeaze-packages/api-client';
import { isPostalCode } from 'validator';
import { useDebouncedValue } from '../utility';

const geocodeAddressQueryKey = (postcode: string) => [
  'geocodeAddress',
  postcode,
];

export const useGeocodeAddress = (
  { postcode }: { postcode: string },
  options?: {
    enabled?: boolean;
  },
) => {
  const debouncedPostcode = useDebouncedValue(postcode, 500);

  const enabled = (options?.enabled ?? true) && isPostalCode(debouncedPostcode, 'GB');

  return useQuery({
    queryKey: geocodeAddressQueryKey(debouncedPostcode),
    queryFn: () => {
      if (!enabled) return null;

      return geocodeAddress({ postCode: debouncedPostcode });
    },
    enabled,
    retry: false,
    staleTime: Infinity,
  });
};
