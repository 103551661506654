import { DeliveryStopContact, SiteContact } from '@tradeaze-packages/schemas';

export const findContactIndex = (
  contacts: DeliveryStopContact[],
  contact: Partial<SiteContact>,
) => {
  return contacts.findIndex(
    (field) =>
      field.contactName === contact.contactName &&
      field.contactNumber === contact.contactNumber,
  );
};
