import {
  ClientDeliveryOption,
  DeliveryOptionId,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';

export function getNextOptionOnVehicleChange({
  currentVehicle,
  currentOption,
  newVehicle,
  availableDeliveryOptions: availableOptions,
}: {
  currentVehicle: DeliveryVehicleId;
  currentOption: DeliveryOptionId | null;
  newVehicle: DeliveryVehicleId;
  availableDeliveryOptions: ClientDeliveryOption[];
}): DeliveryOptionId {
  const nextOption = availableOptions.find((option) => {
    const includesVehicle = option.deliveryVehicleId === newVehicle;

    const includesOptionType =
      option.deliveryOptionId.replace(option.deliveryVehicleId, '') ===
      currentOption?.replace(currentVehicle, '');

    return includesVehicle && includesOptionType;
  });

  return nextOption?.deliveryOptionId || (`${newVehicle}_DAY` as const);
}
