import { DeliveryStopContact, SiteContact } from '@tradeaze-packages/schemas';

export const getIsContactSelected = (
  contacts: DeliveryStopContact[],
  siteContact: SiteContact,
) => {
  return contacts.some(
    (field) =>
      field.contactName === siteContact.contactName &&
      field.contactNumber === siteContact.contactNumber,
  );
};
