import { z } from 'zod';
import { PaginationParamsSchema } from './paginated-data-schema';
import { buildArrayOrSingleSchema } from '../utils';
import { DeliveryVehicleIdSchema, DeliveryVehicleSchema } from './delivery-vehicle-schema';
import { GigJobBoardSchema } from './gig-job-board-schema';

export const GigJobStatusSchema = z.enum([
  'PENDING',
  'ACCEPTED',
  'IN_PROGRESS',
  'COMPLETED',
  'CANCELLED',
]);

export const RiderGigJobStatusSchema = z.enum(['REJECTED', 'ACCEPTED']);

const GigJobSortByEnum = z.enum(['createdAt', 'completedAt', 'paidAt']);

export const JobPaymentStatusSchema = z.enum(['PAID', 'UNPAID']);

export const GigJobSortByPaymentStatus = z.boolean().optional();

export const GigJobFilterByJobStatus = z
  .enum(['PENDING', 'ACCEPTED', 'IN_PROGRESS', 'COMPLETED', 'CANCELLED'])
  .optional();

export const GigJobCurrencySchema = z.enum(['GBP']);

export const GigJobSchema = z.object({
  gigJobId: z.string(),
  description: z.string().nullish(),
  createdAt: z.string(),
  updatedAt: z.string(),
  cancelledAt: z.string().nullish(),
  paymentAmount: z.number().nonnegative(),
  paymentCurrency: GigJobCurrencySchema,
  paidAt: z.string().nullish(),
  completedAt: z.string().nullish(),
  deletedAt: z.string().nullish(),
  status: GigJobStatusSchema,
  riderId: z.string().nullish(),
  gigJobBoard: z.array(GigJobBoardSchema).nullish(),
});

export const CreateGigJobSchema = GigJobSchema.omit({
  cancelledAt: true,
  completedAt: true,
  deletedAt: true,
  paidAt: true,
  status: true,
  riderId: true,
}).extend({
  gigJobId: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  deliveryIds: z.array(z.string()).min(1),
});
export const CreateGigJobParamSchema = CreateGigJobSchema.extend({
  deliveryVehicleIds: z.array(DeliveryVehicleIdSchema)
});

export const UpdateGigJobSchema = GigJobSchema.omit({
  riderId: true,
  createdAt: true,
  updatedAt: true,
  gigJobId: true,
}).extend({
  deliveryIds: z.array(z.string()).optional(),
  cancelledAt: z.string().optional(),
  paidAt: z.string().optional(),
  deletedAt: z.string().optional(),
  status: GigJobStatusSchema.optional(),
  completedAt: z.string().optional(),
});

/**
 * Query Params for gig jobs
 */

export const GigJobsStringQueryParamsSchema = z.object({
  fromDate: z.string().optional(),
  toDate: z.string().optional(),
  sortBy: GigJobSortByEnum.optional(),
  driverName: z.string().optional(),
  paidStatus: z.preprocess((arg) => arg === 'true', z.boolean()).optional(),
  deliveryId: z.string().optional(),
  gigJobId: z.string().optional(),
  riderVehicleId: z.string().optional(),
  autoAccept: z.string().optional(),
  ...PaginationParamsSchema.shape,
});

export const GigJobsArrayQueryParamsSchema = z.object({
  status: buildArrayOrSingleSchema(GigJobStatusSchema, false),
});

export const GigJobsQueryParamsSchema = z.object({
  ...GigJobsArrayQueryParamsSchema.shape,
  ...GigJobsStringQueryParamsSchema.shape,
});

/**
 * Types
 */
export type GigJob = z.infer<typeof GigJobSchema>;
export type CreateGigJob = z.infer<typeof CreateGigJobSchema>;
export type CreateGigJobParam = z.infer<typeof CreateGigJobParamSchema>;
export type GigJobStatus = z.infer<typeof GigJobStatusSchema>;
export type GigJobCurrency = z.infer<typeof GigJobCurrencySchema>;
export type UpdateGigJob = z.infer<typeof UpdateGigJobSchema>;
export type GigJobSortBy = z.infer<typeof GigJobSortByEnum>;
export type GigJobSortByPaymentStatus = z.infer<
  typeof GigJobSortByPaymentStatus
>;
export type GigJobFilterByJobStatus = z.infer<typeof GigJobFilterByJobStatus>;
export type JobPaymentStatus = z.infer<typeof JobPaymentStatusSchema>;
export type RiderGigJobStatus = z.infer<typeof RiderGigJobStatusSchema>;
