import React from 'react';
import { Box } from '@chakra-ui/react';
import { HydratedDelivery, HydratedOrder } from '@tradeaze-packages/schemas';
import { OrderPreviews } from './OrderPreviews';
import { PageLoading } from '../layout/PageLoading';
import { PageError } from '../layout/PageError';
import { EmptyDeliveriesPage } from '../delivery';

export const OrderQueryPage = (props: {
  children?: React.ReactNode;
  deliveries: HydratedDelivery[];
  orders: HydratedOrder[];
  isAdmin: boolean;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  isError: boolean;
  columns?: number[];
  selectedDelivery?: HydratedDelivery;
  selectedOrder?: HydratedOrder;
  hoveredDeliveryId?: string;
  isFiltered: boolean;
  groupByOrder: boolean;
  fetchNextPage: () => void;
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onSelectOrder?: (order?: HydratedOrder) => void;
  onHoverDelivery?: (deliveryId: string) => void;
  onHoverLeaveDelivery?: () => void;
  onHoverOrder?: (orderId: string) => void;
  onHoverLeaveOrder?: () => void;
  onPressGoTo: (orderId: string) => void;
}) => (
  <Box>
    {props.deliveries?.length && props.deliveries.length > 0 ? (
      <OrderPreviews
        groupByOrder={props.groupByOrder}
        orders={props.orders}
        deliveries={props.deliveries}
        isAdmin={props.isAdmin}
        onWaypointEnter={props.fetchNextPage}
        hasNextPage={props.hasNextPage}
        isFetchingNextPage={props.isFetchingNextPage}
        columns={props.columns}
        selectedDelivery={props.selectedDelivery}
        selectedOrder={props.selectedOrder}
        onSelectDelivery={props.onSelectDelivery}
        onSelectOrder={props.onSelectOrder}
        hoveredDeliveryId={props.hoveredDeliveryId}
        onHoverDelivery={props.onHoverDelivery}
        onHoverLeaveDelivery={props.onHoverLeaveDelivery}
        onHoverOrder={props.onHoverOrder}
        onHoverLeaveOrder={props.onHoverLeaveOrder}
        onPressGoTo={props.onPressGoTo}
      />
    ) : props.isLoading ? (
      <PageLoading />
    ) : props.isError ? (
      <PageError />
    ) : (
      <EmptyDeliveriesPage isFiltered={props.isFiltered} />
    )}
  </Box>
);
