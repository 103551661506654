import { z } from "zod";

export const GetQuotesResponseSchema = z.array(z.object({
  quoteId: z.string(),
  providerId: z.string(),
  providerName : z.string(),
  providerLogo: z.string().nullish(),
  dropoffEta: z.string().nullable(),
  totalPrice: z.number(),
  currency: z.string(),
}));

export type GetQuotesResponse = z.infer<
  typeof GetQuotesResponseSchema
>;

