import { UseFormReturn } from 'react-hook-form';
import { CreateDeliveryItem } from '@tradeaze-packages/schemas';

export type ItemDetails = {
  heavySideItems?: boolean;
  length: number;
  width: number;
  height: number;
  weight: number;
  price: number;
};

type Form = UseFormReturn<CreateDeliveryItem>;

export const isItemDetailsSectionComplete = (
  itemDetails: ItemDetails,
  itemForm: Form,
): boolean => {
  return (
    Boolean(itemDetails.heavySideItems !== undefined) &&
    Boolean(itemDetails.length) &&
    Boolean(itemDetails.width) &&
    Boolean(itemDetails.height) &&
    Boolean(itemDetails.weight) &&
    Boolean(itemDetails.price) &&
    !itemForm.getFieldState('length').invalid &&
    !itemForm.getFieldState('width').invalid &&
    !itemForm.getFieldState('height').invalid &&
    !itemForm.getFieldState('weight').invalid &&
    !itemForm.getFieldState('price').invalid
  );
};
