import {
  DuplicateOrder,
  DuplicateOrderSchema,
} from '@tradeaze-packages/schemas';
import { handleError } from '@tradeaze/frontend/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';

export const useParseDuplicateOrderFromState = () => {
  const [duplicatedOrder, setDuplicatedOrder] = useState<
    DuplicateOrder | undefined
  >(undefined);

  const location = useLocation();

  const state = location.state;

  const getDuplicateOrder = useCallback(() => {
    if (!state) {
      return;
    }
    const parsedResult = z
      .object({
        order: DuplicateOrderSchema,
      })
      .safeParse(state);

    if (!parsedResult.success) {
      handleError(parsedResult.error, {
        showNotification: true,
        notificationMessage: 'Error parsing duplicate order',
        sendToSentry: true,
        consoleLog: true,
      });
      return;
    }
    setDuplicatedOrder(parsedResult.data.order);

    return parsedResult.data.order;
  }, [state]);

  useEffect(() => {
    getDuplicateOrder();
  }, [getDuplicateOrder]);

  return useMemo(
    () => ({ duplicatedOrder, getDuplicateOrder }),
    [duplicatedOrder, getDuplicateOrder],
  );
};
