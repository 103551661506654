import { DimensionUnit } from '@tradeaze-packages/schemas';

export const getStepByUnit = (unit: DimensionUnit) => {
  switch (unit) {
    case 'mm':
      return 10;
    case 'cm':
      return 1;
    case 'm':
      return 0.01;
  }
};
