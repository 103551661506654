import { useQuery } from '@tanstack/react-query';
import {
  GetDeliveryPriceErrorData,
  GetDeliveryPriceQuery,
} from '@tradeaze-packages/schemas';
import { fetchDeliveryPrice } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared';

export const getDeliveryPriceQueryKey = (args: GetDeliveryPriceQuery) => {
  return ['getDeliveryPrice', args];
};

type ErrorResponse = {
    data: {
      error: {
        message: string;
      };
    };
};

type PricingError = {
  error: {
    message: string;
    data: GetDeliveryPriceErrorData;
  };
  response?: ErrorResponse;
};

export const useGetDeliveryPrice = (
  args: GetDeliveryPriceQuery,
  options?: CustomUseQueryOptions<
    typeof fetchDeliveryPrice,
    PricingError
  >,
) =>
  useQuery({
    queryKey: getDeliveryPriceQueryKey(args),
    queryFn: async () => fetchDeliveryPrice(args),
    retry: false,
    ...options,
  });
