import { z } from 'zod';
import { HydratedDeliverySchema, DeliveriesQueryParamsSchema } from '../../../models';

export const GetDeliveriesByMerchantIdQuerySchema = DeliveriesQueryParamsSchema.omit({
  merchantId: true,
  merchantIds: true,
});

export const GetDeliveriesByMerchantIdResponseSchema = z.object({
  deliveries: z.array(HydratedDeliverySchema),
  nextPageIndex: z.number().nullable(),
});

export type GetDeliveriesByMerchantIdQuery = z.infer<
  typeof GetDeliveriesByMerchantIdQuerySchema
>;

export type GetDeliveriesByMerchantIdResponse = z.infer<
  typeof GetDeliveriesByMerchantIdResponseSchema
>;
