import {
  CreateOrder,
  MerchantAccount,
  ExistingOrder,
} from '@tradeaze-packages/schemas';
import { PostHog } from 'posthog-js/react';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { useMultiCollectionForm } from '../../hooks';
import { MultiCollectionAddPickup } from './MultiCollectionAddPickup';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { OrderStopCard } from '../../order-stop-card';
import { MultiCollectionAddDelivery } from './MultiCollectionAddDelivery';
import { FormProvider } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { InvoicingSection } from '../../order-form-sections';

type MultiCollectionOrderFormProps = {
  merchantAccount?: MerchantAccount;
  isAdmin: boolean;
  posthog?: PostHog;
  onSubmit: (order: CreateOrder) => void;
  addPickupMarker: AddMarkerFn;
  addDeliveryMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  setShowMainBackButton?: (value: boolean) => void;
  isLoading: boolean;
  existingOrder?: ExistingOrder;
};

export const MultiCollectionOrderForm = ({
  merchantAccount,
  isAdmin,
  posthog,
  onSubmit,
  addPickupMarker,
  addDeliveryMarker,
  removePickupMarker,
  removeDeliveryMarker,
  setShowMainBackButton,
  isLoading,
  existingOrder,
}: MultiCollectionOrderFormProps) => {
  const {
    pickupForm,
    itemForm,
    isPickupComplete,
    isItemDetailsComplete,
    selectedPickupIndex,
    showPickupSection,
    handleAddPickup,
    multiCollectionDetails,
    handleOrderCardSelect,
    handleUpdatePickup,
    handleCreateBackButton,
    handleEditBackButton,
    handleAddPickupButton,
    existingPickups,
    dropOffForm,
    itemDetails,
    isDropOffComplete,
    isDeliveryPriceLoading,
    deliveryPriceErrorMessage,
    thresholdDistances,
    hidePriorityDelivery,
    isVehicleDetailsComplete,
    form,
    handleSubmit,
    isBookingInformationComplete,
    handleRemoveOrderCard,
    isInvoicingComplete,
  } = useMultiCollectionForm({
    merchantAccount,
    isAdmin,
    posthog,
    onSubmit,
    setShowMainBackButton,
    existingOrder,
    addPickupMarker,
    removePickupMarker,
  });

  const pickupCount = (multiCollectionDetails?.pickups?.length ?? 0) + 1;

  const restrictedEditing =
    !isAdmin &&
    existingOrder?.pickupDetails?.some(
      (pickup) => pickup.status === 'CONFIRMED',
    );

  const orderStopCardsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (!showPickupSection && orderStopCardsRef.current.length > 0) {
      orderStopCardsRef.current[0]?.focus();
    }
  }, [showPickupSection]);

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" gap={10}>
        {selectedPickupIndex === null && showPickupSection ? (
          <HStack alignItems={'center'}>
            <Button
              mr="5px"
              variant={'ghost'}
              colorScheme={'gray'}
              onClick={handleCreateBackButton}
              data-cy={'add-pickup-back-button'}
            >
              <ChevronLeftIcon fontSize={32} />
            </Button>
            <Text fontSize="xl" fontWeight={600}>
              Add Multi-Collection Pickup {existingPickups + 1}
            </Text>
          </HStack>
        ) : null}

        {selectedPickupIndex !== null && showPickupSection ? (
          <HStack alignItems={'center'}>
            <ChevronLeftIcon
              boxSize={10}
              onClick={handleEditBackButton}
              cursor="pointer"
              data-cy={'edit-pickup-back-button'}
            />
            <Text fontSize="xl" fontWeight={600}>
              Edit Multi-Collection Pickup {selectedPickupIndex + 1}
            </Text>
          </HStack>
        ) : null}

        {!merchantAccount && !showPickupSection ? (
          <InvoicingSection
            form={form}
            isSectionComplete={isInvoicingComplete}
          />
        ) : null}

        {!showPickupSection &&
          multiCollectionDetails?.pickups?.map((pickup, index) => (
            <OrderStopCard
              key={index}
              ref={(el) => (orderStopCardsRef.current[index] = el)}
              title={`Pick Up ${index + 1}`}
              pickupReference={pickup.collectionReference}
              address={`${pickup.addressLine1}, ${pickup.postCode}`}
              companyName={pickup.companyName}
              itemSize={pickup.deliveryItems ?? []}
              onClick={() => handleOrderCardSelect(index)}
              onRemove={() => handleRemoveOrderCard(index)}
              isCancelled={pickup.status === 'CANCELLED'}
              isAdmin={isAdmin}
              restrictedEditing={restrictedEditing}
            />
          ))}
        {!showPickupSection ? (
          <Button
            onClick={handleAddPickupButton}
            colorScheme="yellow"
            size="sm"
            fontWeight="bold"
            data-cy={'add-pickup-button'}
          >
            Add Pickup {pickupCount}
          </Button>
        ) : null}
        {showPickupSection ? (
          <MultiCollectionAddPickup
            merchantAccount={merchantAccount}
            addPickupMarker={addPickupMarker}
            removePickupMarker={removePickupMarker}
            isPickupComplete={isPickupComplete}
            isItemDetailsComplete={isItemDetailsComplete}
            pickupForm={pickupForm}
            itemForm={itemForm}
            handleAddPickup={
              selectedPickupIndex !== null
                ? handleUpdatePickup
                : handleAddPickup
            }
            buttonLabel={
              selectedPickupIndex !== null
                ? `Update Pick Up ${selectedPickupIndex + 1}`
                : `Add Pick Up ${existingPickups + 1}`
            }
            pickupCount={
              selectedPickupIndex !== null
                ? selectedPickupIndex + 1
                : pickupCount
            }
            showExpanded={selectedPickupIndex !== null}
            restrictedEditing={Boolean(restrictedEditing)}
          />
        ) : null}
        {!showPickupSection &&
        selectedPickupIndex === null &&
        multiCollectionDetails?.pickups?.length >= 2 ? (
          <MultiCollectionAddDelivery
            merchantAccount={merchantAccount}
            dropOffForm={dropOffForm}
            addDeliveryMarker={addDeliveryMarker}
            removeDeliveryMarker={removeDeliveryMarker}
            isDropOffComplete={isDropOffComplete}
            isVehicleDetailsComplete={isVehicleDetailsComplete}
            handleSubmit={handleSubmit}
            hidePriorityDelivery={hidePriorityDelivery}
            isAnyStopOverThreshold={thresholdDistances.isAnyStopOverThreshold}
            isDeliveryPriceLoading={isDeliveryPriceLoading}
            deliveryPriceErrorMessage={deliveryPriceErrorMessage}
            itemDetails={itemDetails}
            isAdmin={isAdmin}
            isLoading={isLoading}
            isBookingInformationComplete={isBookingInformationComplete}
            showExpanded={Boolean(existingOrder)}
            initialDate={existingOrder?.orderDate}
            restrictedEditing={Boolean(restrictedEditing)}
          />
        ) : null}
      </Box>
    </FormProvider>
  );
};
