import { SelectDropdown } from '../../../shared';
import React from 'react';
import { Address } from '@tradeaze-packages/schemas';

type SavedAddressSectionProps = {
  handleSelectExistingAddress: (address: Address) => void;
  savedAddressPlaceholder: string;
  isLoadingSavedAddresses: boolean;
  savedAddresses:
    | {
        address: Address;
        displayName: string;
      }[]
    | undefined;
  disabled?: boolean;
};

export const SavedAddressSection = ({
  savedAddresses,
  savedAddressPlaceholder,
  isLoadingSavedAddresses,
  handleSelectExistingAddress,
  disabled,
}: SavedAddressSectionProps) => {
  return (
    <SelectDropdown
      isLoading={isLoadingSavedAddresses}
      onSelect={handleSelectExistingAddress}
      results={savedAddresses?.map((a) => ({
        label: a.displayName,
        value: a.address,
      }))}
      placeholder={savedAddressPlaceholder}
      showClear
      isMultiSelect={false}
      label={'Saved Address'}
      disabled={disabled}
    />
  );
};
