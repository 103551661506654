import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  ClockInRiderBodySchema,
  ClockInRiderResponseSchema,
  ClockOutRiderResponseSchema,
  CreateFleetVehicleRequestSchema,
  CreateFleetVehicleResponseSchema,
  CreateRiderBodySchema,
  CreateRiderResponseSchema,
  DeleteRiderResponseSchema,
  GetActiveFleetVehiclesResponseSchema,
  GetClockInsQuerySchema,
  GetClockInsResponseSchema,
  GetFleetVehiclesQuerySchema,
  GetFleetVehiclesResponseSchema,
  GetRiderByIdResponseSchema,
  GetRiderClockInResponseSchema,
  GetRiderIdentityResponseSchema,
  GetRiderLocationsBodySchema,
  GetRiderLocationsResponseSchema,
  GetRidersQuerySchema,
  GetRidersResponseSchema,
  SearchFleetVehicleBodySchema,
  SearchFleetVehicleResponseSchema,
  UpdateRiderBodySchema,
  RejectRiderRequestSchema,
  RejectRiderResponseSchema,
  UpdateRiderLocationV2BodySchema,
  UpdateRiderLocationV2ResponseSchema,
  UpdateRiderResponseSchema,
  FleetVehicleStatsResponseSchema,
  UpdateFleetVehicleRequestSchema,
  UpdateFleetVehicleResponseSchema,
  DeleteFleetVehicleResponseSchema,
  GetRiderFleetVehiclesResponseSchema,
  RecentFleetVehicleResponseSchema,
  RejectFleetVehicleRequestSchema,
  RejectFleetVehicleResponseSchema,
  ApproveFleetVehicleResponseSchema,
  ResetFleetVehicleResponseSchema,
  ApproveRiderResponseSchema,
  ResetRiderResponseSchema,
  CreateCognitoUserSchema,
  CreateCognitoUserResponseSchema,
  DeleteCognitoUserResponseSchema,
  GetRiderByIdQuerySchema,
  UndoDeletedRiderResponseSchema,
} from '../../rider-service';
import { z } from 'zod';
import { GetFleetVehicleByIdResponseSchema } from '../../rider-service/fleet-vehicles/get-fleet-vehicle-by-id-schema';
import { RiderStatsResponseSchema } from '../../rider-service/riders';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/rider';

/**
 * POST /clockIn
 */
registry.registerPath({
  operationId: 'clockInRider',
  method: 'post',
  path: `${basePath}/clockIn/{riderId}`,
  description: 'Clock in',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: ClockInRiderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: ClockInRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /clockOut
 */
registry.registerPath({
  operationId: 'clockOutRider',
  method: 'post',
  path: `${basePath}/clockOut/{riderId}/{clockInId}`,
  description: 'Clock out',
  request: {
    params: z.object({
      riderId: z.string(),
      clockInId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: ClockOutRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /riderCreateFleetVehicle
 */
registry.registerPath({
  operationId: 'riderCreateFleetVehicle',
  method: 'post',
  path: `${basePath}/riderCreateFleetVehicle`,
  description: 'Create fleet vehicle',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateFleetVehicleRequestSchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Created',
      content: {
        'application/json': {
          schema: CreateFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /adminCreateFleetVehicle
 */
registry.registerPath({
  operationId: 'adminCreateFleetVehicle',
  method: 'post',
  path: `${basePath}/adminCreateFleetVehicle`,
  description: 'Create fleet vehicle by admin',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateFleetVehicleRequestSchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Created',
      content: {
        'application/json': {
          schema: CreateFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /adminUpdateFleetVehicle/{fleetVehicleId}
 */
registry.registerPath({
  operationId: 'adminUpdateFleetVehicle',
  method: 'put',
  path: `${basePath}/adminUpdateFleetVehicle/{fleetVehicleId}`,
  description: 'Update fleet vehicle by admin',
  request: {
    params: z.object({
      fleetVehicleId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateFleetVehicleRequestSchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Updated',
      content: {
        'application/json': {
          schema: UpdateFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /riderUpdateFleetVehicle/{fleetVehicleId}
 */
registry.registerPath({
  operationId: 'riderUpdateFleetVehicle',
  method: 'put',
  path: `${basePath}/riderUpdateFleetVehicle/{fleetVehicleId}`,
  description: 'Update fleet vehicle',
  request: {
    params: z.object({
      fleetVehicleId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateFleetVehicleRequestSchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Updated',
      content: {
        'application/json': {
          schema: UpdateFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /createRider
 */
registry.registerPath({
  operationId: 'createRider',
  method: 'post',
  path: `${basePath}/createRider`,
  description: 'Create rider',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateRiderBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Created',
      content: {
        'application/json': {
          schema: CreateRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deleteRiderById
 */
registry.registerPath({
  operationId: 'deleteRiderById',
  method: 'delete',
  path: `${basePath}/deleteRiderById/{riderId}`,
  description: 'Delete rider by id',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: DeleteRiderResponseSchema,
        },
      },
    },

    ...commonErrorResponses,
  },
});

/**
 * GET /getActiveFleetVehicles
 */
registry.registerPath({
  operationId: 'getActiveFleetVehicles',
  method: 'get',
  path: `${basePath}/getActiveFleetVehicles/{date}`,
  description: 'Get active fleet vehicles',
  request: {
    params: z.object({
      date: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetActiveFleetVehiclesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getClockIns
 */
registry.registerPath({
  operationId: 'getClockIns',
  method: 'get',
  path: `${basePath}/getClockIns`,
  description: 'Get clock ins',
  request: {
    query: GetClockInsQuerySchema,
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetClockInsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getFleetVehicles
 */
registry.registerPath({
  operationId: 'getFleetVehicles',
  method: 'get',
  path: `${basePath}/getFleetVehicles`,
  description: 'Get fleet vehicles',
  request: {
    query: GetFleetVehiclesQuerySchema,
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetFleetVehiclesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getRiderById/{riderId}
 */
registry.registerPath({
  operationId: 'getRiderById',
  method: 'get',
  path: `${basePath}/getRiderById/{riderId}`,
  description: 'Get rider by id',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    query: GetRiderByIdQuerySchema,
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetRiderByIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getRiderClockIn
 */
registry.registerPath({
  operationId: 'getRiderClockIn',
  method: 'get',
  path: `${basePath}/getRiderClockIn/{riderId}/{date}`,
  description: 'Get rider clock in',
  request: {
    params: z.object({
      riderId: z.string(),
      date: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetRiderClockInResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getRiderIdentity
 */
registry.registerPath({
  operationId: 'getRiderIdentity',
  method: 'get',
  path: `${basePath}/getRiderIdentity/{riderId}`,
  description: 'Get rider identity',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetRiderIdentityResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /getRiderLocations
 */
registry.registerPath({
  operationId: 'getRiderLocations',
  method: 'post',
  path: `${basePath}/getRiderLocations`,
  description: 'Get rider locations',
  request: {
    body: {
      content: {
        'application/json': {
          schema: GetRiderLocationsBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetRiderLocationsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getRiders
 */
registry.registerPath({
  operationId: 'getRiders',
  method: 'get',
  path: `${basePath}/getRiders`,
  description: 'Get riders',
  request: {
    query: GetRidersQuerySchema,
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetRidersResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

// TODO - remove this endpoint and use getRiderIdentity endpoint
/**
 * GET /getTrackRiderIdentity
 */
registry.registerPath({
  operationId: 'getTrackRiderIdentity',
  method: 'get',
  path: `${basePath}/getTrackRiderIdentity/{riderId}`,
  description: 'Get track rider identity',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: GetRiderIdentityResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /searchFleetVehicle
 */
registry.registerPath({
  operationId: 'searchFleetVehicle',
  method: 'get',
  path: `${basePath}/searchFleetVehicle`,
  description: 'Search fleet vehicle',
  request: {
    query: SearchFleetVehicleBodySchema,
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: SearchFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /updateRider
 */
registry.registerPath({
  operationId: 'updateRider',
  method: 'post',
  path: `${basePath}/updateRider/{riderId}`,
  description: 'Update rider',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateRiderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: UpdateRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /updateRiderLocationV2
 */
registry.registerPath({
  operationId: 'updateRiderLocationV2',
  method: 'post',
  path: `${basePath}/updateRiderLocationV2/{riderId}`,
  description: 'Update rider location',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateRiderLocationV2BodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: UpdateRiderLocationV2ResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /rejectRider/{riderId}
 */
registry.registerPath({
  operationId: 'rejectRider',
  method: 'post',
  path: `${basePath}/rejectRider/{riderId}`,
  description: 'Reject rider',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: RejectRiderRequestSchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Post',
      content: {
        'application/json': {
          schema: RejectRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /riderDeleteFleetVehicle
 */
registry.registerPath({
  operationId: 'riderDeleteFleetVehicle',
  method: 'delete',
  path: `${basePath}/riderDeleteFleetVehicle/{fleetVehicleId}`,
  description: 'Delete fleet vehicle by rider',
  request: {
    params: z.object({
      fleetVehicleId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Deleted',
      content: {
        'application/json': {
          schema: DeleteFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /adminDeleteFleetVehicle
 */
registry.registerPath({
  operationId: 'adminDeleteFleetVehicle',
  method: 'delete',
  path: `${basePath}/adminDeleteFleetVehicle/{fleetVehicleId}`,
  description: 'Delete fleet vehicle by admin',
  request: {
    params: z.object({
      fleetVehicleId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Deleted',
      content: {
        'application/json': {
          schema: DeleteFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getRiderFleetVehicles
 */
registry.registerPath({
  operationId: 'getRiderFleetVehicles',
  method: 'get',
  path: `${basePath}/getRiderFleetVehicles`,
  description: 'Get rider fleet vehicles',
  responses: {
    200: {
      description: 'get rider fleet vehicles',
      content: {
        'application/json': {
          schema: GetRiderFleetVehiclesResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /fleetVehicleStats
 */
registry.registerPath({
  operationId: 'fleetVehicleStats',
  method: 'get',
  path: `${basePath}/fleetVehicleStats`,
  description: 'Get stats of fleet vehicles',
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: FleetVehicleStatsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /recentFleetVehicle
 */
registry.registerPath({
  operationId: 'recentFleetVehicle',
  method: 'get',
  path: `${basePath}/recentFleetVehicle`,
  description: 'Get details of recently used fleet vehicle by owner',
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: RecentFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getFleetVehicle
 */
registry.registerPath({
  operationId: 'getFleetVehicle',
  method: 'get',
  path: `${basePath}/getFleetVehicle/{fleetVehicleId}`,
  description: 'Get fleet vehicle by id',
  request: {
    params: z.object({
      fleetVehicleId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Get',
      content: {
        'application/json': {
          schema: GetFleetVehicleByIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /rejectFleetVehicle/{fleetVehicleId}
 */
registry.registerPath({
  operationId: 'rejectFleetVehicle',
  method: 'post',
  path: `${basePath}/rejectFleetVehicle/{fleetVehicleId}`,
  description: 'Reject fleet vehicle',
  request: {
    params: z.object({
      fleetVehicleId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: RejectFleetVehicleRequestSchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Post',
      content: {
        'application/json': {
          schema: RejectFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /approveFleetVehicle/{fleetVehicleId}
 */
registry.registerPath({
  operationId: 'approveFleetVehicle',
  method: 'post',
  path: `${basePath}/approveFleetVehicle/{fleetVehicleId}`,
  description: 'Approve fleet vehicle',
  request: {
    params: z.object({
      fleetVehicleId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Post',
      content: {
        'application/json': {
          schema: ApproveFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PUT /resetFleetVehicle/{fleetVehicleId}
 */
registry.registerPath({
  operationId: 'resetFleetVehicle',
  method: 'put',
  path: `${basePath}/resetFleetVehicle/{fleetVehicleId}`,
  description: 'Reset fleet vehicle verification',
  request: {
    params: z.object({
      fleetVehicleId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Reset fleet vehicle verification',
      content: {
        'application/json': {
          schema: ResetFleetVehicleResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /approveRider/{riderId}
 */
registry.registerPath({
  operationId: 'approveRider',
  method: 'post',
  path: `${basePath}/approveRider/{riderId}`,
  description: 'Approve rider',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Post',
      content: {
        'application/json': {
          schema: ApproveRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PUT /adminUpdateRider/{riderId}
 */
registry.registerPath({
  operationId: 'adminUpdateRider',
  method: 'put',
  path: `${basePath}/adminUpdateRider/{riderId}`,
  description: 'Updated the rider details.',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateRiderBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Post',
      content: {
        'application/json': {
          schema: UpdateRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PUT /resetRider/{riderId}
 */
registry.registerPath({
  operationId: 'resetRider',
  method: 'put',
  path: `${basePath}/resetRider/{riderId}`,
  description: 'Reset rider verification',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Reset rider verification',
      content: {
        'application/json': {
          schema: ResetRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /riderStats
 */
registry.registerPath({
  operationId: 'riderStats',
  method: 'get',
  path: `${basePath}/riderStats`,
  description: 'Get rider stats',
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: RiderStatsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /createCognitoUser
 */
registry.registerPath({
  operationId: 'createCognitoUser',
  method: 'post',
  path: `${basePath}/createCognitoUser`,
  description: 'Created cognito user (Rider).',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateCognitoUserSchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Post',
      content: {
        'application/json': {
          schema: CreateCognitoUserResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deleteCognitoUser/:userName
 */
registry.registerPath({
  operationId: 'deleteCognitoUser',
  method: 'delete',
  path: `${basePath}/deleteCognitoUser/{userName}`,
  description: 'Delete cognito user (rider)',
  request: {
    params: z.object({
      userName: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: DeleteCognitoUserResponseSchema,
        },
      },
    },

    ...commonErrorResponses,
  },
});

/**
 * POST /undoDeletedRider/:riderId
 */
registry.registerPath({
  operationId: 'undoDeletedRider',
  method: 'post',
  path: `${basePath}/undoDeletedRider/{riderId}`,
  description: 'Undo deleted rider',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'OK',
      content: {
        'application/json': {
          schema: UndoDeletedRiderResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const riderApiRegistry = registry;
