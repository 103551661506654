import { z } from 'zod';
import { GeofenceEventSchema } from '../../../models';

export const ArrivedAtDeliveryStopBodySchema = GeofenceEventSchema.pick({
  identifier: true,
  timestamp: true,
  extras: true,
});

export const ArrivedAtDeliveryStopResponseSchema = z.object({
  deliveryStopId: z.string(),
  arrivedAt: z.string().datetime(),
});

export type ArrivedAtDeliveryStopBody = z.infer<
  typeof ArrivedAtDeliveryStopBodySchema
>;

export type ArrivedAtDeliveryStopResponse = z.infer<
  typeof ArrivedAtDeliveryStopResponseSchema
>;
