import { z } from 'zod';

export const LocationWithWaitTimeSchema = z.object({
  id: z.string(),
  location: z.string(),
  waitTime: z.number(),
});

const CommonBodySchema = z.object({
  riderId: z.string().optional(),
  trafficAware: z.boolean().optional(),
});

export const GetTotalTimeEstimateBodySchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('SCHEDULE'),
    scheduleId: z.string(),
    ...CommonBodySchema.shape,
  }),
  z.object({
    type: z.literal('STOPS'),
    stopIds: z.array(z.string()),
    ...CommonBodySchema.shape,
  }),
]);

export const RouteLegSchema = z.object({
  distance: z.number(),
  duration: z.number(),
});

export const StopEtaSchema = z.object({
  etaStart: z.string(),
  etaEnd: z.string(),
  waitTime: z.number(),
  durationFromPreviousStop: z.number(),
  distanceFromPreviousStop: z.number(),
});

export const GetTotalTimeEstimateResponseSchema = z.object({
  distance: z.number(),
  duration: z.number(),
  stopEtas: z.record(z.string(), StopEtaSchema),
  driverToNextStop: RouteLegSchema.nullable(),
  stopOrder: z.array(z.string()),
});

export type RouteLeg = z.infer<typeof RouteLegSchema>;

export type GetTotalTimeEstimateBody = z.infer<
  typeof GetTotalTimeEstimateBodySchema
>;

export type GetTotalTimeEstimateResponse = z.infer<
  typeof GetTotalTimeEstimateResponseSchema
>;
