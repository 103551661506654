import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { CreateDeliveryItem, DimensionUnit } from '@tradeaze-packages/schemas';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { getStepByUnit } from '../../utils';
import { FormInputNote } from '../../../form';

type DimensionInputProps = {
  id: Parameters<UseFormRegister<CreateDeliveryItem>>[0];
  label: string;
  unit: DimensionUnit;
  max: number;
  onFocus?: () => void;
  control: Control<CreateDeliveryItem>;
  inputNote?: string;
  width?: string[] | string | number;
};

export const DimensionInput = ({
  id,
  label,
  unit,
  max,
  onFocus,
  control,
  inputNote,
  width = 'auto',
}: DimensionInputProps) => {
  return (
    <Box width={width}>
      <Controller
        name={id}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl isRequired isInvalid={Boolean(error)}>
            <Box mb={3}>
              <FormLabel htmlFor={id}>{label}</FormLabel>
              <InputGroup>
                <Input
                  {...field}
                  type="number"
                  step={getStepByUnit(unit)}
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) => {
                    field.onChange(parseFloat(e.target.value));
                  }}
                  value={
                    typeof field.value === 'boolean' ? '' : field.value ?? ''
                  }
                  onFocus={onFocus}
                />
                <InputRightElement
                  pointerEvents="none"
                  color="blackAlpha.600"
                  fontSize="1.2em"
                  children={unit}
                />
              </InputGroup>
              <FormInputNote>{inputNote}</FormInputNote>
            </Box>
            <Slider
              min={0}
              max={max}
              step={getStepByUnit(unit)}
              onChange={(val) => {
                onFocus?.();
                field.onChange(val);
              }}
              value={typeof field.value === 'number' ? field.value : 0}
              focusThumbOnChange={false}
            >
              <SliderTrack>
                <SliderFilledTrack bg="yellow.600" />
              </SliderTrack>
              <SliderThumb bg="yellow.600" height={'18px'} width={'18px'} />
            </Slider>
            <FormErrorMessage>
              <Text data-cy={`${id}-error`}>{error?.message}</Text>
            </FormErrorMessage>
          </FormControl>
        )}
      />
    </Box>
  );
};
