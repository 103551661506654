import React, { useMemo, useRef } from 'react';
import { Box, Button, Center, HStack, Icon, Stack } from '@chakra-ui/react';
import {
  HydratedDelivery,
  DeliveryStatus,
  HydratedOrder,
} from '@tradeaze-packages/schemas';
import { FaEye, FaUserCircle } from 'react-icons/fa';
import { useDisclosure } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { CgNotes } from 'react-icons/cg';
import { toast } from 'react-hot-toast';
import { DeliveryNotesModal } from './DeliveryNotesModal';
import {
  UpdateDeliveryStatusButton,
  getDeliveryStatusButtonProps,
} from '../UpdateDeliveryStatusButton';
import { DeliveryActionButton } from '../DeliveryActionButton';
import { useUpdateDeliveryStatus } from '@tradeaze/frontend/hooks';
import { useOrderDashboardContext } from '../../../../order';

export const CardAdminMenu: React.FC<{
  delivery: HydratedDelivery;
  assignedRiderId?: string | null;
  deliveryStatus: DeliveryStatus;
  onPressGoTo: (deliveryId: string) => void;
  onCloseMenu: () => void;
  deliveryDate: Date;
}> = ({ delivery, onPressGoTo, deliveryStatus, onCloseMenu }) => {
  const { deliveryId, order } = delivery;

  const isConfirmed = delivery.status === 'CONFIRMED';

  const { handleStartAssign, handlePreviewOrder } = useOrderDashboardContext();

  const {
    isOpen: isDeliveryNotesOpen,
    onClose: onCloseDeliveryNotes,
    onOpen: onOpenDeliveryNotes,
  } = useDisclosure();

  const menuRef = useRef<HTMLDivElement>(null);

  const allNotes = useMemo(
    () =>
      [
        order.notes,
        delivery.pickup?.deliveryNotes,
        delivery.dropOff?.deliveryNotes,
        ...delivery.deliveryItems.map((item) => item.description),
      ].filter((n): n is NonNullable<typeof n> => Boolean(n)),
    [delivery.deliveryItems, delivery.pickup, delivery.dropOff, order.notes],
  );

  const updateStatusMutation = useUpdateDeliveryStatus({
    onError: () => {
      toast.error('Error updating delivery status');
    },
  });

  const deliveryStatusButtonProps = getDeliveryStatusButtonProps({
    deliveryId,
    updateDeliveryStatus: updateStatusMutation.mutate,
    isLoading: updateStatusMutation.isLoading,
    deliveryStatus,
    gigJobId: delivery.gigJobId,
  });

  const handleOpenDeliveryNotes = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpenDeliveryNotes();
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onCloseMenu?.();
  };

  const handleOpenAssignRider = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCloseMenu?.();
    handleStartAssign(delivery);
  };

  return (
    <Box
      ref={menuRef}
      bg="blackAlpha.800"
      position={'absolute'}
      left={0}
      top={0}
      h="100%"
      w="100%"
      zIndex={1}
      borderRadius={10}
      onClick={handleClose}
    >
      {allNotes.length ? (
        <DeliveryNotesModal
          notes={allNotes}
          isOpen={isDeliveryNotesOpen}
          onClose={onCloseDeliveryNotes}
        />
      ) : null}
      <Center h="100%">
        <Stack direction={'row'} maxW="100%" justifyContent={'center'}>
          {isConfirmed ? (
            <DeliveryActionButton
              icon={<FaUserCircle fontSize={24} />}
              label="Assign"
              onClick={handleOpenAssignRider}
              colorScheme="yellow"
            />
          ) : null}
          {deliveryStatusButtonProps.map((props, index) => (
            <UpdateDeliveryStatusButton key={props.label + index} {...props} />
          ))}
        </Stack>
      </Center>
      <HStack position={'absolute'} bottom={2} right={2}>
        {allNotes.length ? (
          <Button size={'sm'} onClick={handleOpenDeliveryNotes}>
            <CgNotes />
          </Button>
        ) : null}
        <Button
          size={'sm'}
          onClick={() => handlePreviewOrder(order as HydratedOrder)}
        >
          <Icon as={FaEye} fontSize={'xl'} />
        </Button>
        <Button size={'sm'} onClick={() => onPressGoTo(order.orderId)}>
          <Icon as={ArrowForwardIcon} fontSize={'2xl'} />
        </Button>
      </HStack>
    </Box>
  );
};
