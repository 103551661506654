import { DeliveryOptionId } from '@tradeaze-packages/schemas';
import { Spinner, Stack, Text } from '@chakra-ui/react';

type DeliveryTotalProps = {
  isDeliveryPriceLoading: boolean;
  showDeliveryPrice: false | DeliveryOptionId;
  totalTradeazeCharges: number;
  deliveryPriceErrorMessage: string | null;
};

export const DeliveryTotal = ({
  isDeliveryPriceLoading,
  showDeliveryPrice,
  totalTradeazeCharges,
  deliveryPriceErrorMessage,
}: DeliveryTotalProps) => {
  return (
    <Stack justify={'space-between'} direction={['column', 'row']}>
      <Text fontWeight={'bold'}>
        Total{' '}
        <Text fontSize={14} color={'grey'}>
          (exc. VAT)
        </Text>
      </Text>
      {isDeliveryPriceLoading ? (
        <Spinner />
      ) : showDeliveryPrice ? (
        <Text fontSize={18} fontWeight={'bold'} data-cy={'total-price'}>
          £{totalTradeazeCharges.toFixed(2)}
        </Text>
      ) : (
        <Text color={'red.500'}>
          {deliveryPriceErrorMessage || 'Missing required info'}
        </Text>
      )}
    </Stack>
  );
};
