import {
  DeliverySortBy,
  DeliveryStatus,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { formatDateIgnoreTime } from '@tradeaze/shared/utils';

export type DeliveryQueryStore = {
  sortBy: DeliverySortBy;
  endDate?: string;
  startDate?: string;
  deliveryToday?: boolean;
  merchantIds?: string[];
  deliveryStatuses: DeliveryStatus[];
  riderIds?: string[];
  merchantOrderReference?: string;
  deliveryVehicles?: DeliveryVehicleId[];
  getActiveFilters: () => number;
  handleStatusFilterChange: (status: DeliveryStatus) => void;
  handleToggleDeliveryToday: () => void;
  handleChangeSortBy: (sortBy: DeliverySortBy) => void;
  handleChangeStartDate: (startDate: Date) => void;
  handleChangeEndDate: (endDate: Date) => void;
  handleClearDates: () => void;
  handleChangeOrderReference: (newOrderReference: string) => void;
  handleToggleRider: (riderId: string) => void;
  handleSelectAllRiders: () => void;
  handleToggleMerchant: (merchantId: string) => void;
  handleSelectAllMerchants: () => void;
  handleToggleDeliveryVehicle: (deliveryVehicle: DeliveryVehicleId) => void;
  handleSelectAllDeliveryVehicles: () => void;
};

export const useDeliveryQueryStore = create<DeliveryQueryStore>()(
  persist(
    (set, get) => ({
      endDate: undefined,
      merchantIds: undefined,
      deliveryStatuses: ['PENDING', 'CONFIRMED'],
      riderIds: undefined,
      sortBy: 'createdAt',
      deliveryToday: false,
      startDate: undefined,
      merchantOrderReference: undefined,
      deliveryVehicles: undefined,
      getActiveFilters: () => {
        const {
          startDate,
          endDate,
          merchantIds,
          riderIds,
          merchantOrderReference,
          deliveryVehicles,
          deliveryStatuses,
          deliveryToday,
        } = get();

        let count = 0;
        if (startDate) count++;
        if (endDate) count++;
        if (merchantIds?.length) count++;
        if (riderIds) count++;
        if (merchantOrderReference) count++;
        if (deliveryVehicles) count++;
        if (deliveryStatuses.length !== 5 && deliveryStatuses.length !== 0) count++;
        if (deliveryToday) count++;
        return count;
      },
      handleStatusFilterChange: (status: DeliveryStatus) =>
        set(({ deliveryStatuses }) =>
          deliveryStatuses?.includes(status)
            ? {
                deliveryStatuses: deliveryStatuses.filter((s) => s !== status),
              }
            : {
                deliveryStatuses: [...deliveryStatuses, status],
              }
        ),
      handleChangeSortBy: (sortBy: DeliverySortBy) => set({ sortBy }),
      handleToggleDeliveryToday: () =>
        set(({ deliveryToday }) => ({
          deliveryToday: !deliveryToday,
        })),
      handleChangeStartDate: (newDate) => {
        set({ startDate: formatDateIgnoreTime(newDate) });
      },
      handleChangeEndDate: (newDate) => {
        set({ endDate: formatDateIgnoreTime(newDate) });
      },
      handleChangeOrderReference: (newOrderReference) => {
        set({ merchantOrderReference: newOrderReference });
      },
      handleClearDates: () => {
        set({ startDate: undefined, endDate: undefined });
      },
      handleToggleRider: (riderId: string) => {
        set(({ riderIds }) => ({
          riderIds: riderIds?.includes(riderId)
            ? riderIds.filter((id) => id !== riderId)
            : [...(riderIds ?? []), riderId],
        }));
      },
      handleSelectAllRiders: () => {
        set({ riderIds: undefined });
      },
      handleToggleMerchant: (merchantId: string) => {
        set(({ merchantIds }) => ({
          merchantIds: merchantIds?.includes(merchantId)
            ? merchantIds.filter((id) => id !== merchantId)
            : [...(merchantIds ?? []), merchantId],
        }));
      },
      handleSelectAllMerchants: () => {
        set({ merchantIds: undefined });
      },
      handleToggleDeliveryVehicle: (deliveryVehicle: DeliveryVehicleId) => {
        set(({ deliveryVehicles }) => {
          return {
            deliveryVehicles: deliveryVehicles?.includes(deliveryVehicle)
              ? deliveryVehicles.filter((id) => id !== deliveryVehicle)
              : [...(deliveryVehicles ?? []), deliveryVehicle],
          };
        });
      },
      handleSelectAllDeliveryVehicles: () => {
        set({ deliveryVehicles: undefined });
      },
    }),
    {
      name: 'delivery-query-store',
      getStorage: () => sessionStorage,
    }
  )
);
