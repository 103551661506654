import { Box, Center, Heading, Show, Spinner } from '@chakra-ui/react';
import {
  useMerchantCreateOrder,
  useGetMerchantAccount,
} from '@tradeaze/frontend/hooks';
import { CreateOrder } from '@tradeaze-packages/schemas';
import {
  Layout,
  Map,
  MobileMapWithToggle,
  TwoColumnLayout,
  useFormMapMarkers,
  OrderFormType,
  useParseDuplicateOrderFromState,
} from '@tradeaze/frontend/ui-web';
import { captureEvent } from '@tradeaze/frontend/utils';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../components/auth/AuthProvider';
import { usePostHog } from 'posthog-js/react';

const CreateOrderPage = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();

  const { cognitoUserId } = useAuthContext();
  const { data: merchantAccount, isLoading: accountLoading } =
    useGetMerchantAccount(cognitoUserId);

  const { duplicatedOrder } = useParseDuplicateOrderFromState();

  const createOrderMutation = useMerchantCreateOrder({
    onSuccess: (data) => {
      navigate(`/orders/${data.orderId}`);
    },
  });

  const onSubmit = async (order: CreateOrder) => {
    if (!cognitoUserId) {
      throw new Error('User ID is not defined');
    }
    if (!merchantAccount) {
      throw new Error('Merchant account is not defined');
    }

    const orderToCreate: CreateOrder = {
      ...order,
      merchantId: cognitoUserId,
    };
    createOrderMutation.mutate(orderToCreate);
    captureEvent(posthog)('complete_create_order', {
      order: orderToCreate,
    });
  };

  const {
    mapMarkers,
    addPickupMarker,
    addDeliveryMarker,
    removePickupMarker,
    removeDeliveryMarker,
    resetMapMarkers,
  } = useFormMapMarkers();

  if (accountLoading) {
    return (
      <Center h="60vh">
        <Spinner />
      </Center>
    );
  }

  if (!merchantAccount) {
    return (
      <Center h="60vh">
        <Heading size="sm">
          No merchant account found. Set up account before creating an order
        </Heading>
      </Center>
    );
  }

  const OrderFormComponent = (
    <Box position={'relative'}>
      <OrderFormType
        isAdmin={false}
        posthog={posthog}
        merchantAccount={merchantAccount}
        onSubmit={onSubmit}
        addPickupMarker={addPickupMarker}
        addDeliveryMarker={addDeliveryMarker}
        removePickupMarker={removePickupMarker}
        removeDeliveryMarker={removeDeliveryMarker}
        resetMapMarkers={resetMapMarkers}
        isLoading={createOrderMutation.isLoading}
        duplicatedOrder={duplicatedOrder}
      />
    </Box>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={OrderFormComponent}
          right={<Map markers={mapMarkers} shouldUpdateBounds={true} />}
        />
      </Show>
      <Show below="md">
        <Layout>
          {OrderFormComponent}
          <MobileMapWithToggle markers={mapMarkers} />
        </Layout>
      </Show>
    </>
  );
};

export default CreateOrderPage;
