import { Button, Flex } from '@chakra-ui/react';
import React from 'react';

type SaveAddressButtonProps = {
  canSaveAddress: boolean;
  isLoadingCreateAddress: boolean;
  handleSaveAddress: () => void;
  canUpdateAddress: boolean;
  handleUpdateAddress: () => void;
  isLoadingUpdateAddress: boolean;
};

export const SaveAddressButton: React.FC<SaveAddressButtonProps> = ({
  canSaveAddress,
  isLoadingCreateAddress,
  handleSaveAddress,
  canUpdateAddress,
  handleUpdateAddress,
  isLoadingUpdateAddress,
}) => {
  const renderButton = (
    text: string,
    onClick: () => void,
    isLoading: boolean,
  ) => (
    <Button
      isLoading={isLoading}
      onClick={onClick}
      size="sm"
      data-cy={text.toLowerCase().replace(' ', '-')}
    >
      {text}
    </Button>
  );

  return (
    <Flex justifyContent="flex-end" mt={3}>
      {canSaveAddress &&
        renderButton('Save Address', handleSaveAddress, isLoadingCreateAddress)}
      {canUpdateAddress &&
        renderButton(
          'Update Address',
          handleUpdateAddress,
          isLoadingUpdateAddress,
        )}
    </Flex>
  );
};
