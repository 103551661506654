import { isSameDay } from 'date-fns';

export function shouldShowRiderMarker({
  updatedAt,
  isOnDuty,
  hideAfterMinutes,
  hideClockedOut,
}: {
  updatedAt: Date | string;
  isOnDuty: boolean;
  hideAfterMinutes?: number;
  hideClockedOut?: boolean;
}) {
  const updatedAtDate = new Date(updatedAt);
  const now = new Date();

  if (hideAfterMinutes) {
    const timeDiff = now.getTime() - updatedAtDate.getTime();
    const minutesDiff = timeDiff / (1000 * 60);
    return minutesDiff < hideAfterMinutes;
  }

  if (hideClockedOut && !isOnDuty) {
    return false;
  }

  return isSameDay(updatedAtDate, now);
}
