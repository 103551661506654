import { ExistingOrder } from '@tradeaze-packages/schemas';

type PricingConfig = {
  deliveryOption?: string;
  deliveryVehicle?: string;
  deliveryWindowStart?: string;
  heavySideItems: boolean;
  allStops: { postCode: string; type: 'PICK_UP' | 'DROP_OFF' }[];
};

export const isPricingConfigSame = (
  currentConfig: PricingConfig,
  existingOrder?: ExistingOrder,
): boolean => {
  if (!existingOrder) return false;

  const currentAllPostCodes = currentConfig.allStops.map(
    (stop) => stop.postCode,
  );
  const existingOrderPickupPostCodes = existingOrder.pickupDetails?.map(
    (pickup) => pickup.postCode,
  );
  const existingOrderDropoffPostCode = existingOrder.dropoffDetails?.map(
    (dropoff) => dropoff.postCode,
  );

  const existingOrderAllPostCodes = [
    ...(existingOrderPickupPostCodes ?? []),
    ...(existingOrderDropoffPostCode ?? []),
  ].filter(Boolean);

  const existingOrderHeavySideItems = existingOrder.pickupDetails?.some(
    (pickup) => pickup?.deliveryItems[0]?.heavySideItems,
  );

  const samePostCodes = currentAllPostCodes.every((postCode) =>
    existingOrderAllPostCodes.includes(postCode),
  );
  const sameHeavySideItems =
    currentConfig.heavySideItems === existingOrderHeavySideItems;
  const sameDeliveryOption =
    currentConfig.deliveryOption === existingOrder.deliveryOption;
  const sameDeliveryVehicle =
    currentConfig.deliveryVehicle === existingOrder.deliveryVehicle;
  const sameDeliveryWindowStart =
    currentConfig.deliveryWindowStart === existingOrder.deliveryWindowStart;

  return (
    samePostCodes &&
    sameHeavySideItems &&
    sameDeliveryOption &&
    sameDeliveryVehicle &&
    sameDeliveryWindowStart
  );
};
